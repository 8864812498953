import React from 'react';
import { connect } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert';
import firebase from '../../config/firebase';
import { getTags } from '../../sagas/tagsSaga/reducer';
import {
  UncontrolledCollapse,
  Col,
  Row,
  Container,
} from 'reactstrap';
// Components
import TitleBar from '../../components/TitleBar';

const query = require('array-query');

export class AdminTagsEdit extends React.Component {
  constructor() {
    super();
    this.state = {
      numbers: ['One', 'Two', 'Three', 'Four', 'Five', 'Six'],
      loading: true,
      catTitle: '',
      tags: [],
      tagCatId: '',
    };
  }

  componentDidMount() {
    this.setCurrentValues();
  }

  setCurrentValues() {
    if (this.props.match.params.id !== undefined) {
      const doc = this.getContent(
        this.props.match.params.id,
        this.props.tags,
      );

      let newTags = doc.tags;
      newTags.forEach((tag, index) => {
        var result = '';
        var characters = 'abcdefghijklmnopqrstuvwxyz';
        var charactersLength = characters.length;
        for (var i = 0; i < 6; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength),
          );
        }
        newTags[index].id = result;
      });

      this.setState({
        catTitle: doc.parent,
        tags: newTags,
        tagCatId: this.props.match.params.id,
        loading: false,
      });
    }

    const options = [];
    this.props.tags.forEach((tagCat, index) => {
      tagCat.tags.forEach((tag) => {
        options.push({
          value: tag.tag + ',' + tagCat.sid,
          label: tag.tag,
        });
      });
    });
    this.setState({
      allTagOptions: options,
    });
  }

  onChangeValue = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleOnChange = (index, x, value, plusminus) => {
    let newTags = this.state.tags;
    newTags[index][plusminus][x].tag = value.label;
    this.setState({ tags: newTags });
  };

  getTagSet(tagCat, index, x, plusminus) {
    const options = [];
    let currentTag = [];

    currentTag.push({
      value: tagCat.tag,
      label: tagCat.tag,
    });

    options.push({
      value: tagCat.tag,
      label: tagCat.tag,
    });

    return (
      <div>
        <Select
          value={currentTag}
          onChange={(value) => {
            this.handleOnChange(index, x, value, plusminus);
          }}
          options={this.state.allTagOptions}
          isMulti={false}
        />
      </div>
    );
  }

  getPlusFields(tagPlus, index) {
    if (tagPlus !== undefined) {
      return (
        <div style={{ marginBottom: '5px', display: 'inline-block' }}>
          {tagPlus.map((tagCat, x) => (
            <div
              style={{
                display: 'inline-block',
                clear: 'both',
                marginBottom: '5px',
              }}
            >
              <div
                style={{
                  width: '400px',
                  float: 'left',
                  marginRight: '10px',
                }}
              >
                {this.getTagSet(tagCat, index, x, 'plus')}
              </div>
              <div
                style={{
                  width: '60px',
                  float: 'left',
                  marginRight: '10px',
                }}
              >
                <input
                  type="text"
                  value={
                    tagCat.value !== undefined ? tagCat.value : 1
                  }
                  name="plus"
                  onChange={(value) => {
                    this.changeText(index, x, value, 'plus');
                  }}
                />
              </div>
              <div style={{ width: '60px', float: 'left' }}>
                <span>
                  <MaterialIcon
                    icon="delete"
                    size={25}
                    onClick={() => {
                      this.confirmDelete(index, x, 'plus');
                    }}
                  />
                </span>
              </div>
            </div>
          ))}
        </div>
      );
    }
  }

  getMinusFields(tagPlus, index) {
    if (tagPlus !== undefined) {
      return (
        <div style={{ marginBottom: '5px', display: 'inline-block' }}>
          {tagPlus.map((tagCat, x) => (
            <div
              style={{
                display: 'inline-block',
                clear: 'both',
                marginBottom: '5px',
              }}
            >
              <div
                style={{
                  width: '400px',
                  float: 'left',
                  marginRight: '10px',
                }}
              >
                {this.getTagSet(tagCat, index, x, 'minus')}
              </div>
              <div
                style={{
                  width: '60px',
                  float: 'left',
                  marginRight: '10px',
                }}
              >
                <input
                  type="text"
                  value={
                    tagCat.value !== undefined ? tagCat.value : 1
                  }
                  name="plus"
                  onChange={(value) => {
                    this.changeText(index, x, value, 'minus');
                  }}
                />
              </div>
              <div style={{ width: '60px', float: 'left' }}>
                <span>
                  <MaterialIcon
                    icon="delete"
                    size={25}
                    onClick={() => {
                      this.confirmDelete(index, x, 'minus');
                    }}
                  />
                </span>
              </div>
            </div>
          ))}
        </div>
      );
    }
  }

  getContent(id, array) {
    if (id !== undefined) {
      const contentDetails = query('id').is(id).on(array);
      return contentDetails[0];
    } else {
      return false;
    }
  }

  saveTemplate = () => {
    let newTags = this.state.tags;

    let finalObject = [];
    newTags.forEach((tag, index) => {
      let plus = [];
      let minus = [];
      if (tag.minus !== undefined) {
        minus = tag.minus;
      }
      if (tag.plus !== undefined) {
        plus = tag.plus;
      }

      let newTag = {
        plus: plus,
        minus: minus,
        tag: tag.tag,
      };
      if (tag.plural !== undefined) {
        newTag.plural = tag.plural;
      }
      finalObject.push(newTag);
    });
    const db = firebase.firestore();
    // const timestamp = new Date();
    const templateToUpdate = db
      .collection('tags')
      .doc(this.state.tagCatId);
    const updateDoc = templateToUpdate
      .update({
        tagsTest: finalObject,
      })
      .then(() => {
        this.props.getTags();
        this.props.history.push('/admin/tags');
      });
  };

  changeText = (index, x, value, plusminus) => {
    let newTags = this.state.tags;
    let int = parseInt(value.target.value);
    newTags[index][plusminus][x].value = int;
    this.setState({ tags: newTags });
  };

  changeTitle = (index, value) => {
    let newTags = this.state.tags;
    newTags[index].tag = value.target.value;
    this.setState({ tags: newTags });
  };

  changeVisibility(category, visibility) {
    this.setState({
      [category.categoryId + 'Visibility']: visibility,
    });
  }

  getDeleteButton(category, index, section) {
    return (
      <div style={{ width: '100%', display: 'inline-block' }}>
        <div style={{ float: 'right' }}>
          <MaterialIcon
            icon="delete"
            size={20}
            color="rgb(242, 29, 89)"
            onClick={() => {
              this.confirmDelete(category, index, section);
            }}
          />
        </div>
      </div>
    );
  }

  deleteCategory(index, x, plusminus, onClose) {
    let newTags = this.state.tags;
    newTags[index][plusminus].splice(x, 1);
    this.setState({ tags: newTags });
    onClose();
  }

  confirmDelete(index, x, plusminus) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <div style={{ textAlign: 'center' }}>
              <span
                style={{
                  fontWeight: '600',
                  marginBottom: '15px',
                  display: 'block',
                  fontSize: '20px',
                }}
              >
                Are you sure you want to delete this sub tag?
              </span>
              <span
                className="title"
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  fontSize: '18px',
                }}
              >
                "{this.state.tags[index][plusminus][x].tag}"
              </span>
              <div style={{ marginBottom: '20px' }}>
                <a
                  className="red"
                  onClick={() => {
                    this.deleteCategory(index, x, plusminus, onClose);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Delete Tag
                </a>
              </div>
            </div>
            <div>
              <div>
                <button onClick={onClose} className="redbutton">
                  Nevermind
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  }

  getNewField(index, plusminus) {
    let newTags = this.state.tags;
    let newTag = {
      tag: '',
      value: 1,
    };
    if (newTags[index][plusminus] !== undefined) {
      newTags[index][plusminus].push(newTag);
    } else {
      newTags[index][plusminus] = [];
      newTags[index][plusminus].push(newTag);
    }

    this.setState({ tags: newTags });
  }

  getCategoryBlock(tag, index, tags) {
    // let tagTemp = tag.tag.replace(/\s/g, '');
    // let tagTempTwo = tagTemp.replace('-', '');
    // let tagId = tagTempTwo.replace('&', '');
    let tagId = tag.id;
    return (
      <div
        id={tagId}
        key={tagId + 'block'}
        style={{
          borderBottom: '1px solid #dddddd',
          marginBottom: '10px',
        }}
      >
        <div style={{ width: '100%', height: '30px', clear: 'both' }}>
          <span style={{ fontWeight: '600' }}>{tag.tag}</span>
          <div
            id={tagId + 'Toggle'}
            key={tagId + 'Toggle'}
            // className={
            //   this.state[tag.tag + 'Toggler'] === true
            //     ? 'collapsed'
            //     : ''
            // }
            data-toggle="collapse"
            style={{ float: 'right', marginRight: '10px' }}
          >
            <MaterialIcon icon="edit" size={25} color="#f21d59" />
          </div>
        </div>

        <UncontrolledCollapse
          toggler={tagId + 'Toggle'}
          defaultOpen={false}
          key={tagId + 'collapse'}
          style={{ width: '100%' }}
        >
          <div
            className="criteria"
            style={{
              padding: '10px',
              border: '1px solid',
            }}
            key={tagId + 'innerdiv'}
          >
            <div key={tagId + 'inputss'}>
              <span
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  position: 'relative',
                }}
              >
                Tag
              </span>
              <input
                autoFocus
                key={tagId + 'inputs'}
                name={tagId + 'Title'}
                value={tags[index].tag}
                onChange={(value) => {
                  this.changeTitle(index, value);
                }}
                type="text"
                placeholder=""
              />
            </div>
            <div key={tagId + 'button'}>
              <span
                style={{
                  fontWeight: '500',
                  marginBottom: '5px',
                  display: 'block',
                  position: 'relative',
                }}
              >
                Plus
              </span>
              <div
                style={{
                  display: 'inline-block',
                  clear: 'both',
                  marginBottom: '5px',
                }}
              >
                {tag.plus !== undefined && tag.plus.length !== 0 ? (
                  this.getPlusFields(tag.plus, index)
                ) : (
                  <div></div>
                )}
              </div>
              <div
                style={{
                  display: 'block',
                  clear: 'both',
                  marginBottom: '10px',
                }}
              >
                <a
                  className="red"
                  onClick={() => {
                    this.getNewField(index, 'plus');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <MaterialIcon
                    icon="add_box"
                    size={30}
                    color="rgb(242, 29, 89)"
                  />
                </a>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  clear: 'both',
                  marginBottom: '0px',
                  marginTop: '10px',
                }}
              >
                <span
                  style={{
                    fontWeight: '500',
                    marginBottom: '5px',
                    display: 'block',
                    position: 'relative',
                  }}
                >
                  Minus
                </span>

                {tag.minus !== undefined && tag.minus.length !== 0 ? (
                  this.getMinusFields(tag.minus, index)
                ) : (
                  <div></div>
                )}
              </div>
              <div
                style={{
                  display: 'block',
                  clear: 'both',
                  marginBottom: '10px',
                }}
              >
                <a
                  className="red"
                  onClick={() => {
                    this.getNewField(index, 'minus');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <MaterialIcon
                    icon="add_box"
                    size={30}
                    color="rgb(242, 29, 89)"
                  />
                </a>
              </div>
            </div>
          </div>
        </UncontrolledCollapse>
      </div>
    );
  }

  selectChange = (values) => {
    this.setState({
      zoneFilter: values.value,
      selectInput: {
        label: values.label,
        value: values.value,
      },
    });
  };

  clearZone = () => {
    this.setState({
      zoneFilter: null,
      selectInput: {
        label: '',
        value: '',
      },
    });
  };

  addNewTag(tags) {
    let newTag = {
      tag: 'NEW TAG',
      plus: [],
      minus: [],
    };
    let newTags = tags;
    tags.push(newTag);
    this.setState({ tags: newTags });
  }

  render() {
    if (this.state.loading) {
      return null;
    }
    return (
      <div id="admin">
        <TitleBar
          title={'Edit ' + this.state.catTitle}
          backlink="/admin/tags"
        />
        <div className="section">
          <Container>
            <Row>
              <Col xs={12} md={12} sm={12} lg={12}>
                <div key="form" style={{ marginTop: '40px' }}>
                  {this.state.tags.map((tag, index) => (
                    <div key={tag.id + 'wrapper'}>
                      {this.getCategoryBlock(
                        tag,
                        index,
                        this.state.tags,
                      )}
                    </div>
                  ))}
                  <span
                    className="redbutton right"
                    onClick={() => {
                      this.saveTemplate();
                    }}
                    style={{
                      marginLeft: '10px',
                      textAlign: 'center',
                    }}
                  >
                    Save
                  </span>

                  <span
                    className="redbutton right"
                    onClick={() => {
                      this.addNewTag(this.state.tags);
                    }}
                    style={{ textAlign: 'center' }}
                  >
                    Add Tag
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.backbar{background: #313131 !important} [data-toggle="collapse"]:after{display: none} .authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important;} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTags: () => {
      dispatch(getTags());
    },
  };
};

function mapStateToProps(state) {
  return {
    guideTemplates: state.guideTemplates.templates,
    tags: state.tags.tags,
    alllocations: state.alllocations.locations,
    user: state.user,
    profile: state.profile.profile,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminTagsEdit);

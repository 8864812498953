import React from 'react';
import { connect } from 'react-redux';
// Components
import ProfileForm from '../components/ProfileForm';

export class SignUpProfile extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  UNSAFE_componentWillMount() {}

  render() {
    if (this.state.loading) {
      return null; // or render a loading icon
    }
    return (
      <div id="login">
        <div className="signup-box-container">
          <div className="signup-box">
            <div className="profile-box-title-area">
              <h3>Setup Your Profile</h3>
            </div>
            <div id="profileform">
              <ProfileForm />
            </div>
          </div>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100% }.maincontent{height: 100% } .maincontent-push{height: 100%}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    user: state.user,
  };
}

export default connect(mapStateToProps)(SignUpProfile);

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import MaterialIcon from 'material-icons-react';
import { Link, NavLink } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import VendorMenu from '../../components/VendorMenu';
import VendorEventListItemEdit from '../../components/VendorEventListItemEdit';
import firebase from '../../config/firebase';
// Reducers
import Select from 'react-select';
import { getMode } from '../../sagas/setModeSaga/reducer';
import { clearUauth } from '../../sagas/unauthenticatedSaga/reducer';
import { setVendorTimedEvents } from '../../sagas/vendorTimedEventsSaga/reducer';

import { filter } from 'lodash';

const KEYS_TO_FILTERS = ['eventTitle'];

const query = require('array-query');

var options = {
  enableHighAccuracy: true,
  timeout: 10,
  maximumAge: 0,
};
function success(pos) {
  console.log('suceess!');
  var crd = pos.coords;

  console.log('Your current position is:');
  console.log(`Latitude : ${crd.latitude}`);
  console.log(`Longitude: ${crd.longitude}`);
  console.log(`More or less ${crd.accuracy} meters.`);
}

function errors(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}

export class VendorLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      showHelp: false,
      searchTerm: '',
      loading: true,
      promos: [],
      vendorLocations: [],
      sortMode: 'dateDown',
      entertainmentTitle: '',
      promoteMode: false,
      entertainmentId: null,
      vendorTimedEvents: [],
    };
    this.showPicker = this.showPicker.bind(this);
    this.changeSortMode = this.changeSortMode.bind(this);
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    document.title = 'Events';
    this.props.clearUauth();
    if (this.props.mode !== 2) {
      this.props.getMode(2);
    }
    this.getVendorTimedEvents(this.props.vendor.vendorId);
  }

  componentDidMount() {}

  getVendorTimedEvents(vendorId) {
    let vendorTimedEvents = [];
    let entertainment = [];
    const db = firebase.firestore();

    if (vendorId === 'KZry0625YQCjZV99ZZh1') {
      db.collection('vendor_events')
        .where('account.vendorId', '==', vendorId)
        .where('active', '==', true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let temp = doc.data();
            vendorTimedEvents.push(doc.data());
          });

          this.setState({
            vendorTimedEvents,
            loading: false,
          });
        });
    } else {
      db.collection('vendor_events')
        .where('account.vendorId', '==', vendorId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            vendorTimedEvents.push(doc.data());
          });

          this.setState({
            vendorTimedEvents,
            loading: false,
          });
        });
    }
  }

  getInactiveEvents(entertainerId) {
    // let timedEvents = this.props.vendorTimedEvents;
    let timedEvents = this.state.vendorTimedEvents;
    const db = firebase.firestore();
    db.collection('vendor_events')
      .where(
        'entertainment.entertainmentIds',
        'array-contains',
        entertainerId,
      )
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          timedEvents.push(doc.data());
        });
        this.props.setVendorTimedEvents(timedEvents);

        let entertainmentTitleX = this.state.entertainmentTitle;
        let entertainmentIdX = this.state.entertainmentId;
        this.setState({
          entertainmentTitle: '',
          entertainmentId: '',
          selectInput: { value: null, label: '' },
        });
        this.setState({
          entertainmentTitle: entertainmentTitleX,
          entertainmentId: entertainmentIdX,
          selectInput: {
            value: entertainmentTitleX,
            label: entertainmentTitleX,
          },
        });
      });
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  showPromote(mode) {
    this.setState({
      promoteMode: mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  getDate(timestamp) {
    let myDate = timestamp.seconds * 1000; // Unix ms timestamp
    let dayText = moment(myDate, 'x').subtract(10, 'days').calendar();

    // const date = dayText + " " + monthText + " " + yearText;
    return dayText;
  }

  getLocations() {
    const docArray = [];
    this.props.vendor.locations.forEach((location) => {
      if (this.props.vendor.vendorId !== 'KZry0625YQCjZV99ZZh1') {
        const docDetails = query('locationId')
          .is(location)
          .on(this.props.alllocations);
        if (docDetails[0] !== undefined) {
          docArray.push(docDetails[0]);
        }
      } else {
        const docDetails = query('locationId')
          .is(location)
          .on(this.props.demo.demoLocations);
        if (docDetails[0] !== undefined) {
          docArray.push(docDetails[0]);
        }
      }
    });
    return docArray;
  }

  changeSortMode(mode) {
    this.setState({
      sortMode: mode,
    });
  }

  getTitleBar(title, id) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span>
              <span className="vendorAppTitle">{title}</span>

              <span className="helpButton mobileHide right">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right mobileHide">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>
              <div className="saveButtons mobileHide">
                {/* <Link
                  to={{
                    pathname: '/vendor/promote',
                  }}
                  className="right"
                > */}
                {this.state.promoteMode === false ? (
                  <a
                    onClick={() => {
                      this.showPromote(true);
                    }}
                    className="right"
                  >
                    <span className="saveButton">Create</span>
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      this.showPromote(false);
                    }}
                    className="right"
                  >
                    <span className="cancelButton">Cancel</span>
                  </a>
                )}

                {this.props.profile.userType === 1 && (
                  <span
                    className="saveButton"
                    onClick={() => {
                      this.deactivate(this.state.vendorTimedEvents);
                    }}
                  >
                    Deactivate
                  </span>
                )}
              </div>

              <div className="right mobileShow">
                {/* <Link
                  to={{
                    pathname: '/vendor/timedevent/add',
                  }}
                  className="right"
                >
                  <span className="saveButton">New Event</span>
                </Link> */}
                {this.state.promoteMode === false ? (
                  <a
                    onClick={() => {
                      this.showPromote(true);
                    }}
                    className="right"
                  >
                    <span className="saveButton">Create</span>
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      this.showPromote(false);
                    }}
                    className="right"
                  >
                    <span className="cancelButton">Cancel</span>
                  </a>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getSelectOptions() {
    let selectOptions = [];
    this.props.entertainment.forEach((entertainment) => {
      selectOptions.push({
        value:
          entertainment.entertainmentTitle +
          '/' +
          entertainment.entertainmentId,
        label: entertainment.entertainmentTitle,
      });
    });
    return selectOptions;
  }

  selectChange = (values) => {
    const text = values.value.split('/', 5);
    this.setState({
      entertainmentTitle: text[0],
      entertainmentId: text[1],
      selectInput: { value: text[0], label: text[0] },
    });
  };

  clearSearch = () => {
    this.setState({
      entertainmentTitle: '',
      entertainmentId: '',
      selectInput: { value: null, label: '' },
    });
  };

  filteredEvents(events, entertainmentTitle) {
    let promoType = [
      'Food & Beverage Event',
      'Happy Hour',
      'Happy Hour Event',
    ];
    let eventsArray = [];
    let ids = [];

    events.forEach((event, index) => {
      event.tagsEventType.forEach((type) => {
        let status = promoType.includes(type);
        if (status === false) {
          event.entertainment.headliners.forEach((entertainment) => {
            if (
              entertainment.entertainmentTitle ===
              this.state.entertainmentTitle
            ) {
              let statusb = ids.includes(event.eventId);
              if (statusb === false) {
                ids.push(event.eventId);
                eventsArray.push(event);
              }
            } else if (this.state.entertainmentTitle === '') {
              let statusb = ids.includes(event.eventId);
              if (statusb === false) {
                ids.push(event.eventId);
                eventsArray.push(event);
              }
            }
          });
          if (event.entertainment.headliners.length === 0) {
            let statusb = ids.includes(event.eventId);
            if (statusb === false) {
              ids.push(event.eventId);
              eventsArray.push(event);
            }
          }
        } else {
          if (this.state.entertainmentTitle === '') {
            eventsArray.push(event);
          }
        }
      });
    });

    // if (eventsArray.length === 0) {
    //   eventsArray = events;
    // }
    return eventsArray;
  }

  getAppContent(vendorTimedEvents, sortMode, entertainmentTitle) {
    let filteredEvents = this.filteredEvents(
      vendorTimedEvents,
      entertainmentTitle,
    );

    let selectOptions = this.getSelectOptions();
    if (this.state.promoteMode === false) {
      return (
        <div
          style={{
            height: '100%',
          }}
          id="vendor"
        >
          <div className="appMenuNew mobileHide">
            <VendorMenu popupMenu={false} />
          </div>
          <div id="vendorScreenBody">
            <div style={{ padding: '10px', margin: '0px auto' }}>
              <div className="search-area">
                <SearchInput onChange={this.searchUpdated} />
              </div>
              {/* {this.props.profile.userType !== 3 && ( */}
              {this.props.profile.userType === 1 && (
                <div style={{ marginBottom: '10px' }}>
                  <div style={{ float: 'right', padding: '10px' }}>
                    <a
                      onClick={() => {
                        this.clearSearch();
                      }}
                      style={{
                        textDecoration: 'underline',
                      }}
                    >
                      <span>Reset</span>
                    </a>
                  </div>
                  <div style={{ width: '90%' }}>
                    <Select
                      value={this.state.selectInput}
                      closeMenuOnSelect={true}
                      blurInputOnSelect={false}
                      onChange={(e) => this.selectChange(e, 0, 'one')}
                      options={selectOptions}
                      isMulti={false}
                      placeholder={'Filter By Entertainer'}
                    />
                  </div>
                </div>
              )}
              <div>
                {filteredEvents.length > 0 ? (
                  <VendorEventListItemEdit
                    events={filteredEvents}
                    edit={true}
                    editPath="/vendor/timedevent/edit/"
                    backPath="/vendor/timedevents"
                    type={12}
                    changeSortMode={this.changeSortMode}
                    sortMode={this.state.sortMode}
                  />
                ) : (
                  <div
                    style={{ marginTop: '80px', display: 'block' }}
                  >
                    <div
                      style={{
                        padding: '30px',
                        border: '1px solid lightgrey',
                        margin: '0px auto',
                        width: '90%',
                        marginTop: '100px',
                        background: 'white',
                        textAlign: 'center',
                        maxWidth: '500px',
                      }}
                    >
                      {this.state.vendorTimedEvents.length === 0 ? (
                        <center>
                          <h3
                            style={{
                              marginBottom: '20px',
                              marginTop: '15px',
                            }}
                          >
                            Lets Add Your First Promotion!
                          </h3>
                          <p>
                            To get started just click on the{' '}
                            <span style={{ fontWeight: '600' }}>
                              Create{' '}
                            </span>
                            button above.
                          </p>
                        </center>
                      ) : (
                        <center>
                          <h3
                            style={{
                              marginBottom: '20px',
                              marginTop: '15px',
                            }}
                          >
                            No Events Found <br />
                          </h3>
                          {this.state.entertainmentTitle === '' ? (
                            <h4
                              style={{
                                marginBottom: '20px',
                                marginTop: '15px',
                              }}
                            >
                              Using Search Term <br />'
                              {this.state.searchTerm}'
                            </h4>
                          ) : (
                            <h4
                              style={{
                                marginBottom: '20px',
                                marginTop: '15px',
                              }}
                            >
                              For {this.state.entertainmentTitle}
                            </h4>
                          )}
                          {this.props.profile.userType === 1 && (
                            <span
                              onClick={() => {
                                this.getInactiveEvents(
                                  this.state.entertainmentId,
                                );
                              }}
                            >
                              Get Inactive Events
                            </span>
                          )}
                        </center>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            height: '100%',
          }}
          id="vendor"
        >
          <div className="appMenuNew mobileHide">
            <VendorMenu popupMenu={false} />
          </div>
          <div id="vendorScreenBody">
            <div style={{ padding: '10px', margin: '0px auto' }}>
              <div>
                <div className="section">
                  <Container className="no-padding">
                    <Row>
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="no-padding"
                      >
                        <h2>Promote Your Business</h2>
                        <p>
                          Our{' '}
                          <span style={{ fontWeight: '600' }}>
                            Trevel Agent
                          </span>{' '}
                          can help connect your{' '}
                          <span style={{ fontWeight: '600' }}>
                            Events
                          </span>{' '}
                          and{' '}
                          <span style={{ fontWeight: '600' }}>
                            Promotions
                          </span>{' '}
                          with the right customers.
                        </p>
                      </Col>
                    </Row>
                  </Container>

                  <Container style={{ marginTop: '40px' }}>
                    <Row style={{ marginBottom: '40px' }}>
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="no-padding"
                      >
                        <center>
                          <h3>
                            What would you like to tell the Trevel
                            Agent about?
                          </h3>
                        </center>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        style={{ marginBottom: '10px' }}
                      >
                        <Link
                          to={{
                            pathname:
                              '/vendor/timedevent/add/happyhour',
                          }}
                        >
                          <div
                            style={{
                              border: '2px solid rgb(172 7 55)',
                              padding: '20px',
                              height: '300px',
                              paddingTop: '50px',
                              backgroundColor: '#f2f2f2',
                            }}
                          >
                            <center>
                              <h3
                                style={{
                                  borderBottom: '3px solid #ac0837',
                                  padding: '10px',
                                  marginBottom: '20px',
                                }}
                              >
                                Happy Hour
                              </h3>
                            </center>
                            <p>
                              Do you have a regular Happy Hour? I
                              can't wait to tell people! This is a
                              great event to get started with.
                            </p>
                          </div>
                        </Link>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        style={{ marginBottom: '10px' }}
                      >
                        <Link
                          to={{
                            pathname: '/vendor/timedevent/add/promo',
                          }}
                        >
                          <div
                            style={{
                              border: '2px solid rgb(172 7 55)',
                              padding: '20px',
                              height: '300px',
                              paddingTop: '50px',
                              backgroundColor: '#f2f2f2',
                            }}
                          >
                            <center>
                              <h3
                                style={{
                                  borderBottom: '3px solid #ac0837',
                                  padding: '10px',
                                  marginBottom: '20px',
                                }}
                              >
                                Promotion
                              </h3>
                            </center>
                            <p>
                              Are you currently running promotions on
                              Food and Beverages, or running a
                              reoccurring special?
                            </p>
                            <p>
                              I can help make sure the right people
                              know about it.
                            </p>

                            {/* <div
                              style={{
                                borderTop: '1px solid grey',
                                paddingTop: '10px',
                              }}
                            >
                              <center>
                                <span
                                  style={{
                                    fontSize: '13px',
                                    color: 'grey',
                                  }}
                                >
                                  Examples
                                </span>
                              </center>
                              <Row className="no-padding">
                                <Col
                                  xs={6}
                                  sm={6}
                                  md={6}
                                  lg={6}
                                  className="no-padding"
                                >
                                  <ul>
                                    <li>Live Music</li>
                                    <li>Comedy</li>
                                  </ul>
                                </Col>
                                <Col
                                  xs={6}
                                  sm={6}
                                  md={6}
                                  lg={6}
                                  className="no-padding"
                                >
                                  <ul>
                                    <li>Wine Tasting</li>
                                    <li>Trivia Night</li>
                                  </ul>
                                </Col>
                              </Row>
                            </div> */}
                          </div>
                        </Link>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={6}
                        lg={4}
                        style={{ marginBottom: '10px' }}
                      >
                        <Link
                          to={{
                            pathname: '/vendor/timedevent/add/',
                          }}
                        >
                          <div
                            style={{
                              border: '2px solid rgb(172 7 55)',
                              padding: '20px',
                              height: '300px',
                              paddingTop: '50px',
                              backgroundColor: '#f2f2f2',
                            }}
                          >
                            <center>
                              <h3
                                style={{
                                  borderBottom: '3px solid #ac0837',
                                  padding: '10px',
                                  marginBottom: '20px',
                                }}
                              >
                                Events
                              </h3>
                            </center>
                            <p>
                              Promote your upcoming events like Live
                              Music, Wine Tastings, Charity Events, or
                              anything people would attend.
                            </p>
                            {/* <div
                              style={{
                                borderTop: '1px solid grey',
                                paddingTop: '10px',
                              }}
                            >
                              <center>
                                <span
                                  style={{
                                    fontSize: '13px',
                                    color: 'grey',
                                  }}
                                >
                                  Examples
                                </span>
                              </center>
                              <Row className="no-padding">
                                <Col
                                  xs={6}
                                  sm={6}
                                  md={6}
                                  lg={6}
                                  className="no-padding"
                                >
                                  <ul>
                                    <li>Live Music</li>
                                    <li>Comedy</li>
                                  </ul>
                                </Col>
                                <Col
                                  xs={6}
                                  sm={6}
                                  md={6}
                                  lg={6}
                                  className="no-padding"
                                >
                                  <ul>
                                    <li>Wine Tasting</li>
                                    <li>Trivia Night</li>
                                  </ul>
                                </Col>
                              </Row>
                            </div> */}
                          </div>
                        </Link>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  markForRemoval(events) {
    let eventArray = [];

    events.forEach((event, i) => {
      if (event.scheduledTime.dates.multiDates === undefined) {
        let today = moment();
        let status = moment(
          event.scheduledTime.dates.endTimestamp,
        ).isBefore(today);
        if (status === true && event.active === true) {
          event.expired = true;
          eventArray.push(event);
        }
      } else if (event.scheduledTime.dates.multiDates.length === 1) {
        let today = moment();
        let status = moment(
          event.scheduledTime.dates.endTimestamp,
        ).isBefore(today);
        if (status === true && event.active === true) {
          event.expired = true;
          eventArray.push(event);
        }
      } else {
        event.expired = false;
        eventArray.push(event);
      }
    });
    return eventArray;
  }

  deactivate(events) {
    const db = firebase.firestore();
    const timestamp = new Date();
    let expiredEvents = [];

    events.forEach((event, i) => {
      if (event.scheduledTime.dates.multiDates === undefined) {
        let today = moment();
        let status = moment(
          event.scheduledTime.dates.endTimestamp,
        ).isBefore(today);
        if (status === true && event.active === true) {
          expiredEvents.push(event);
        }
      } else if (event.scheduledTime.dates.multiDates.length === 1) {
        let today = moment();
        let status = moment(
          event.scheduledTime.dates.endTimestamp,
        ).isBefore(today);
        if (status === true && event.active === true) {
          expiredEvents.push(event);
        }
      }
    });

    expiredEvents.forEach((event, i) => {
      let id = event.eventId;
      const docToUpdate = db.collection('vendor_events').doc(id);
      const updateDoc = docToUpdate.update({
        active: false,
        lastUpdated: timestamp,
      });
      console.log('deactivate', i, event.eventId);
    });
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  render() {
    let entertainmentArray = [];
    if (this.state.loading) {
      return (
        <div
          style={{
            height: '100%',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'table',
              width: '100%',
              background:
                'linear-gradient(180deg, rgba(242,29,89,1) 0%, rgba(167,25,65,1) 59%)',
            }}
          >
            <div
              style={{
                height: '100%',
                position: 'relative',
                textAlign: 'center',
                display: 'table-cell',
                verticalAlign: 'middle',
                top: '-100px',
              }}
            >
              <div
                style={{ textAlign: 'center', marginTop: '50px' }}
              ></div>
              <span style={{ color: 'white', fontSize: '24px' }}>
                Loading
              </span>
            </div>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '.maincontent{margin-top: 0px !important} body{height: 100%} .toolbar{display:none} #analytics{height: 100%} .maincontent{margin-top: 60px; top:0px !important} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
            }}
          />
        </div>
      );
    } else {
      this.state.vendorTimedEvents.forEach(function (doc) {
        if (doc.tagsEventType[0] === 'Music') {
          doc.entertainment.headliners.forEach(function (
            entertainment,
          ) {
            if (doc.eventId === '34S1ZWEFTViPPVPfiEm7') {
              console.log('lube', doc);
            }
            entertainmentArray.push({
              entertainmentId: entertainment.entertainmentId,
              entertainmentTitle: entertainment.entertainmentTitle,
            });
          });
        } else {
          entertainmentArray.push({
            entertainmentId: doc.eventId,
            entertainmentTitle: doc.eventTitle,
          });
        }
      });
      console.log('lube2', entertainmentArray);
    }

    let eventsReady = this.markForRemoval(
      this.state.vendorTimedEvents,
    );

    const filteredEvents = eventsReady.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
    );

    console.log('hotit', filteredEvents);

    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <VendorMenu showPicker={this.showPicker} popupMenu={true} />
        )}
        {this.getTitleBar('Promote')}

        <div className="vendorContent">
          {this.getAppContent(
            filteredEvents,
            this.state.sortMode,
            this.state.entertainmentTitle,
          )}
        </div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
            <span className="vendorAppTitle">Help</span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%} .toolbar{display:none} #analytics{height: 100%} .maincontent{margin-top: 60px; top:0px !important} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMode: (mode) => {
      dispatch(getMode(mode));
    },
    clearUauth: () => {
      dispatch(clearUauth());
    },
    setVendorTimedEvents: (events) => {
      dispatch(setVendorTimedEvents(events));
    },
  };
};

function mapStateToProps(state) {
  return {
    vendor: state.vendor.vendor,
    mode: state.mode.mode,
    profile: state.profile.profile,
    vendorTimedEvents: state.vendorTimedEvents.events,
    alllocations: state.locations.locations,
    demo: state.demo,
    user: state.user,
    entertainment: state.vendorEntertainment.entertainment,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorLocations);

import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import React from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import { Container, Row, Col } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { connect } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import firebase from '../../config/firebase';
import FileUploader from 'react-firebase-file-uploader';
import Select from 'react-select';
import MaterialIcon from 'material-icons-react';
import ReactCrop from 'react-image-crop';
import UserAvatar from 'react-user-avatar';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../images/TrevelUnderlined.svg';
// Reducers
// import { setLocations } from '../../sagas/locationsSaga/reducer';
import { getVendorEntertainment } from '../../sagas/vendorEntertainmentSaga/reducer';
import { getTimedEvents } from '../../sagas/timedEventsSaga/reducer';
import { getEntertainment } from '../../sagas/entertainmentSaga/reducer';

const format = 'h:mm a';
const now = moment().hour(0).minute(0);

const INITIAL_STATE = {
  mode: 0,
  title: '',
  categories: '',
  city: '',
  latitude: null,
  longitude: null,
  slug: '',
  state: '',
  street: '',
  zip: '',
  locationHero: '',
  locationId: '',
  locationThumb: '',
  entertainmentDescriptionLong: '',
  locationName: '',
  website: '',
  twitter: '',
  twitch: '',
  tiktok: '',
  facebook: '',
  patreon: '',
  bandcamp: '',
  instagram: '',
  youtube: '',
  menuLink: '',
  zoneId: '',
  error: null,
  loading: true,
  isUploading: false,
  progress: 0,
  progressThumb: 0,
  heroURL: '',
  hero: '',
  bandName: '',
  selectedOption: null,
  contactEmail: null,
  contactName: null,
  contactPhone: null,
  step: 1,
  feedbackSubmitstatus: 0,
  feedback: '',
  selectedTime: '',
  from: undefined,
  to: undefined,
  local: false,

  crop: {
    unit: '%',
    width: 30,
    aspect: 800 / 259,
  },
  cropThumb: {
    unit: '%',
    width: 30,
    aspect: 146 / 122,
  },
  cropping: false,
  croppingThumb: false,
  thumbPath: '',
  heroPath: '',
};

const query = require('array-query');

export class VendorEntertainmentEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.getCategoryFields = this.getCategoryFields.bind(this);
    this.saveLocation = this.saveLocation.bind(this);
  }

  UNSAFE_componentWillMount() {}

  componentDidMount() {
    this.setCurrentValues();
    // Get New Location ID to use later
  }

  getCategoryFields(cattag) {
    const options = [];
    if (
      cattag.sid === 'entertainmentType' ||
      cattag.sid === 'genre'
    ) {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });
      return (
        <div>
          <span className="inputLabel">{cattag.parent}</span>
          <Select
            value={this.state[cattag.sid]}
            onChange={(value) => {
              this.handleOnChange(cattag.sid, value);
            }}
            options={options}
            isMulti={true}
          />
        </div>
      );
    }
  }

  handleOnChange = (fieldName, value) => {
    this.setState({ [fieldName]: value });
  };

  getImage(type, location) {
    let path = '';
    let className = '';
    if (type === 'hero') {
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.entertainmentHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.entertainmentHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      className = 'thumbnail';
      if (
        location !== undefined &&
        location.settings !== undefined &&
        location.settings.customImages !== undefined &&
        location.settings.customImages === true
      ) {
        path = location.entertainmentThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.entertainmentThumb +
            '?alt=media';
        }
      }
    }
    return path;
  }

  onChangeActionLocal = (value) => {
    this.setState({
      local: value.target.checked,
    });
  };

  getCity() {
    let city = '';
    if (this.props.profile.zoneId === 11) {
      city = 'St. Louis';
    } else if (this.props.profile.zoneId === 10) {
      city = 'Kansas City';
    }
    return city;
  }

  setCurrentValues() {
    // const entertainmentDetails = query('entertainmentId')
    //   .is(this.props.match.params.id)
    //   .on(this.props.vendorEntertainment);

    // let entertainment = entertainmentDetails[0];
    console.log(
      'this.props.location.state.entertainment',
      this.props.location.state.entertainment,
    );
    let entertainment = this.props.location.state.entertainment;

    const tagsEntertainmentType = [];
    if (entertainment.tagsEntertainmentType) {
      entertainment.tagsEntertainmentType.forEach((tag) => {
        tagsEntertainmentType.push({
          value: tag,
          label: tag,
        });
      });
    }

    const tagsGenre = [];
    if (entertainment.tagsGenre) {
      entertainment.tagsGenre.forEach((tag) => {
        tagsGenre.push({
          value: tag,
          label: tag,
        });
      });
    }

    if (entertainment.entertainmentDescription !== undefined) {
      const blocksFromHtml = htmlToDraft(
        entertainment.entertainmentDescription,
      );
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState,
      });
    } else {
      const editorState = EditorState.createEmpty();
      this.setState({
        editorState,
      });
    }
    const timestamp = new Date();

    const heroPath = this.getImage('hero', entertainment);
    const thumbPath = this.getImage('thumb', entertainment);

    let twitter = '';
    let twitch = '';
    let tiktok = '';
    let facebook = '';
    let patreon = '';
    let bandcamp = '';
    let instagram = '';
    let youtube = '';
    if (entertainment.social !== undefined) {
      twitter = entertainment.social.twitter;
      twitch = entertainment.social.twitch;
      tiktok = entertainment.social.tiktok;
      facebook = entertainment.social.facebook;
      patreon = entertainment.social.patreon;
      bandcamp = entertainment.social.bandcamp;
      instagram = entertainment.social.instagram;
    }

    let local = false;
    if (entertainment.settings.local !== undefined) {
      local = entertainment.settings.local;
    }

    this.setState({
      // dateType: dateTypeTags,
      active: entertainment.active,
      entertainmentDescription: entertainment.eventDescription,
      title: entertainment.entertainmentTitle,
      entertainmentId: entertainment.entertainmentId,
      entertainmentThumb: entertainment.entertainmentThumb,
      entertainmentHero: entertainment.entertainmentHero,
      website: entertainment.website,
      twitter,
      twitch,
      tiktok,
      facebook,
      patreon,
      bandcamp,
      instagram,
      youtube,
      entertainmentType: tagsEntertainmentType,
      slug: entertainment.slug,
      genre: tagsGenre,
      settings: entertainment.settings,
      creationDetails: entertainment.creationDetails,
      zoneId: entertainment.zoneId,
      account: entertainment.account,
      thumbPath,
      heroPath,
      local,
    });
  }

  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
  };

  onEditorStateChangeLong: Function = (editorStateLong) => {
    this.setState({
      editorStateLong,
    });
  };

  saveLocation(editorState, values) {
    const db = firebase.firestore();
    // const articleToUpdate = db.collection('locations').doc(id);
    const timestamp = new Date();

    const tagsEntertainmentType = [];
    this.state.entertainmentType.forEach((doc) => {
      tagsEntertainmentType.push(doc.value);
    });

    const tagsGenre = [];
    this.state.genre.forEach((doc) => {
      tagsGenre.push(doc.value);
    });

    // Convert Body from Draft.js to HTML
    const rawContentState = convertToRaw(
      editorState.getCurrentContent(),
    );

    const markup = draftToHtml(rawContentState);

    const docToUpdate = db
      .collection('entertainment')
      .doc(this.state.entertainmentId);
    const updateDoc = docToUpdate
      .update({
        active: this.state.active,
        entertainmentDescription: markup,
        lastUpdated: timestamp,
        entertainmentTitle: values.title,
        entertainmentId: this.state.entertainmentId,
        entertainmentThumb: this.state.thumbPath,
        entertainmentHero: this.state.heroPath,
        website: values.website,
        social: {
          twitter: values.twitter,
          twitch: values.twitch,
          tiktok: values.tiktok,
          facebook: values.facebook,
          patreon: values.patreon,
          bandcamp: values.bandcamp,
          instagram: values.instagram,
          youtube: values.youtube,
          local: this.state.local,
        },
        slug: values.slug,
        settings: this.state.settings,
        creationDetails: {
          creatorId: this.props.profile.userId,
          createdDate: timestamp,
        },
        zoneId: this.props.profile.zoneId,
        account: {
          vendorId: this.props.vendor.vendorId,
        },
        tagsEntertainmentType,
        tagsGenre,
      })
      .then(() => {
        this.props.getEntertainment(this.props.profile.zoneId);
        this.props.getTimedEvents(this.props.profile.zoneId);
        this.props.getVendorEntertainment(this.props.vendor.vendorId);
      });
  }

  // Banner Crop
  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEntertainmentListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      cropping: true,
    });
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'heroImage.jpeg',
      );
      this.setState({ heroPath: croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getBannerCropper(bannerCropping) {
    const { crop, croppedImageUrl, src } = this.state;
    if (bannerCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="Bar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropper();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropper();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </div>
        </div>
      );
    }
  }
  // End Banner Crop
  // Thumb Crop
  onSelectFileThumb = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEntertainmentListener('load', () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({
      croppingThumb: true,
    });
  };

  onImageLoadedThumb = (image) => {
    this.imageRef = image;
  };

  onCropCompleteThumb = (crop) => {
    this.makeClientCropThumb(crop);
  };

  onCropChangeThumb = (cropThumb, percentCrop) => {
    this.setState({ cropThumb });
  };

  async makeClientCropThumb(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImgThumb(
        this.imageRef,
        crop,
        'thumbnail.jpeg',
      );
      this.setState({
        thumbPath: croppedImageUrl,
      });
    }
  }

  getCroppedImgThumb(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({
          blob,
        });
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  getThumbnailCropper(thumbnailCropping) {
    const { cropThumb, croppedImageUrl, src } = this.state;
    if (thumbnailCropping === true) {
      return (
        <div id="cropper">
          <div id="headersTop">
            <div className="secondaryBar">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.cancelCropperThumb();
                      }}
                    />
                  </center>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="page-header">Crop Image</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  <center>
                    <MaterialIcon
                      icon="check"
                      size={25}
                      color="white"
                      onClick={() => {
                        this.saveCropperThumb();
                      }}
                    />
                  </center>
                </Col>
              </Row>
            </div>
          </div>
          <div id="crop-image">
            {src && (
              <ReactCrop
                src={src}
                crop={cropThumb}
                onImageLoaded={this.onImageLoadedThumb}
                onComplete={this.onCropCompleteThumb}
                onChange={this.onCropChangeThumb}
              />
            )}
          </div>
        </div>
      );
    }
  }
  //End Thumb Crop
  cancelCropper() {
    this.setState({
      cropping: false,
    });
  }

  saveCropper() {
    this.uploadImage(this.state.blob, 'hero');
    this.setState({
      cropping: false,
    });
  }

  cancelCropperThumb() {
    this.setState({
      croppingThumb: false,
    });
  }

  saveCropperThumb() {
    this.uploadImage(this.state.blob, 'thumb');
    this.setState({
      croppingThumb: false,
    });
  }

  uploadImage = (image, type) => {
    const uploadUrl =
      'vendorEntertainment_images/' +
      this.state.entertainmentId +
      '/' +
      image.name;
    // const uploadUrl =
    //   'event_images/' + this.state.dbref + '/' + image.name;
    const storage = firebase.storage();
    const storageRef = storage.ref();
    const imageRef = storageRef.child(uploadUrl);
    imageRef.put(image).then((snapshot) => {
      // const path = snapshot.ref.location.path_;
      if (type === 'hero') {
        let heroImagePath =
          'vendorEntertainment_images%2F' +
          this.state.entertainmentId +
          '%2FheroImage.jpeg';
        let heroPath =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          heroImagePath +
          '?alt=media';
        this.setState({
          heroPath,
          customHero: true,
        });
      } else {
        let thumbnailImagePath =
          'vendorEntertainment_images%2F' +
          this.state.entertainmentId +
          '%2Fthumbnail.jpeg';
        let thumbPath =
          'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/' +
          thumbnailImagePath +
          '?alt=media';
        this.setState({
          thumbPath,
          customThumb: true,
        });
      }
    });
  };

  // Crop Functions
  getHeroImage() {
    if (this.state.heroPath !== '') {
      return (
        <img
          alt="Crop"
          style={{ maxWidth: '100%' }}
          src={this.state.heroPath}
        />
      );
    }
  }

  getThumbnail() {
    if (this.state.thumbPath !== '') {
      return (
        <img
          alt="Crop"
          style={{ maxWidth: '100%' }}
          src={this.state.thumbPath}
        />
      );
    }
  }

  getStep(step) {
    const { editorState, editorStateLong } = this.state;
    if (step === 0) {
      return (
        <div>
          <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                {this.getId(this.props.mode)}
                <div className="section no-padding">
                  <span className="inputLabel">Name</span>
                  <Field
                    type="title"
                    name="title"
                    placeholder={this.state.title}
                  />
                  <ErrorMessage name="title" component="div" />
                  <span className="inputLabel">Description</span>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                      // inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                      options: [
                        'inline',
                        'blockType',
                        'list',
                        'colorPicker',
                        'link',
                        'remove',
                        'history',
                      ],
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <div
                  style={{
                    paddingBottom: '10px',
                    display: 'inline-block',
                  }}
                >
                  <span className="inputLabel">
                    Are you local to the {this.getCity()} area?
                  </span>

                  <input
                    type="checkbox"
                    checked={this.state.local}
                    onChange={this.onChangeActionLocal}
                    className="closedCheckBox"
                    name="local"
                    style={{ transform: 'scale(2)', float: 'left' }}
                  />
                  <span
                    style={{
                      float: 'left',
                      position: 'relative',
                      top: '9px',
                    }}
                  >
                    Yes
                  </span>
                  <ErrorMessage name="local" component="div" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div>
                  {/* <span className="inputLabel">Phone</span>
                  <Field
                    type="phone"
                    name="phone"
                    placeholder="Phone Number"
                  />
                  <ErrorMessage name="phone" component="div" /> */}
                  <span className="inputLabel">
                    Website Link (Optional)
                  </span>
                  <Field
                    type="phone"
                    name="website"
                    placeholder="Website URL"
                  />
                  <ErrorMessage name="website" component="div" />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div>
                  <span className="inputLabel">
                    Your Trevel Home Page URL
                  </span>
                  <Field
                    type="slug"
                    name="slug"
                    placeholder={this.state.slug}
                  />
                  <ErrorMessage name="slug" component="div" />
                  {/* <span className="inputLabel">Main Contact Name</span>
                      <Field
                        type="text"
                        name="contactName"
                        placeholder="Name"
                      />
                      <ErrorMessage name="contactName" component="div" />
                      <span className="inputLabel">Main Contact Phone</span>
                      <Field
                        type="text"
                        name="contactPhone"
                        placeholder="Phone Number"
                      />
                      <ErrorMessage name="contactPhone" component="div" />
                      <span className="inputLabel">Main Contact Email</span>
                      <Field
                        type="text"
                        name="contactEmail"
                        placeholder="Email Address"
                      />
                      <ErrorMessage name="contactEmail" component="div" /> */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <hr />
              </Col>
            </Row>
            <Row style={{ paddingTop: '20px' }}>
              <Col lg={12} md={12} sm={12} xs={12}>
                <span>Social Media Links (Optional)</span>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div>
                  <span className="inputLabel">Twitter</span>
                  <Field type="text" name="twitter" placeholder="" />
                  <ErrorMessage name="twitter" component="div" />
                </div>
                <div>
                  <span className="inputLabel">Twitch</span>
                  <Field type="text" name="twitch" placeholder="" />
                  <ErrorMessage name="twitch" component="div" />
                </div>
                <div>
                  <span className="inputLabel">TicTok</span>
                  <Field type="text" name="tiktok" placeholder="" />
                  <ErrorMessage name="ticktok" component="div" />
                </div>

                <div>
                  <span className="inputLabel">YouTube</span>
                  <Field type="text" name="youtube" placeholder="" />
                  <ErrorMessage name="youtube" component="div" />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div>
                  <span className="inputLabel">Facebook</span>
                  <Field type="text" name="facebook" placeholder="" />
                  <ErrorMessage name="facebook" component="div" />
                </div>
                <div>
                  <span className="inputLabel">Instagram</span>
                  <Field
                    type="text"
                    name="instagram"
                    placeholder=""
                  />
                  <ErrorMessage name="instagram" component="div" />
                </div>
                <div>
                  <span className="inputLabel">Patreon</span>
                  <Field type="text" name="patreon" placeholder="" />
                  <ErrorMessage name="patreon" component="div" />
                </div>
                <div>
                  <span className="inputLabel">Band Camp</span>
                  <Field type="text" name="bandcamp" placeholder="" />
                  <ErrorMessage name="bandcamp" component="div" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (step === 1) {
      return (
        <Container id="imageUpload">
          <Row>
            <Col
              lg={12}
              md={6}
              sm={12}
              xs={12}
              className="left"
              style={{ marginTop: '10px' }}
            >
              <span className="title">Images</span>
              <p>
                You can skip uploading images for now, but you will
                need to add them before your page can be published.
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              <span className="inputLabel">Banner Image</span>
              {this.getHeroImage()}
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              <div>
                <input type="file" onChange={this.onSelectFile} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              <span className="inputLabel">Thumbnail Image</span>
              {this.getThumbnail()}
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={6} sm={12} xs={12} className="left">
              <div>
                <input
                  type="file"
                  onChange={this.onSelectFileThumb}
                />
              </div>
            </Col>
          </Row>
        </Container>
      );
    } else if (step === 2) {
      return (
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            {/* <div className="section no-padding">
              <span className="section-header">Categories</span>
            </div> */}
            <div className="section no-padding">
              {this.props.tags.map((cattag, index) => (
                <div>{this.getCategoryFields(cattag)}</div>
              ))}
              <ErrorMessage name="categories" component="div" />
            </div>
          </Col>
        </Row>
      );
    }
  }

  handleChange = (entertainment) => {
    this.setState({ feedback: entertainment.target.value });
  };

  submitFeedback(feedback) {
    const userName =
      this.props.profile.firstName + this.props.profile.lastName;
    const userId = this.props.profile.userId;
    const db = firebase.firestore();
    const timestamp = new Date();
    db.collection('feedback').add({
      created: timestamp,
      userName,
      userId,
      feedback: feedback,
      topic: 'tags',
    });
    this.setState({ feedbackSubmitstatus: 1, feedback: '' });
  }

  getActionButton(step, title) {
    if (this.state.step === step) {
      return (
        <div
          className="text-center borderRight actionButtonActive"
          onClick={() => {
            this.setStep(step);
          }}
        >
          {title}
        </div>
      );
    } else {
      return (
        <div
          className="text-center borderRight actionButton"
          onClick={() => {
            this.setStep(step);
          }}
        >
          {title}
        </div>
      );
    }
  }

  getId(mode) {
    if (mode === 1) {
      return (
        <div className="border-box greyback">
          <span>
            <span className="inputLabel">
              ID: {this.state.locationId}
            </span>
          </span>
        </div>
      );
    }
  }

  setStep(step) {
    this.setState({
      step,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  getTitleBar(title) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              {/* <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span> */}
              <span className="vendorAppTitle">
                {title}
                <span
                  className="vendorAppTitle"
                  style={{ color: 'grey' }}
                >
                  {/* {name} */}
                </span>
              </span>
              {/* </Col>

            <Col lg={2} md={2} sm={2} xs={2} className="no-padding"> */}
              <span className="helpButton mobileHide right">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right mobileHide">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>

              <div className="saveButtons mobileHide">
                <button className="saveButton" type="submit">
                  Save
                </button>

                <Link
                  to={{
                    pathname: '/vendor/home',
                  }}
                >
                  <span className="cancelButton">Cancel</span>
                </Link>
              </div>

              <div className="right mobileShow">
                <button className="saveButton" type="submit">
                  Save
                </button>

                <Link
                  to={{
                    pathname: '/vendor/home',
                  }}
                >
                  <span className="cancelButton">Cancel</span>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <Row className="no-margin">
          <Col lg={12} md={12} sm={12} xs={12} className="no-padding">
            <div className="mobileShow">{this.getMobileMenu()}</div>
          </Col>
          <style
            dangerouslySetInnerHTML={{
              __html:
                'body{height: 100%} @media screen and (max-width: 768px) { .maincontent{margin-top: 120px !important; top:0px !important}}',
            }}
          />
        </Row>
      </div>
    );
  }

  getTab(tabMode, tabLable, mode, last) {
    if (tabMode === mode) {
      if (last === true) {
        return (
          <div className="noBorderRight actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      } else {
        return (
          <div className="actionButton actionButtonActive">
            {tabLable}
          </div>
        );
      }
    } else {
      if (last === true) {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="actionButton noBorderRight  actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      } else {
        return (
          <a
            onClick={() => {
              this.setMode(tabMode);
            }}
          >
            <div className="actionButton actionButtonInactive">
              {tabLable}
            </div>
          </a>
        );
      }
    }
  }

  onKeyDown(keyEntertainment) {
    if (
      (keyEntertainment.charCode || keyEntertainment.keyCode) === 13
    ) {
      keyEntertainment.prentertainmentDefault();
    }
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  getLeftMenu() {
    return (
      <div className="vendorLeftNavButtons">
        {this.getTab(1, 'Details', this.state.mode, false)}
        {this.getTab(2, 'Images', this.state.mode, false)}
        {this.getTab(3, 'Tags', this.state.mode, false)}
        {/* {this.getTab(5, 'Our Story', this.state.mode, false)} */}
      </div>
    );
  }

  onTimeChange = (time) => {
    console.log('TIME!', time);
    this.setState({ selectedTime: time });
  };

  setScheduleTimes(
    selectedStartTime,
    selectedEndTime,
    differentTime,
  ) {
    // Check if Time Set
    let bundle = {
      startTime: null,
      startTimeString: null,
      endTime: null,
      endTimeString: null,
      regHours: null,
    };

    if (differentTime === 'no') {
      bundle.regHours = true;
    } else {
      bundle.regHours = false;
    }

    if (
      selectedStartTime !== '' &&
      selectedStartTime !== undefined &&
      selectedStartTime !== null
    ) {
      const datestring = String(selectedStartTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      // const seconds = Number(time[2]);
      let timeValue;
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.';
      bundle.startTime = timeValue;
      bundle.startTimeString = selectedStartTime._d;
    }

    if (
      selectedEndTime !== '' &&
      selectedEndTime !== undefined &&
      selectedEndTime !== null
    ) {
      const datestring = String(selectedEndTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      // const seconds = Number(time[2]);
      let timeValue;
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.';
      bundle.endTime = timeValue;
      bundle.endTimeString = selectedEndTime._d;
    }

    return bundle;
  }

  getDifferentTimePicker() {
    return (
      <div>
        <div className="clear">
          <span className="inputLabel">Start Time</span>
          <TimePicker
            showSecond={false}
            defaultValue={this.state.selectedStartTime}
            className="xxx"
            onChange={this.onStartTimeChange}
            format={format}
            use12Hours
            inputReadOnly
          />
        </div>

        <div className="clear">
          <span className="inputLabel">End Time</span>
          <TimePicker
            showSecond={false}
            defaultValue={this.state.selectedEndTime}
            className="xxx"
            onChange={this.onEndTimeChange}
            format={format}
            use12Hours
            inputReadOnly
          />
        </div>
      </div>
    );
  }

  differentEndChange(entertainment) {
    this.setState({
      differentEnd: entertainment.target.value,
    });
  }

  differentTimeChange(entertainment) {
    this.setState({
      differentTime: entertainment.target.value,
    });
  }

  getScheduleTab() {
    const { from, to } = this.state;
    const modifiersStyles = {
      selected: {
        color: 'white',
        backgroundColor: '#f21d59',
      },
    };
    const modifiers = { start: from, end: to };
    const today = new Date();

    return (
      <Container>
        <Row className="no-margin">
          <Col xs={12} md={6} sm={12} lg={6} className="no-padding">
            <Row className="no-margin">
              <span className="inputLabel">Entertainment Dates</span>
              <div className="full-width">
                <p>
                  {!from && !to && 'Please select the first day.'}
                  {from && !to && 'Please select the last day.'}
                  {from &&
                    to &&
                    `Selected from ${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}{' '}
                  {from && to && (
                    <button
                      className="link"
                      onClick={this.handleResetClick.bind(this)}
                    >
                      Reset
                    </button>
                  )}
                </p>
              </div>
              <div id="vendorSchedule">
                <DayPicker
                  onDayClick={this.handleStartDayClick}
                  selectedDays={[from, { from, to }]}
                  modifiers={modifiers}
                  modifiersStyles={modifiersStyles}
                  disabledDays={{ before: today }}
                />
              </div>
              {/* <p>
                    {this.state.selectedStartDay
                      ? this.state.selectedStartDay.toLocaleDateString()
                      : "Please select a day"}
                  </p> */}
            </Row>
            <Row>
              <div />
            </Row>
          </Col>
          <Col xs={12} md={6} sm={12} lg={6} className="no-padding">
            <Row>
              <span className="inputLabel">Entertainment Time</span>
            </Row>
            <Row>
              {this.getDifferentTimePicker(
                this.state.differentTime,
                modifiersStyles,
                today,
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

  handleResetClick() {
    this.setState({ from: undefined, to: undefined });
  }

  setSchedule(selectedStartDay, selectedEndDay) {
    let bundle = {
      startDate: null,
      startTimestamp: null,
      endDate: null,
      endTimestamp: null,
      oneDayEntertainment: null,
    };

    if (selectedStartDay === selectedEndDay) {
      bundle.oneDayEntertainment = true;
    } else {
      bundle.oneDayEntertainment = false;
    }

    // Check for Date
    if (
      selectedStartDay !== '' &&
      selectedStartDay !== undefined &&
      selectedStartDay !== null
    ) {
      const timestamp = moment(selectedStartDay).unix();
      const timestampReady = timestamp * 1000;
      const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
      bundle.startDate = dateString;
      bundle.startTimestamp = timestampReady;
    }

    if (
      selectedEndDay !== '' &&
      selectedEndDay !== undefined &&
      selectedEndDay !== null
    ) {
      const timestamp = moment(selectedEndDay).unix();
      const timestampReady = timestamp * 1000;
      const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
      bundle.endDate = dateString;
      bundle.endTimestamp = timestampReady;
    }
    return bundle;
  }

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }

  handleStartDayClick = (day, { selected }) => {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);

    // this.setState({
    //   selectedStartDay: selected ? undefined : day
    // });
  };

  handleEndDayClick(day, { selected }) {
    this.setState({
      selectedEndDay: selected ? undefined : day,
    });
  }

  onStartTimeChange = (time) => {
    console.log('Start TIME!', time);
    this.setState({ selectedStartTime: time });
  };

  onEndTimeChange = (time) => {
    console.log('End TIME!', time);
    this.setState({ selectedEndTime: time });
  };

  getLeftMenu() {
    return (
      <div className="vendorLeftNavButtons">
        {this.getTab(0, 'Details', this.state.mode, false)}
        {this.getTab(1, 'Images', this.state.mode, false)}
        {this.getTab(2, 'Tags', this.state.mode, false)}
      </div>
    );
  }

  getMobileNext(mode, mobileMenu) {
    let totalCount = mobileMenu.length;
    let currentMenu = mobileMenu[mode];
    if (currentMenu.last !== true) {
      let arrayNum = mode + 1;
      let nextMenu = mobileMenu[arrayNum];
      return (
        <div
          style={{
            float: 'right',
            position: 'relative',
            top: '8px',
          }}
        >
          <a
            onClick={() => {
              this.setMode(nextMenu.mode);
            }}
          >
            <span style={{ fontWeight: '600', color: 'white' }}>
              {/* {nextMenu.label} */}
              Next
            </span>
            <span
              className="right"
              style={{ position: 'relative', right: '-5px' }}
            >
              <MaterialIcon
                icon="arrow_forward_ios"
                size={25}
                color="white"
              />
            </span>
          </a>
        </div>
      );
    }
  }

  getMobilePrev(mode, mobileMenu) {
    let currentMenu = mobileMenu[mode];
    if (currentMenu.first !== true) {
      let arrayNum = mode - 1;
      let nextMenu = mobileMenu[arrayNum];
      return (
        <div
          style={{ float: 'left', position: 'relative', top: '8px' }}
        >
          <a
            onClick={() => {
              this.setMode(nextMenu.mode);
            }}
          >
            <span style={{ fontWeight: '600', color: 'white' }}>
              Back
            </span>
            <span className="left">
              <MaterialIcon
                icon="arrow_back_ios"
                size={25}
                color="white"
              />
            </span>
          </a>
        </div>
      );
    }
  }

  getMobileCurrent(mode, mobileMenu) {
    let total = mobileMenu.length;
    let currentMenu = mobileMenu[mode];
    return (
      <div>
        <span style={{ fontWeight: '600', color: 'white' }}>
          {currentMenu.label}
        </span>
      </div>
    );
  }

  getMobileMenu() {
    let mobileMenu = [
      {
        label: 'Details',
        mode: 0,
        last: false,
        first: true,
      },
      {
        label: 'Images',
        mode: 1,
        last: false,
        first: false,
      },
      {
        label: 'Tags',
        mode: 2,
        last: true,
        first: false,
      },
    ];

    return (
      <div
        style={{
          background: '#353535',
          paddingTop: '5px',
          paddingBottom: '5px',
          position: 'fixed',
          bottom: '0px',
          width: '100%',
        }}
      >
        <Row>
          <Col xs={4} md={4} sm={4} lg={4}>
            <div style={{ textAlign: 'center' }}>
              {this.getMobilePrev(this.state.mode, mobileMenu)}
            </div>
          </Col>
          <Col xs={4} md={4} sm={4} lg={4}>
            <div style={{ textAlign: 'center' }}>
              {this.getMobileCurrent(this.state.mode, mobileMenu)}
              <div className="paginationInner">
                {mobileMenu.map((menu, index) => (
                  <div
                    className={
                      menu.mode === this.state.mode
                        ? 'dot active'
                        : 'dot'
                    }
                  />
                ))}
              </div>
            </div>
          </Col>
          <Col xs={4} md={4} sm={4} lg={4}>
            <div style={{ textAlign: 'center' }}>
              {this.getMobileNext(this.state.mode, mobileMenu)}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const {
      title,
      categories,
      error,
      website,
      twitter,
      twitch,
      tiktok,
      facebook,
      patreon,
      bandcamp,
      instagram,
      youtube,
      locationHero,
      slug,
      locationId,
      locationThumb,
      phone,
      zoneId,
    } = this.state;
    const isInvalid =
      categories === '' ||
      title === '' ||
      locationHero === '' ||
      locationId === '' ||
      locationThumb === '' ||
      phone === '' ||
      website === '' ||
      twitter === '' ||
      twitch === '' ||
      tiktok === '' ||
      facebook === '' ||
      patreon === '' ||
      bandcamp === '' ||
      instagram === '' ||
      youtube === '' ||
      slug === '' ||
      zoneId === '';

    return (
      <div style={{ height: '100%', display: 'block' }} id="vendor">
        <div>{this.getBannerCropper(this.state.cropping)}</div>
        <div>
          {this.getThumbnailCropper(this.state.croppingThumb)}
        </div>

        <Formik
          enableReinitialize
          initialValues={{
            title: this.state.title,
            slug: this.state.slug,
            categories: '',
            zoneId: this.state.zoneId,
            locationHero: '',
            locationId: '',
            locationThumb: '',
            packages: '',
            description: this.state.description,
            longDescription: this.state.longDescription,
            website: this.state.website,
            twitter: this.state.twitter,
            twitch: this.state.twitch,
            tiktok: this.state.tiktok,
            facebook: this.state.facebook,
            patreon: this.state.patreon,
            bandcamp: this.state.bandcamp,
            instagram: this.state.instagram,
            youtube: this.state.youtube,
          }}
          // validate={values => {
          //   let errors = {};
          //   if (!values.title) {
          //     errors.title = "Required";
          //   } else if (
          //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          //   ) {
          //     errors.title = "Required";
          //   }
          //   return errors;
          // }}
          onSubmit={(values) => {
            this.saveLocation(this.state.editorState, values);
          }}
        >
          {({ isSubmitting }) => (
            <Form
              style={{ height: '100%', display: 'block' }}
              onKeyDown={this.onKeyDown}
            >
              {this.getTitleBar('Manage Your Page')}
              <div className="vendorLeftNav mobileHide">
                <div style={{ padding: '10px' }}>
                  <span style={{ fontWeight: '600', color: 'white' }}>
                    SETUP
                  </span>
                </div>

                {this.getLeftMenu()}
              </div>

              <Container>{this.getStep(this.state.mode)}</Container>
            </Form>
          )}
        </Formik>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.toolbar{display:none} #analytics{height: 100%} .maincontent{margin-top: 60px; top:0px !important} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.location,
    tags: state.tags.tags,
    mode: state.mode.mode,
    profile: state.profile.profile,
    vendor: state.vendor.vendor,
    vendorEntertainment: state.vendorEntertainment.entertainment,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    // setLocations: (location) => {
    //   dispatch(setLocations(location));
    // },
    getVendorEntertainment: (vendorId) => {
      dispatch(getVendorEntertainment(vendorId));
    },
    getEntertainment: (zoneId) => {
      dispatch(getEntertainment(zoneId));
    },
    getTimedEvents: (zoneId) => {
      dispatch(getTimedEvents(zoneId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorEntertainmentEdit);

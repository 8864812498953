// import geolib from 'geolib';
import moment from 'moment';
import MaterialIcon from 'material-icons-react';
import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  UncontrolledCollapse,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
} from 'reactstrap';
import ConversationModal from '../components/ConversationModal';
import firebase from '../config/firebase';

// Images
import whiteLogo from '../images/trevel_logo_white.png';
import { getUserAwards } from '../sagas/userAwardsSaga/reducer';
// Reducers
import { finishEvent } from '../sagas/userEventsSaga/reducer';
import { ContinuousColorLegend } from 'react-vis';

const query = require('array-query');
const _ = require('lodash');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '370px',
    padding: '0px',
  },
};

const conversationModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '370px',
    padding: '0px',
    height: '100%',
    width: '100%',
  },
};

const AnyReactComponent = ({ text }) => (
  <div>
    <center>
      <img src="https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fgoicon.png?alt=media&token=44afb950-2e1a-4e8a-b7e2-a0178a49fd4f" />
    </center>
  </div>
);

// class GMapReact extends React.Component {
//   render() {
//     const { zoom, location } = this.props;
//     return (
//       <div style={{ height: '100%', width: '100%' }}>
//         <GoogleMapReact
//           bootstrapURLKeys={{
//             key: 'AIzaSyBGGWTnJQdshIr_HSsNYJREN_vWIlxZUX8',
//           }}
//           center={[
//             location.address.longitude,
//             location.address.latitude,
//           ]}
//           zoom={zoom}
//         >
//           <AnyReactComponent
//             lat={location.address.longitude}
//             lng={location.address.latitude}
//             text={location.locationTitle}
//           />
//         </GoogleMapReact>
//       </div>
//     );
//   }
// }

export class ActiveEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      loading: false,
      showPromo: false,
      transportModal: false,
      activePromo: {
        reward: {
          text: '',
        },
        criteria: {
          text: '',
        },
      },
      validate: false,
      redeem: false,
      vendorUsers: null,
      showConfirm: false,
      code: '',
      mode: 2,
      inBetween: true,
    };
    this.openTransportModal = this.openTransportModal.bind(this);
    this.closeTransportModal = this.closeTransportModal.bind(this);
    this.openFinishModal = this.openFinishModal.bind(this);
    this.closeFinishModal = this.closeFinishModal.bind(this);
    this.openConversationModal =
      this.openConversationModal.bind(this);
    this.closeConversationModal =
      this.closeConversationModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.openPromoModal = this.openPromoModal.bind(this);
    this.closePromoModal = this.closePromoModal.bind(this);
  }
  static defaultProps = {
    zoom: 15,
  };

  UNSAFE_componentWillMount() {
    if (this.props.activeEvent.eventStarted === false) {
      this.props.history.push('/events/upcoming');
    } else {
      // this.getLocations();
      this.stepStepCount(this.props.activeEvent);
      // this.getGeoLocation();
    }
  }

  // getGeoLocation = () => {
  //   let self = this;
  //   if (navigator.geolocation) {
  //     //check if geolocation is available
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         self.setState({
  //           currentLongitude: position.coords.longitude,
  //           currentLatitude: position.coords.latitude,
  //           error: null,
  //           loading: false,
  //         });
  //       },
  //       (error) => self.setState({ error: error.message }),
  //       {
  //         enableHighAccuracy: true,
  //         timeout: 20000,
  //         maximumAge: 1000,
  //       },
  //     );
  //   }
  // };

  // Edit History Key
  // 8 = user finished
  // 9 = event closed

  finishEvent(profile) {
    const fullName = profile.firstName + ' ' + profile.lastName;
    let userId = profile.userId;
    let event = this.props.activeEvent;
    event.users[userId].finished = true;
    let finishedArray = [];
    let usersArray = [];
    Object.keys(event.users).forEach((key) => {
      usersArray.push(event.users[key]);
      if (
        event.users[key].finished !== undefined &&
        event.users[key].finished === true
      ) {
        finishedArray.push(event.users[key]);
      }
    });
    // Is Everyone Finshed?
    if (finishedArray.length === usersArray.length) {
      event.status = 9;
    }
    // Update History
    const updatedHistory = event.editHistory;
    const localDate = new Date();
    const newHistory = {
      editDate: localDate,
      eventType: 8,
      fullName: fullName,
      userId: userId,
    };
    // Is Everyone Finshed?
    updatedHistory.push(newHistory);
    if (finishedArray.length === usersArray.length) {
      const closeHistory = {
        editDate: localDate,
        eventType: 9,
        fullName: fullName,
        userId: userId,
      };
      updatedHistory.push(closeHistory);
    }
    event.editHistory = updatedHistory;
    this.props.finishEvent(event);
  }

  getTab(tabMode, tabLable, mode) {
    if (tabMode === mode) {
      return (
        <div className="text-center borderRight actionButtonActive">
          {tabLable}
        </div>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.setMode(tabMode);
          }}
        >
          <div className="text-center borderRight actionButton">
            {tabLable}
          </div>
        </a>
      );
    }
  }

  setMode(mode) {
    this.setState({
      mode,
    });
  }

  showConfirm(mode) {
    this.setState({
      showConfirm: mode,
    });
  }

  getConfirmMessage(award) {
    if (this.state.showConfirm === true) {
      return (
        <div className="section pushfifty padding-forty">
          <span className="confirmMessage">
            Are you sure you want to redeem this Award?{' '}
          </span>
          {/* <a
            onClick={() => {
              this.validate(true);
            }}
          > */}
          <a
            onClick={() => {
              this.requestRedemption(award);
            }}
          >
            <span className="confirmBtn">Yes!</span>
          </a>
        </div>
      );
    }
  }

  showRedeemScreen(award) {
    return (
      <div id="loadscreen  pin-area">
        <div className="section text-center pin-area">
          <Container>
            <Row className="no-margin">
              <Col
                xs={1}
                md={1}
                sm={1}
                lg={1}
                className="no-padding"
              />
              <Col
                xs={10}
                md={10}
                sm={10}
                lg={10}
                className="no-padding"
              >
                <h2 className="white">Redeem Award</h2>
                <h3 className="white">{award.reward.text}</h3>
              </Col>
              <Col xs={1} md={1} sm={1} lg={1} className="no-padding">
                <a
                  onClick={() => {
                    this.closeAward();
                  }}
                >
                  <MaterialIcon
                    icon="close"
                    size={25}
                    color="white"
                  />
                </a>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="text-center pushfifty">
          <a
            onClick={() => {
              this.showConfirm(true);
            }}
            className="pin-code-btn"
          >
            <MaterialIcon
              icon="check_circle"
              size={100}
              color="white"
            />
          </a>
          <div>{this.getConfirmMessage(award)}</div>
        </div>

        <style
          dangerouslySetInnerHTML={{
            __html:
              '.authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important; background: #f21d59 !important} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }

  getHappyHoursAndPromos(location) {
    if (location.happyHours !== undefined) {
      return <div>{this.happyHourSection(location.happyHours)}</div>;
    }
  }

  getWebsite(location) {
    if (location.website !== undefined && location.website !== null) {
      return (
        <a
          href={location.website}
          target="_blank"
          rel="noopener noreferrer"
          className="red"
        >
          <span className="right phone clear">Visit Website</span>
        </a>
      );
    }
  }

  getPhone(location) {
    if (location.phone !== undefined && location.phone !== null) {
      let path = 'tel:' + location.phone;
      return (
        <a href={path} className="red">
          <span className="right phone">{location.phone}</span>
        </a>
      );
    }
  }

  getSection(mode, location, numVouchers, userVouchers, awards) {
    let locationReady = {};
    if (
      location.locationId !== 'PQszNY3Mpy8oJXTQMooB' &&
      location.locationId !== 'WkhbxzFvfPjnDTXCBVyr' &&
      location.locationId !== '-LKT3cWR6JqNdUTEST'
    ) {
      if (location.locationId !== null) {
        let locationDetails = query('locationId')
          .is(location.locationId)
          .on(this.props.locations);
        locationReady = locationDetails[0];
      } else {
        locationReady = location;
        if (location.event === true) {
          let eventDetails = query('eventId')
            .is(location.eventId)
            .on(this.props.timedEvents);
          location.locationHero = eventDetails[0].eventHero;
          location.locationDescription =
            eventDetails[0].eventDescription;
          location.website = eventDetails[0].website;
        }
      }
    } else {
      let locationDetails = query('locationId')
        .is(location.locationId)
        .on(this.props.demo.demoLocations);
      locationReady = locationDetails[0];
    }

    // const distance = geolib.getDistance(
    //   {
    //     latitude: location.address.longitude,
    //     longitude: location.address.latitude,
    //   },
    //   {
    //     latitude: this.state.currentLatitude,
    //     longitude: this.state.currentLongitude,
    //   },
    // );
    // const miles = geolib.convertUnit('mi', distance, 2); // -> 14,21
    let awardArray = [];
    if (location.locationId !== null) {
      awards.forEach((award) => {
        if (
          award.promo.locationId === location.locationId &&
          award.active === true
        ) {
          awardArray.push(award);
        }
      });
    }

    let awardCount = awardArray.length;
    if (mode === 1) {
      return (
        <Card style={{ height: '100%' }}>
          <CardImg
            top
            width="100%"
            src={this.getImage('hero', location, key)}
            alt="articleHero"
          />

          {/* <div className="distance-bar">
              <span>{miles} miles</span>
            </div> */}
          <CardBody style={{ paddinBottom: '80px', height: '100%' }}>
            <Row className="no-margin">
              <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
                <span className="address bold">
                  {location.address.street}
                </span>
                <span className="address">
                  {location.address.city} {location.address.state},{' '}
                  {location.address.zip}
                </span>
              </Col>
              <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
                {this.getPhone(locationReady)}
                {this.getWebsite(locationReady)}
              </Col>
            </Row>

            <br />

            <div className="card-section htmlBox">
              <span
                dangerouslySetInnerHTML={{
                  __html: location.locationDescription,
                }}
              />
            </div>
            {this.getHappyHoursAndPromos(location)}
          </CardBody>
        </Card>
      );
    } else if (mode === 2) {
      if (this.state.showPromo === true) {
        return <div>{this.showActivePromoScreen(location)}</div>;
      }
      let promos = [];
      if (
        location.locationId !== 'PQszNY3Mpy8oJXTQMooB' &&
        location.locationId !== 'WkhbxzFvfPjnDTXCBVyr' &&
        location.locationId !== '-LKT3cWR6JqNdUTEST'
      ) {
        promos = this.props.promos;
      } else {
        promos = this.props.demo.demoPromos;
      }

      return (
        <Card style={{ height: '100%' }}>
          {this.getImage('hero', location)}
          {/* <CardImg
                top
                width="100%"
                src={
                  'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
                  locationDetails[0].locationHero +
                  '?alt=media'
                }
                alt="articleHero"
              /> */}
          <div
            style={{
              width: '100%',

              position: 'relative',
            }}
          >
            {/* <GMapReact zoom={15} location={location} /> */}
          </div>
          {/* <div className="distance-bar">
              <span>{miles} miles</span>
            </div> */}
          <CardBody style={{ paddinBottom: '80px', height: '100%' }}>
            <Row className="no-margin">
              <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
                <span className="address bold">
                  {location.address.street}
                </span>
                <span className="address">
                  {location.address.city} {location.address.state},{' '}
                  {location.address.zip}
                </span>
              </Col>
              <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
                {this.getPhone(locationReady)}
                {this.getWebsite(locationReady)}
              </Col>
            </Row>

            <br />

            <div className="card-section htmlBox">
              <p
                dangerouslySetInnerHTML={{
                  __html: locationReady.locationDescription,
                }}
                className="editoOverRide"
              />
            </div>
            {locationReady.locationDescriptionLong !== undefined &&
              locationReady.locationDescriptionLong.length > 10 && (
                <div className="card-section">
                  <h5>
                    {locationReady.event !== true ? 'Our Story' : ''}
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: locationReady.locationDescriptionLong,
                    }}
                    className="editoOverRide"
                  />
                </div>
              )}

            {this.getHappyHoursAndPromos(location)}
            {this.getPromoLinks(location.locationId, promos)}
          </CardBody>
        </Card>
      );
    } else {
      return (
        <div className="section">
          {awardCount > 0 ? (
            <div>
              {awardArray.map((award, index) => (
                <div>{this.listVoucher(award, index)}</div>
              ))}
            </div>
          ) : (
            <div className="text-center">
              <span>No Rewards Available</span>
            </div>
          )}
        </div>
      );
    }
  }

  getImage(type, eventLocation, key) {
    let location = {};
    if (
      eventLocation.locationId !== 'PQszNY3Mpy8oJXTQMooB' &&
      eventLocation.locationId !== 'WkhbxzFvfPjnDTXCBVyr' &&
      eventLocation.locationId !== '-LKT3cWR6JqNdUTEST'
    ) {
      if (eventLocation.locationId !== null) {
        let locationDetails = query('locationId')
          .is(eventLocation.locationId)
          .on(this.props.locations);
        location = locationDetails[0];
      } else {
        location = eventLocation;
      }
    } else {
      let locationDetails = query('locationId')
        .is(eventLocation.locationId)
        .on(this.props.demo.demoLocations);
      location = locationDetails[0];
    }
    let path = '';
    let className = '';
    if (type === 'hero') {
      let status = location.locationHero.startsWith('http');
      if (status === true) {
        path = location.locationHero;
      } else {
        if (
          location !== undefined &&
          location.locationHero !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationHero +
            '?alt=media';
        }
      }
    } else if (type === 'thumb') {
      if (key === this.state.step) {
        className = 'activeEventLocationSelected';
      } else {
        className = 'activeEventLocation';
      }

      let status = location.locationThumb.startsWith('http');
      if (status === true) {
        path = location.locationThumb;
      } else {
        if (
          location !== undefined &&
          location.locationThumb !== undefined
        ) {
          path =
            'https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/location_images%2F' +
            location.locationThumb +
            '?alt=media';
        }
      }
    }
    return (
      <img
        src={path}
        alt={location.locationTitle}
        className={className}
      />
    );
  }

  getThumbs() {
    return (
      <Container>
        <Row>
          {this.props.activeEvent.packageDetails.locations.map(
            (location, key) => {
              return (
                <Col
                  xs={4}
                  md={4}
                  sm={4}
                  lg={4}
                  className="no-padding"
                >
                  {this.getImage('thumb', location, key)}
                </Col>
              );
            },
          )}
        </Row>
      </Container>
    );
  }

  getStep(step, packageDetails, locations) {
    // const location = query("locationId")
    //   .is(packageDetails.locations[step].locationId)
    //   .on(locations);

    const location = locations[step];

    const userVouchers = query('locationId')
      // .is(location[0].locationId)
      .is(location.locationId)
      .and('voucherStatus')
      .is(true)
      .on(this.props.vouchers);

    const numVouchers = _.size(userVouchers);

    // const uberUrl =
    //   "https://m.uber.com/ul/?action=setPickup&dropoff[latitude]=" +
    //   location[0].address.longitude +
    //   "&dropoff[longitude]=" +
    //   location[0].address.latitude +
    //   "&dropoff[nickname]=" +
    //   location[0].locationTitle;

    // const lyftUrl =
    //   "https://lyft.com/ride?id=lyft&destination[latitude]=" +
    //   location[0].address.longitude +
    //   "&destination[longitude]=" +
    //   location[0].address.latitude;

    let newtitle = location.locationTitle.replace(/\s/g, '');

    const uberUrl =
      'https://m.uber.com/ul/?client_id=gQEc7MHF0_GHui3bm46rSdpG-txW9wRs&action=setPickup&dropoff[latitude]=' +
      location.address.longitude +
      '&dropoff[longitude]=' +
      location.address.latitude +
      '&dropoff[nickname]=' +
      newtitle;

    const lyftUrl =
      'https://lyft.com/ride?id=lyft&destination[latitude]=' +
      location.address.longitude +
      '&destination[longitude]=' +
      location.address.latitude;

    const mapsUrl =
      'http://maps.apple.com/?daddr=' +
      locations[step].address.longitude +
      ',' +
      locations[step].address.latitude +
      '&z=10&t=s&dirflg=d';

    if (this.state.inBetween) {
      return (
        <div
          className="section text-center"
          style={{ height: '100%' }}
        >
          <span
            style={{
              fontSize: '24px',
              margin: '10px 0px 30px 0px',
            }}
          >
            {this.state.step === 0 ? 'First Stop' : 'Next Stop'}
          </span>
          <Container>
            <Row style={{ margin: '20px 0px 0px 0px' }}>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding text-center"
              >
                {this.getThumbs()}
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding text-center"
              >
                <div style={{ margin: '50px 0px 30px 0px' }}>
                  <h4 style={{ margin: '0px 0px 15px 0px' }}>
                    {location.locationTitle}
                  </h4>
                  <span
                    style={{
                      fontSize: '18px',
                      clear: 'both',
                      display: 'block',
                    }}
                  >
                    {location.address.street}
                  </span>
                  <span style={{ fontSize: '18px' }}>
                    {location.address.city} {location.address.state},{' '}
                    {location.address.zip}
                  </span>
                </div>
              </Col>
            </Row>
            <Row style={{ margin: '40px 0px 0px 0px' }}>
              <Col
                xs={4}
                md={4}
                sm={4}
                lg={4}
                className="no-padding text-center"
              >
                <div className="action-item">
                  <a
                    className="action-icon"
                    href={uberUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MaterialIcon
                      icon="directions_car"
                      size={35}
                      color="white"
                    />
                    <span className="action-item-text">Uber</span>
                  </a>
                </div>
              </Col>

              <Col
                xs={4}
                md={4}
                sm={4}
                lg={4}
                className="no-padding text-center"
              >
                <div className="action-item">
                  <a className="action-icon" href={lyftUrl}>
                    <MaterialIcon
                      icon="directions_car"
                      size={35}
                      color="white"
                    />
                    <span className="action-item-text">LYFT</span>
                  </a>
                </div>
              </Col>

              <Col
                xs={4}
                md={4}
                sm={4}
                lg={4}
                className="no-padding text-center"
              >
                <div className="action-item">
                  <a className="action-icon" href={mapsUrl}>
                    <MaterialIcon
                      icon="directions"
                      size={35}
                      color="white"
                    />
                    <span className="action-item-text">
                      Directions
                    </span>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
          <style
            dangerouslySetInnerHTML={{
              __html: ' .maincontent{top: 44px  }',
            }}
          />
        </div>
      );
    }
    return (
      <div style={{ height: '100%' }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            top: '40px',
            position: 'relative',
          }}
        >
          {/* <GMapReact zoom={15} location={location[0]} /> */}

          {/* <div className="promo-bar full-width">
            <Row>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getTab(1, 'Info', this.state.mode)}
              </Col>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getTab(2, 'Offers', this.state.mode)}
              </Col>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getTab(3, 'Rewards', this.state.mode)}
              </Col>
            </Row>
          </div> */}

          {this.getSection(
            this.state.mode,
            location,
            numVouchers,
            userVouchers,
            this.props.awards,
          )}
        </div>
        <Modal
          isOpen={this.state.transportModalVisible}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeTransportModal}
          style={customStyles}
          contentLabel="Transport Modal"
        >
          <div className="full-height">
            <div className="modalHeader">
              <Row className="no-margin">
                <Col
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="no-padding"
                >
                  <a
                    onClick={() => {
                      this.closeTransportModal();
                    }}
                  >
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                    />
                  </a>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="modalHeader-text">
                      Call a Ride
                    </span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  {/* <center>
                <MaterialIcon
                  icon="check"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.createCustomEvent(
                      this.props.profile,
                      this.props.customEvent,
                      this.props.locations,
                      this.props.packages
                    );
                  }}
                />
              </center> */}
                </Col>
              </Row>
            </div>
            <div className="section">
              <Container>
                <Row className="no-margin">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding text-center"
                  >
                    <div>
                      <a href={uberUrl}>UBER</a>
                    </div>
                  </Col>
                </Row>
                <Row className="no-margin">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding text-center"
                  >
                    <div>
                      <a href={lyftUrl}>LYFT</a>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.finishModalVisible}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeFinishtModal}
          style={customStyles}
          contentLabel="Transport Modal"
        >
          <div className="full-height">
            <div className="modalHeader">
              <Row className="no-margin">
                <Col
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="no-padding"
                >
                  <a
                    onClick={() => {
                      this.closeFinishModal();
                    }}
                  >
                    <MaterialIcon
                      icon="close"
                      size={25}
                      color="white"
                    />
                  </a>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="modalHeader-text">
                      Finish Event
                    </span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}>
                  {/* <center>
                <MaterialIcon
                  icon="check"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.createCustomEvent(
                      this.props.profile,
                      this.props.customEvent,
                      this.props.locations,
                      this.props.packages
                    );
                  }}
                />
              </center> */}
                </Col>
              </Row>
            </div>
            <div className="section">
              <Container>
                <Row className="no-margin wizard-section-border">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding text-center"
                  >
                    Ready to head home?
                  </Col>
                </Row>
                <Row className="no-margin">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding text-center"
                  >
                    <div>
                      <a
                        href="https://m.uber.com/ul/"
                        className="stackedRedButtonBlock "
                      >
                        Call UBER
                      </a>
                    </div>
                  </Col>
                </Row>
                <Row className="no-margin">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding text-center"
                  >
                    <div>
                      <a
                        href="https://lyft.com/ride"
                        className="stackedRedButtonBlock"
                      >
                        Call LYFT
                      </a>
                    </div>
                  </Col>
                </Row>
                <Row className="no-margin">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding text-center"
                  >
                    <div>
                      <a
                        href={mapsUrl}
                        className="stackedRedButtonBlock "
                      >
                        Get Directions
                      </a>
                    </div>
                  </Col>
                </Row>
                <Row className="no-margin">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding text-center"
                  >
                    <div>
                      <a
                        onClick={() => {
                          this.finishEvent(this.props.profile);
                        }}
                        className="stackedRedButtonBlock"
                      >
                        All Done
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.conversationModalVisible}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeConversationModal}
          style={conversationModalStyles}
          contentLabel="Conversation Modal"
        >
          <div className="modalHeader">
            <a
              onClick={() => {
                this.closeConversationModal();
              }}
            >
              <div className="modalCloser text-center">
                <MaterialIcon
                  icon="keyboard_arrow_down"
                  size={25}
                  color="white"
                />
              </div>
            </a>
            <a
              onClick={() => {
                this.closeConversationModal();
              }}
            >
              <span className="modalHeader-text">Conversation</span>
            </a>
          </div>

          <ConversationModal
            eventId={this.props.activeEvent.eventId}
            className="conversationModal"
          />
        </Modal>
        {/* <Modal
          isOpen={this.state.promoModalVisible}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closePromoModal}
          style={customStyles}
          contentLabel="Promo Modal"
        >
          <div className="full-height">
            <div className="modalHeader">
              <Row className="no-margin">
                <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
                  <a
                    onClick={() => {
                      this.closePromoModal();
                    }}
                  >
                    <MaterialIcon icon="close" size={25} color="white" />
                  </a>
                </Col>
                <Col lg={8} md={8} sm={8} xs={8}>
                  <center>
                    <span className="modalHeader-text">Offer</span>
                  </center>
                </Col>
                <Col lg={2} md={2} sm={2} xs={2}> */}
        {/* <center>
                <MaterialIcon
                  icon="check"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.createCustomEvent(
                      this.props.profile,
                      this.props.customEvent,
                      this.props.locations,
                      this.props.packages
                    );
                  }}
                />
              </center> */}
        {/* </Col>
              </Row>
            </div>
            <div className="section">
              <Container>
                <Row className="no-margin wizard-section-border">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding text-center"
                  >
                    {this.state.activePromo.reward.text}
                  </Col>
                </Row>
              </Container>
              <div className="section">
                <p> {this.state.activePromo.criteria.text}</p>
              </div>
              <div className="text-center">
                <a
                  onClick={() => {
                    this.validate(true);
                  }}
                >
                  Validate!
                </a>
              </div>
            </div>
          </div>
        </Modal> */}
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important }',
          }}
        />
      </div>
    );
  }

  validate(value) {
    this.setState({
      validate: value,
    });
  }

  closeInBetween() {
    this.setState({
      inBetween: false,
    });
  }

  nextStep(step) {
    let inBetween = true;
    if (this.state.inBetween === true) {
      inBetween = false;
    }
    const nextStep = step + 1;
    this.setState({
      step: nextStep,
      inBetween,
    });
  }

  prevStep(step) {
    let inBetween = true;
    if (this.state.inBetween === true) {
      inBetween = false;
    }
    const prevStep = step - 1;
    this.setState({
      step: prevStep,
      inBetween,
    });
  }

  exitEvent() {
    this.props.history.push('/events/upcoming');
  }

  // getLocations() {
  //   const packageDetails = query("packageId")
  //     .is(this.props.activeEvent.packageDetails.packageId)
  //     .on(this.props.packages);
  //   const stepCount = packageDetails[0].locations.length - 1;
  //   this.setState({
  //     packageDetails: packageDetails[0],
  //     stepCount
  //   });
  // }

  stepStepCount(event) {
    const stepCount = event.packageDetails.locations.length - 1;
    this.setState({
      stepCount,
    });
  }

  setActivePromo(promo) {
    this.setState({
      activePromo: promo,
    });
    this.openPromoModal();
  }

  setActiveCriteria(promo) {
    this.setState({
      activePromo: promo,
    });
    this.openPromoModal();
  }

  getBackButton() {
    if (this.state.step === 0) {
      return (
        <span className="left padding-top-five">
          <MaterialIcon
            icon="keyboard_arrow_left"
            size={30}
            color="rgb(128, 128, 128)"
          />
          {/* Previous */}
        </span>
      );
    } else {
      return (
        <a
          className="left red padding-top-five"
          onClick={() => {
            this.prevStep(this.state.step);
          }}
        >
          <MaterialIcon
            icon="keyboard_arrow_left"
            size={30}
            color="#f21d59"
          />
          {/* Previous */}
        </a>
      );
    }
  }

  getNextButton() {
    if (this.state.step === this.state.stepCount) {
      return (
        <span
          className="right red padding-top-five"
          onClick={() => {
            this.openFinishModal();
          }}
        >
          Finish
        </span>
      );
    } else {
      return (
        <span
          className="right red padding-top-five"
          onClick={() => {
            this.nextStep(this.state.step);
          }}
        >
          <MaterialIcon
            icon="keyboard_arrow_right"
            size={30}
            color="#f21d59"
            onClick={() => {
              this.nextStep(this.state.step);
            }}
          />
          {/* Next */}
        </span>
      );
    }
  }

  // Promo Stuff
  // Ask for Validation (Reward Started)
  getValidated(index, userReward) {
    let newAward = userReward;
    newAward.criteria[index].activeCriteria = true;
    this.setState({
      activeCriteriaIndex: index,
      criteriaDetailView: false,
    });
    const db = firebase.firestore();
    const update = db.collection('user_awards').doc(newAward.awardId);
    const setDoc = update
      .update({
        criteria: newAward.criteria,
        validatorMode: true,
        validationType: 1, // 2 means validate criteria
      })
      .then((ref) => {
        // this.setState({
        //   showConfirm: false,
        //   validate: false,
        //   redeem: false,
        // });
      });
  }

  // Ask for Validation (Reward New)
  getValidatedNew(index, reward, promo, profile) {
    this.setState({
      activeCriteriaIndex: index,
      criteriaDetailView: false,
    });
    let rewardLocations = [];
    if (reward.locations !== undefined) {
      rewardLocations = reward.locations;
    }
    // Set Timestamp
    const localDate = new Date();
    // Get New Event ID to use later
    const db = firebase.firestore();
    const newAwardRef = db.collection('user_awards').doc();
    const dbref = newAwardRef.id;

    let possibleRewards = [];

    reward.reward.forEach((rewardx, x) => {
      let rewardObject = {
        id: rewardx.id,
        title: rewardx.title,
        description: '',
        validLocations: rewardLocations,
      };
      possibleRewards.push(rewardObject);
    });

    //Build Object
    let award = {
      active: true,
      creationDetails: {
        startDate: localDate,
      },
      earned: false,
      owner: {
        firstName: profile.firstName,
        lastName: profile.lastName,
        userId: profile.userId,
      },
      promo: {
        id: promo.id,
        locations: promo.locations,
        vendorId: promo.account.vendorId,
      },
      rewards: possibleRewards,
      redemption: {
        redeemed: false,
      },
      validatorMode: true,
      validationType: 1, // 2 means validate criteria
    };
    // Set Criteria
    let criteriaArray = reward.criteria;
    reward.criteria.forEach((criteria, indexx) => {
      if (index === indexx) {
        criteriaArray[indexx].activeCriteria = true;
      } else {
        criteriaArray[indexx].activeCriteria = false;
      }
      criteriaArray[indexx].satisfied = false;
      criteriaArray[indexx].validationHistory = [];
    });
    award.criteria = criteriaArray;

    const setDoc = db
      .collection('user_awards')
      .doc(dbref)
      .set(award)
      .then((ref) => {
        return ref;
      });
  }

  cancelValidation(index, userReward) {
    let newAward = userReward;
    newAward.criteria[index].activeCriteria = false;
    const db = firebase.firestore();
    const update = db.collection('user_awards').doc(newAward.awardId);
    const setDoc = update
      .update({
        // criteria: newAward.criteria,
        validatorMode: false,
        validationType: 0,
      })
      .then((ref) => {
        // this.setState({
        //   showConfirm: false,
        //   validate: false,
        //   redeem: false,
        // });
      });
  }

  cancelRedemption(userReward) {
    let newAward = userReward;
    const db = firebase.firestore();
    const update = db.collection('user_awards').doc(newAward.awardId);
    const setDoc = update
      .update({
        // criteria: newAward.criteria,
        validatorMode: false,
        validationType: 0,
      })
      .then((ref) => {
        // this.setState({
        //   showConfirm: false,
        //   validate: false,
        //   redeem: false,
        // });
      });
  }

  // Ask for Validation (Reward Started)
  requestRedemption(userReward) {
    let newAward = userReward;
    const db = firebase.firestore();
    const update = db.collection('user_awards').doc(newAward.awardId);
    const setDoc = update
      .update({
        validatorMode: true,
        validationType: 2, // 2 means redeem
      })
      .then((ref) => {});

    this.setState({
      redeem: false,
    });
  }

  getRewardIcon(index, userReward) {
    if (
      userReward[0] !== undefined &&
      userReward[0].earned === true
    ) {
      return (
        <MaterialIcon
          icon="favorite"
          size={25}
          color="red"
          // onClick={() => {
          //   this.resetPackage();
          // }}
        />
      );
    } else if (index === 0) {
      return (
        <MaterialIcon
          icon="lock_open"
          size={25}
          color="red"
          // onClick={() => {
          //   this.resetPackage();
          // }}
        />
      );
    } else {
      // if unlocked...
      return (
        <MaterialIcon
          icon="lock_open"
          size={25}
          color="red"
          // onClick={() => {
          //   this.resetPackage();
          // }}
        />
      );
    }
  }

  getCriteria(
    criteria,
    index,
    userReward,
    reward,
    promo,
    locationId,
  ) {
    // Set Active Reward
    let matchx = false;
    let activeReward = {};

    if (userReward.promo.id === promo.id) {
      matchx = true;
      activeReward = userReward;
    }
    // Is it Once Per Day?
    let activeStatus = true;
    if (
      activeReward.criteria !== undefined &&
      activeReward.criteria[index].oncePerDay === true
    ) {
      let date = '';
      let validationHistory =
        activeReward.criteria[index].validationHistory;

      let confirmedValidations = [];
      validationHistory.forEach((history) => {
        if (history.validated === true) {
          confirmedValidations.push(history);
        }
      });
      let historyCount = confirmedValidations.length;
      if (historyCount > 0) {
        let index = historyCount - 1;
        date = confirmedValidations[index].date.seconds * 1000;
        let hours = moment().diff(moment(date), 'hours');
        if (hours < 24) {
          activeStatus = false;
        }
      }
    }

    if (matchx === true) {
      if (criteria.type === 1) {
        if (criteria.numTimes < 2) {
          if (
            activeReward === undefined ||
            activeReward.earned === false
          ) {
            if (activeReward.criteria[index].satisfied === false) {
              let match = false;
              criteria.locations.forEach((location) => {
                if (location.value === locationId) {
                  match = true;
                }
              });
              if (match === true) {
                return (
                  <div
                    className="criteriaBorder"
                    onClick={() => {
                      this.setCriteriaDetailView(
                        true,
                        criteria,
                        index,
                        activeReward,
                        activeStatus,
                      );
                    }}
                    // onClick={() => {
                    //   activeStatus === true &&
                    //     this.getValidated(index, activeReward);
                    // }}
                  >
                    <Row className=" no-margin no-padding">
                      <Col
                        xs={1}
                        md={1}
                        sm={1}
                        lg={1}
                        className="left no-padding"
                      >
                        <span className="rewardIcon">
                          <MaterialIcon
                            icon="check_box_outline_blank"
                            size={25}
                            color="#9a0039"
                          />
                        </span>
                      </Col>
                      <Col
                        xs={11}
                        md={11}
                        sm={11}
                        lg={11}
                        className="left no-padding"
                      >
                        <span className="criteriaText">
                          {criteria.text}
                        </span>
                        <br />
                        {criteria.locations.map((location, index) => (
                          <span className="locationTag">
                            {location.label}
                          </span>
                        ))}
                      </Col>
                    </Row>
                  </div>
                );
              } else {
                return (
                  <div
                    className="criteriaBorder"
                    // onClick={() => {
                    //   this.getValidated(index, activeReward);
                    // }}
                  >
                    <Row className=" no-margin no-padding">
                      <Col
                        xs={1}
                        md={1}
                        sm={1}
                        lg={1}
                        className="left no-padding"
                      >
                        <span className="rewardIcon">
                          <MaterialIcon
                            icon="check_box_outline_blank"
                            size={25}
                            color="#9a0039"
                          />
                        </span>
                      </Col>
                      <Col
                        xs={11}
                        md={11}
                        sm={11}
                        lg={11}
                        className="left no-padding"
                      >
                        <span className="criteriaText">
                          {criteria.text}
                        </span>
                        <br />
                        {criteria.locations.map((location, index) => (
                          <span className="locationTag">
                            {location.label}
                          </span>
                        ))}
                        <span style={{ clear: 'both' }}>
                          <b>Not Available at This Location</b>
                        </span>
                      </Col>
                    </Row>
                  </div>
                );
              }
            } else {
              let match = false;
              criteria.locations.forEach((location) => {
                if (location.value === locationId) {
                  match = true;
                }
              });
              if (match === true) {
                return (
                  <div
                    className="criteriaBorder"
                    // onClick={() => {
                    //   this.getValidated(index, activeReward);
                    // }}
                  >
                    <Row className=" no-margin no-padding">
                      <Col
                        xs={1}
                        md={1}
                        sm={1}
                        lg={1}
                        className="left no-padding"
                      >
                        <span className="rewardIcon">
                          <MaterialIcon
                            icon="check_box"
                            size={25}
                            color="#9a0039"
                          />
                        </span>
                      </Col>
                      <Col
                        xs={11}
                        md={11}
                        sm={11}
                        lg={11}
                        className="left no-padding"
                      >
                        <span className="criteriaText">
                          {criteria.text}
                        </span>
                        <br />
                        {criteria.locations.map((location, index) => (
                          <span className="locationTag">
                            {location.label}
                          </span>
                        ))}
                      </Col>
                    </Row>
                  </div>
                );
              } else {
                return (
                  <div
                    className="criteriaBorder"
                    // onClick={() => {
                    //   this.getValidated(index, activeReward);
                    // }}
                  >
                    <Row className=" no-margin no-padding">
                      <Col
                        xs={1}
                        md={1}
                        sm={1}
                        lg={1}
                        className="left no-padding"
                      >
                        <span className="rewardIcon">
                          <MaterialIcon
                            icon="check_box"
                            size={25}
                            color="#9a0039"
                          />
                        </span>
                      </Col>
                      <Col
                        xs={11}
                        md={11}
                        sm={11}
                        lg={11}
                        className="left no-padding"
                      >
                        <span className="criteriaText">
                          {criteria.text}
                        </span>
                        <br />
                        {criteria.locations.map((location, index) => (
                          <span className="locationTag">
                            {location.label}
                          </span>
                        ))}
                        <span style={{ clear: 'both' }}>
                          <b>Not Available at This Location</b>
                        </span>
                      </Col>
                    </Row>
                  </div>
                );
              }
            }
          } else {
            // if (userReward[0] === undefined || userReward[0].redemption.redeemed === false) {
            //   return (
            //   <a
            //   onClick={() => {
            //     this.requestRedemption(userReward[0]);
            //   }}
            //   className="redbutton text-center"
            // ><span>Redeem!</span>
            // </a>
            // )
            // } else {
            //   let myDate = userReward[0].redemption.date.seconds * 1000; // Unix ms timestamp
            //   let dayText = moment(myDate, "x")
            //     .subtract(10, "days")
            //     .calendar();
            //   return  <span>Redeemed: {dayText}</span>
            // }
          }
        } else {
          let currentNum = 0;
          if (criteria.type === 1) {
            if (
              activeReward.criteria[index].validationHistory !==
              undefined
            ) {
              activeReward.criteria[index].validationHistory.forEach(
                (validation) => {
                  if (validation.validated === true) {
                    currentNum = currentNum + 1;
                  }
                },
              );
            }
          }
          let width =
            ((currentNum / criteria.numTimes) * 100).toFixed(2) + '%';

          if (activeReward.criteria[index].satisfied === true) {
            let match = false;
            criteria.locations.forEach((location) => {
              if (location.value === locationId) {
                match = true;
              }
            });
            if (match === true) {
              return (
                <div
                  className="criteriaBorder"
                  // onClick={() => {
                  //   this.getValidated(index, activeReward);
                  // }}
                >
                  <Row className=" no-margin no-padding">
                    <Col
                      xs={1}
                      md={1}
                      sm={1}
                      lg={1}
                      className="left no-padding"
                    >
                      <span className="rewardIcon">
                        <MaterialIcon
                          icon="check_box"
                          size={25}
                          color="#9a0039"
                        />
                      </span>
                    </Col>
                    <Col
                      xs={11}
                      md={11}
                      sm={11}
                      lg={11}
                      className="left no-padding"
                    >
                      <span className="criteriaText">
                        {criteria.text}
                      </span>
                      <br />
                      {criteria.locations.map((location, index) => (
                        <span className="locationTag">
                          {location.label}
                        </span>
                      ))}
                    </Col>
                  </Row>
                </div>
              );
            } else {
              return (
                <div
                  className="criteriaBorder"
                  // onClick={() => {
                  //   this.getValidated(index, activeReward);
                  // }}
                >
                  <Row className=" no-margin no-padding">
                    <Col
                      xs={1}
                      md={1}
                      sm={1}
                      lg={1}
                      className="left no-padding"
                    >
                      <span className="rewardIcon">
                        <MaterialIcon
                          icon="check_box"
                          size={25}
                          color="#9a0039"
                        />
                      </span>
                    </Col>
                    <Col
                      xs={11}
                      md={11}
                      sm={11}
                      lg={11}
                      className="left no-padding"
                    >
                      <span className="criteriaText">
                        {criteria.text}
                      </span>
                      <br />
                      {criteria.locations.map((location, index) => (
                        <span className="locationTag">
                          {location.label}
                        </span>
                      ))}
                      <span style={{ clear: 'both' }}>
                        <b>Not Available at This Location</b>
                      </span>
                    </Col>
                  </Row>
                </div>
              );
            }
          } else {
            let match = false;
            criteria.locations.forEach((location) => {
              if (location.value === locationId) {
                match = true;
              }
            });
            if (match === true) {
              return (
                <div
                  className="criteriaBorder"
                  // onClick={() => {
                  //   activeStatus === true &&
                  //     this.getValidated(index, activeReward);
                  // }}
                  onClick={() => {
                    this.setCriteriaDetailView(
                      true,
                      criteria,
                      index,
                      activeReward,
                      activeStatus,
                    );
                  }}
                >
                  <Row className=" no-margin no-padding">
                    <Col
                      xs={1}
                      md={1}
                      sm={1}
                      lg={1}
                      className="left no-padding"
                    >
                      <span className="rewardIcon">
                        <MaterialIcon
                          icon="check_box_outline_blank"
                          size={25}
                          color="#9a0039"
                        />
                      </span>
                    </Col>
                    <Col
                      xs={11}
                      md={11}
                      sm={11}
                      lg={11}
                      className="left no-padding"
                    >
                      <span className="criteriaText">
                        {criteria.text}
                      </span>
                      <div id="progressBarBack">
                        <span className="right red">
                          <span className="progressGoal">
                            {criteria.numTimes}
                          </span>
                        </span>
                        <div
                          className="progressBar"
                          style={{ width: width }}
                        >
                          <span className="progressTotal">
                            {currentNum}
                          </span>
                        </div>
                      </div>
                      {criteria.locations.map((location, index) => (
                        <span className="locationTag">
                          {location.label}
                        </span>
                      ))}
                    </Col>
                  </Row>
                </div>
              );
            } else {
              return (
                <div className="criteriaBorder">
                  <Row className=" no-margin no-padding">
                    <Col
                      xs={1}
                      md={1}
                      sm={1}
                      lg={1}
                      className="left no-padding"
                    >
                      <span className="rewardIcon">
                        <MaterialIcon
                          icon="check_box_outline_blank"
                          size={25}
                          color="#9a0039"
                        />
                      </span>
                    </Col>
                    <Col
                      xs={11}
                      md={11}
                      sm={11}
                      lg={11}
                      className="left no-padding"
                    >
                      <span className="criteriaText">
                        {criteria.text}
                      </span>
                      <div id="progressBarBack">
                        <span className="right red">
                          <span className="progressGoal">
                            {criteria.numTimes}
                          </span>
                        </span>
                        <div
                          className="progressBar"
                          style={{ width: width }}
                        >
                          <span className="progressTotal">
                            {currentNum}
                          </span>
                        </div>
                      </div>
                      {criteria.locations.map((location, index) => (
                        <span className="locationTag">
                          {location.label}
                        </span>
                      ))}
                      <span>
                        <b>Not Available at This Location</b>
                      </span>
                    </Col>
                  </Row>
                </div>
              );
            }
          }
        }
      } else {
        // return(
        //   <div className="criteriaBorder">
        //     <span className="rewardIcon"><MaterialIcon icon="check_box_outline_blank" size={25} color="#9a0039" /></span>
        //     <span className="criteriaHeaderComplete">Criteria {indexPlusOne}</span><br></br>
        //     <span className="criteriaText">{criteria.text}</span>
        // </div>
        // );
      }
    } else {
      let currentNum = 0;
      let width = '5%';
      let match = false;
      criteria.locations.forEach((location) => {
        if (location.value === locationId) {
          match = true;
        }
      });
      if (match === true) {
        return (
          <div
            className="criteriaBorder clickable"
            onClick={() => {
              this.getValidatedNew(
                index,
                reward,
                promo,
                this.props.profile,
              );
            }}
          >
            <Row className=" no-margin no-padding">
              <Col
                xs={1}
                md={1}
                sm={1}
                lg={1}
                className="left no-padding"
              >
                <span className="rewardIcon">
                  <MaterialIcon
                    icon="check_box_outline_blank"
                    size={25}
                    color="#9a0039"
                  />
                </span>
              </Col>
              <Col
                xs={11}
                md={11}
                sm={11}
                lg={11}
                className="left no-padding"
              >
                <span className="criteriaText">{criteria.text}</span>
                <div id="progressBarBack">
                  <span className="right red">
                    <span className="progressGoal">
                      {criteria.numTimes}
                    </span>
                  </span>
                  <div
                    className="progressBar"
                    style={{ width: width }}
                  >
                    <span className="progressTotal">
                      {currentNum}
                    </span>
                  </div>
                </div>
                {criteria.locations.map((location, index) => (
                  <span className="locationTag">
                    {location.label}
                  </span>
                ))}
              </Col>
            </Row>
          </div>
        );
      } else {
        return (
          <div className="criteriaBorder">
            <Row className=" no-margin no-padding">
              <Col
                xs={1}
                md={1}
                sm={1}
                lg={1}
                className="left no-padding"
              >
                <span className="rewardIcon">
                  <MaterialIcon
                    icon="check_box_outline_blank"
                    size={25}
                    color="#9a0039"
                  />
                </span>
              </Col>
              <Col
                xs={11}
                md={11}
                sm={11}
                lg={11}
                className="left no-padding"
              >
                <span className="criteriaText">{criteria.text}</span>
                <div id="progressBarBack">
                  <span className="right red">
                    <span className="progressGoal">
                      {criteria.numTimes}
                    </span>
                  </span>
                  <div
                    className="progressBar"
                    style={{ width: width }}
                  >
                    <span className="progressTotal">
                      {currentNum}
                    </span>
                  </div>
                </div>
                {criteria.locations.map((location, index) => (
                  <span className="locationTag">
                    {location.label}
                  </span>
                ))}
                <span>
                  <b>Not Available at This Location</b>
                </span>
              </Col>
            </Row>
          </div>
        );
      }
    }
  }

  // getGoldenTicket(reward, index) {
  //   let match = false;
  //   this.state.goldenTicketLevel.forEach((level, i) => {
  //     if (level.value === index) {
  //       match = true;
  //     }
  //   });
  //   if (match === true) {
  //     return (
  //       <span>
  //         <span style={{ color: 'black' }}>or </span>
  //         <span className="goldenTitle">
  //           {this.state.levelSix.label}
  //         </span>
  //       </span>
  //     );
  //   }
  // }

  getReward(reward, index, promo, locationId) {
    let userReward = [];
    let rewardArray = [];

    this.props.awards.forEach((award, x) => {
      if (award.promo.id === promo.id) {
        rewardArray.push(award);
      }
    });
    rewardArray.forEach((reward, x) => {
      if (reward.rewards[0].id === promo.rewards[0].id) {
        userReward = reward;
      }
    });

    // const userReward = query('reward.id')
    //   .is(reward.id)
    //   .on(this.props.awards);

    let title = '';
    if (reward.reward.length === 1) {
      title = reward.reward[0].title;
    } else if (reward.reward.length > 1) {
      let colabMode = 'all';
      if (index + 1 === 1) {
        colabMode = reward.colabSettings.colabOne;
      } else if (index + 1 === 2) {
        colabMode = reward.colabSettings.colabTwo;
      } else if (index + 1 === 3) {
        colabMode = reward.colabSettings.colabThree;
      } else if (index + 1 === 4) {
        colabMode = reward.colabSettings.colabFour;
      } else if (index + 1 === 5) {
        colabMode = reward.colabSettings.colabFive;
      }
      if (colabMode === 'choose') {
        reward.reward.forEach((rewardx, x) => {
          if (x === 0) {
            title = rewardx.title;
          } else {
            title = title + ' or ' + rewardx.title;
          }
        });
      } else if (colabMode === 'all') {
        reward.reward.forEach((rewardx, x) => {
          if (x === 0) {
            title = rewardx.title;
          } else {
            title = title + ' & ' + rewardx.title;
          }
        });
      }
    }

    if (reward.locked === false) {
      if (userReward[0] === undefined) {
        return (
          <div className="promo-criteria">
            <div id={'toggler' + index}>
              <span>
                <span className="rewardIcon">
                  {this.getRewardIcon(index, userReward)}
                </span>
                <span>
                  <b>Level {index + 1}:</b>
                </span>{' '}
                <span className="rewardTitle">
                  {title}{' '}
                  {/* {this.getGoldenTicket(reward, index + 1)} */}
                </span>
              </span>
            </div>
            <UncontrolledCollapse toggler={'#toggler' + index}>
              {reward.criteria.map((criteria, index) => (
                <div>
                  {this.getCriteria(
                    criteria,
                    index,
                    userReward,
                    reward,
                    promo,
                    locationId,
                  )}
                </div>
              ))}
            </UncontrolledCollapse>
          </div>
        );
      }
      // Not Earned
      if (
        userReward[0] !== undefined &&
        userReward[0].earned === false
      ) {
        return (
          <div className="promo-criteria">
            <span>
              <span className="rewardIcon">
                {this.getRewardIcon(index, userReward)}
              </span>
              <span>
                <b>Level {index + 1}:</b>
              </span>{' '}
              <span className="rewardTitle">{reward.title}</span>
            </span>
            {reward.criteria.map((criteria, index) => (
              <div key={index + 1}>
                {this.getCriteria(
                  criteria,
                  index,
                  userReward,
                  reward,
                  promo,
                  locationId,
                )}
              </div>
            ))}
          </div>
        );
      } else {
        // Earned but Not Redeemed
        if (
          userReward[0] !== undefined &&
          userReward[0].earned === true &&
          userReward[0].redemption.redeemed === false
        ) {
          return (
            <div className="promo-criteria">
              <span>
                <span className="rewardIcon">
                  <MaterialIcon
                    icon="favorite"
                    size={25}
                    color="red"
                    // onClick={() => {
                    //   this.resetPackage();
                    // }}
                  />
                </span>
                <span>
                  <b>Level {index + 1}:</b>
                </span>{' '}
                <span className="rewardTitle">{reward.title}</span>
              </span>
              <a
                onClick={() => {
                  this.requestRedemption(userReward[0]);
                }}
                className="redbuttonRight text-center"
              >
                <span>Redeem!</span>
              </a>
            </div>
          );
        } else if (
          userReward[0] !== undefined &&
          userReward[0].earned === true &&
          userReward[0].redemption.redeemed === true
        ) {
          // Earned AND Redeemed
          let myDate = userReward[0].redemption.date.seconds * 1000; // Unix ms timestamp
          let dayText = moment(myDate, 'x')
            .subtract(10, 'days')
            .calendar();
          return (
            <div className="promo-criteria">
              <span>
                <span className="rewardIcon">
                  {this.getRewardIcon(index, userReward)}
                </span>
                <span>
                  <b>Level {index + 1}:</b>
                </span>{' '}
                <span className="rewardTitle">{reward.title}</span>
              </span>
              <span className="clear">Redeemed: {dayText}</span>
            </div>
          );
        }
      }
    } else {
      const earnedReward = query('reward.id')
        .is(reward.lockDependency)
        .on(this.props.awards);
      // Locked
      if (
        earnedReward[0] !== undefined &&
        earnedReward[0].earned === false
      ) {
        return (
          <div className="promo-criteria">
            <span>
              <span className="rewardIcon">
                <MaterialIcon icon="lock" size={25} color="red" />
              </span>
              <span>
                <b>Level {index + 1}:</b>
              </span>{' '}
              <span className="rewardTitle">{reward.title}</span>
            </span>
          </div>
        );
      } else if (earnedReward[0] === undefined) {
        return (
          <div className="promo-criteria">
            <span>
              <span className="rewardIcon">
                <MaterialIcon icon="lock" size={25} color="red" />
              </span>
              <span>
                <b>Level {index + 1}:</b>
              </span>{' '}
              <span className="rewardTitle">{reward.title}</span>
            </span>
          </div>
        );
      } else {
        //  Unlocked - EARNED but NOT Redeemed
        if (
          earnedReward[0] !== undefined &&
          earnedReward[0].earned === true &&
          userReward[0] !== undefined &&
          userReward[0].earned === true &&
          userReward[0].redemption.redeemed === false
        ) {
          return (
            <div className="promo-criteria">
              <span>
                <span className="rewardIcon">
                  {this.getRewardIcon(index, userReward)}
                </span>
                <span>
                  <b>Level {index + 1}:</b>
                </span>{' '}
                <span className="rewardTitle">{reward.title}</span>
              </span>
              <a
                onClick={() => {
                  this.requestRedemption(userReward[0]);
                }}
                className="redbutton text-center"
              >
                <span>Redeem!</span>
              </a>
            </div>
          );
        } else if (
          userReward[0] !== undefined &&
          userReward[0].earned === true &&
          userReward[0].redemption.redeemed === true
        ) {
          //  Unlocked - EARNED AND Redeemed

          let myDate = userReward[0].redemption.date.seconds * 1000; // Unix ms timestamp
          let dayText = moment(myDate, 'x')
            .subtract(10, 'days')
            .calendar();
          return (
            <div className="promo-criteria">
              <span>
                <span className="rewardIcon">
                  {this.getRewardIcon(index, userReward)}
                </span>
                <span>
                  <b>Level {index + 1}:</b>
                </span>{' '}
                <span className="rewardTitle">{reward.title}</span>
              </span>
              <span className="clear">Redeemed: {dayText}</span>
            </div>
          );
        } else if (
          earnedReward[0] !== undefined &&
          earnedReward[0].earned === true &&
          userReward[0] === undefined
        ) {
          //  Unlocked - Not Earned - New
          return (
            <div className="promo-criteria">
              <span>
                <span className="rewardIcon">
                  <MaterialIcon
                    icon="lock_open"
                    size={25}
                    color="red"
                  />
                </span>
                <span>
                  <b>Level {index + 1}:</b>
                </span>{' '}
                <span className="rewardTitle">{reward.title}</span>
                {reward.criteria.map((criteria, index) => (
                  <div>
                    {this.getCriteria(
                      criteria,
                      index,
                      userReward,
                      reward,
                      promo,
                      locationId,
                    )}
                  </div>
                ))}
              </span>
            </div>
          );
        } else {
          //  Unlocked - Not New
          return (
            <div className="promo-criteria">
              <span>
                <span className="rewardIcon">
                  {this.getRewardIcon(index, userReward)}
                </span>
                <span>
                  <b>Level {index + 1}:</b>
                </span>{' '}
                <span className="rewardTitle">{reward.title}</span>
              </span>
              {reward.criteria.map((criteria, index) => (
                <div>
                  {this.getCriteria(
                    criteria,
                    index,
                    userReward,
                    reward,
                    promo,
                    locationId,
                  )}
                </div>
              ))}
            </div>
          );
        }
      }
    }
  }

  getPromoHeroImage(promo) {
    if (promo.images.heroPath !== '') {
      return (
        <div className="promoImage">
          <img src={promo.images.heroPath} />
        </div>
      );
    }
  }

  getPromoLinks(locationId, promos) {
    let promoArray = [];

    if (promos !== null && promos !== undefined) {
      promos.forEach((promo) => {
        let match = false;
        promo.locations.forEach((location) => {
          if (location.locationId === locationId) {
            match = true;
          }
        });
        if (match === true) {
          promoArray.push(promo);
        }
      });
    }

    if (promoArray.length !== 0) {
      return (
        <div className="card-section">
          <span
            className="title"
            style={{
              margin: '0px 0px 20px 0px',
              fontSize: '18px',
              color: '#464646',
            }}
          >
            Experiences
          </span>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div>
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding"
                >
                  {/* <span className="location-title">Promotions</span> */}
                  {promoArray.map((promo, index) => (
                    <Row
                      className="location-info"
                      key={'key' + index}
                      onClick={() => {
                        this.setActivePromo(true, promo);
                      }}
                    >
                      <Col
                        xs={4}
                        md={4}
                        sm={4}
                        lg={4}
                        className="no-padding thumbnailCol"
                      >
                        {this.getPromoImage(promo)}
                      </Col>
                      <Col xs={8} md={8} sm={8} lg={8}>
                        <span className="title">{promo.title}</span>
                        <p>{promo.description}</p>
                        {promo.rewards.length !== 0 && (
                          <div style={{ margin: '10px 0px 0px 0px' }}>
                            <span
                              style={{
                                float: 'left',
                                margin: '0px 5px 0px 0px',
                                fontWeight: '600',
                              }}
                            >
                              Rewards:{' '}
                            </span>
                            {promo.rewards.map((reward, index) => (
                              <span style={{ float: 'left' }}>
                                {reward.title}
                              </span>
                            ))}
                          </div>
                        )}
                      </Col>
                    </Row>
                  ))}
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }

  getPromoTitleBar(title) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <a
                onClick={() => {
                  this.setActivePromo(false, null);
                }}
              >
                <MaterialIcon
                  icon="keyboard_backspace"
                  size={25}
                  color="white"
                />
              </a>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">{title}</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2}>
              {/* <center>
                <MaterialIcon
                  icon="check"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.createCustomEvent(
                      this.props.profile,
                      this.props.customEvent,
                      this.props.locations,
                      this.props.packages
                    );
                  }}
                />
              </center> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  setActivePromo(mode, promo) {
    this.setState({
      showPromo: mode,
      activePromo: promo,
    });
  }

  getPromoImage(promo) {
    if (promo.images.heroPath !== '') {
      return (
        <a
          onClick={() => {
            this.setActivePromo(true, promo);
          }}
        >
          <img
            src={promo.images.thumbPath}
            className="thumbnail"
            style={{ margin: '5px 0px 0px 0px' }}
          />
        </a>
      );
    }
  }

  getPromos(locationId, promos) {
    // if (promos !== undefined) {
    //   let promoArray = [];
    //   promos.forEach((promo) => {
    //     if (promo.locations !== undefined) {
    //       promo.locations.forEach((location) => {
    //         if (location.locationId === locationId) {
    //           promoArray.push(promo);
    //         }
    //       });

    //       // let status = promo.locations.includes(locationId);
    //       // if (status === true) {

    //       // }
    //     }
    //   });
    let promo = this.state.activePromo;
    // if (promoArray.length > 0) {
    return (
      <div className="promo-container">
        {/* {promoArray.map((promo, index) => ( */}
        <div className="promo" key={promo.title}>
          {this.getPromoHeroImage(promo)}
          <div className="promoBody">
            <span className="promo-reward">{promo.title}</span>
            <span className="promo-criteria">
              {promo.description}
            </span>
            {promo.rewards.map((reward, index) => (
              <div className="reward" key={index + 1}>
                {this.getReward(reward, index, promo, locationId)}
              </div>
            ))}
          </div>
          <hr />
          {/* <span className="promo-reward">{promo.criteria.text}</span> */}
        </div>
        {/* ))} */}
      </div>
    );
    // } else {
    //   return (
    //     <div>
    //       <div className="text-center">
    //         <span>No Offers Available</span>
    //       </div>
    //     </div>
    //   );
    // }
  }

  getFooter(step, locations) {
    const location = locations[step];
    if (this.state.inBetween === true) {
      return (
        <Container>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div>
                <a
                  onClick={() => {
                    this.closeInBetween();
                  }}
                  className="center solidredbutton"
                >
                  Start!
                </a>
              </div>
            </Col>
          </Row>
          <style
            dangerouslySetInnerHTML={{
              __html: '.footer{border: 0px solid grey}',
            }}
          />
        </Container>
      );
    }
    return (
      <Container>
        <Row className="no-margin">
          <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
            {this.getBackButton()}
          </Col>
          <Col
            xs={8}
            md={8}
            sm={8}
            lg={8}
            className="no-padding text-center"
          >
            <span className="active-location-title">
              {location.locationTitle}
            </span>
          </Col>
          <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
            {this.getNextButton()}
          </Col>
        </Row>
      </Container>
    );
  }

  getActionBar(step, locations) {
    // getActionBar(step, packageDetails, locations) {
    // const location = query("locationId")
    //   .is(packageDetails.locations[step].locationId)
    //   .on(locations);

    // const mapsUrl =
    //   "http://maps.apple.com/?daddr=" +
    //   location[0].address.longitude +
    //   "," +
    //   location[0].address.latitude +
    //   "&z=10&t=s&dirflg=d";
    const mapsUrl =
      'http://maps.apple.com/?daddr=' +
      locations[step].address.longitude +
      ',' +
      locations[step].address.latitude +
      '&z=10&t=s&dirflg=d';
    return (
      <div className="action-bar">
        <Container>
          <Row className="no-margin">
            <Col
              xs={3}
              md={3}
              sm={3}
              lg={3}
              className="no-padding text-center"
            >
              <div className="action-item">
                <a
                  className="action-icon"
                  onClick={() => {
                    this.openTransportModal();
                  }}
                >
                  <MaterialIcon
                    icon="directions_car"
                    size={25}
                    color="white"
                  />
                  <span className="action-item-text">
                    Call a Ride
                  </span>
                </a>
              </div>
            </Col>
            <Col
              xs={3}
              md={3}
              sm={3}
              lg={3}
              className="no-padding text-center"
            >
              <div className="action-item">
                <a className="action-icon" href={mapsUrl}>
                  <MaterialIcon
                    icon="directions"
                    size={25}
                    color="white"
                  />
                  <span className="action-item-text">Directions</span>
                </a>
              </div>
            </Col>
            <Col
              xs={3}
              md={3}
              sm={3}
              lg={3}
              className="no-padding text-center"
            >
              <div className="action-item">
                <a
                  className="action-icon"
                  onClick={() => {
                    this.openConversationModal();
                  }}
                >
                  <MaterialIcon icon="sms" size={25} color="white" />
                  <span className="action-item-text">
                    Conversation
                  </span>
                </a>
              </div>
            </Col>
            <Col
              xs={3}
              md={3}
              sm={3}
              lg={3}
              className="no-padding text-center"
            >
              <div className="action-item">
                <a className="action-icon">
                  <MaterialIcon
                    icon="photo_camera"
                    size={25}
                    color="white"
                  />
                  <span className="action-item-text">
                    Share Photo
                  </span>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  openTransportModal() {
    this.setState({ transportModalVisible: true });
  }

  closeTransportModal() {
    this.setState({ transportModalVisible: false });
  }

  openFinishModal() {
    this.setState({ finishModalVisible: true });
  }

  closeFinishModal() {
    this.setState({ finishModalVisible: false });
  }

  openConversationModal() {
    this.setState({ conversationModalVisible: true });
  }

  closeConversationModal() {
    this.setState({ conversationModalVisible: false });
  }

  openPromoModal() {
    this.setState({ promoModalVisible: true });
  }

  closePromoModal() {
    this.setState({ promoModalVisible: false });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  showLoadScreen() {
    return (
      <div id="loadscreen">
        <div className="text-center push">
          <div className="middleMe">
            {/* <img src={whiteLogo} /> */}
            <span>Starting Your Event!</span>
          </div>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important; background: #f21d59} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }

  getValidateScreen(awards) {
    let validatorMode = false;
    let activeUserAward = {};
    let activeCriteriaIndex = 0;
    awards.forEach((award) => {
      if (
        award.validatorMode !== undefined &&
        award.validatorMode === true
      ) {
        validatorMode = true;
        activeUserAward = award;
        award.criteria.forEach((criteria, index) => {
          if (criteria.activeCriteria === true) {
            activeCriteriaIndex = index;
          }
        });
      }
    });

    if (validatorMode === true) {
      if (activeUserAward.validationType === 1) {
        return (
          <div id="loadscreen">
            <div className="text-center push">
              <div className="middleMe">
                {/* <img src={whiteLogo} /> */}
                <span>Waiting on Validation!</span>
              </div>
              <br />
              <a
                style={{
                  display: 'inline-block',
                  backgroundColor: 'white',
                  padding: '5px 10px 5px 10px',
                  color: '#f21e58',
                  borderRadius: '3px',
                  marginTop: '20px',
                }}
                onClick={() => {
                  this.cancelValidation(
                    activeCriteriaIndex,
                    activeUserAward,
                  );
                }}
              >
                <span>Cancel</span>
              </a>
            </div>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '#activeContent { display: none } .authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important; background: #f21d59} #bottomNav{display:none} #loadscreen p{color:white}',
              }}
            />
          </div>
        );
      } else {
        return (
          <div id="loadscreen">
            <div className="text-center push">
              <div className="middleMe">
                <img src={whiteLogo} />
                <span>Redeeming...</span>
              </div>
              <br />
              <button
                onClick={() => {
                  this.cancelRedemption(activeUserAward);
                }}
              >
                <span>Cancel</span>
              </button>
            </div>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '#activeContent { display: none } .authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important; background: #f21d59} #bottomNav{display:none} #loadscreen p{color:white}',
              }}
            />
          </div>
        );
      }
    }
  }

  codeOnChangeOne(e) {
    if (e.target.value !== '') {
      document.getElementById('two').focus();
    }
    this.setState({ codeOne: e.target.value });
  }

  codeOnChangeTwo(e) {
    if (e.target.value !== '') {
      document.getElementById('three').focus();
    }
    this.setState({ codeTwo: e.target.value });
  }

  codeOnChangeThree(e) {
    if (e.target.value !== '') {
      document.getElementById('four').focus();
    }
    this.setState({ codeThree: e.target.value });
  }

  codeOnChangeFour(e) {
    if (e.target.value !== '') {
      document.getElementById('five').focus();
    }
    this.setState({ codeFour: e.target.value });
  }

  codeOnChangeFive(e) {
    if (e.target.value !== '') {
      document.getElementById('six').focus();
    }
    this.setState({ codeFive: e.target.value });
  }

  codeOnChangeSix(e) {
    this.setState({ codeSix: e.target.value });
  }

  // Create Award
  createAward(user) {
    let promo = this.state.activePromo;
    let awardObject = {};
    const timestamp = new Date();
    awardObject.active = true;
    awardObject.description = promo.description;
    awardObject.owner = {
      userId: this.props.profile.userId,
      firstName: this.props.profile.firstName,
      lastName: this.props.profile.lastName,
    };
    awardObject.creationDetails = {
      createdDate: timestamp,
      validatorId: user,
    };
    awardObject.promo = {
      id: promo.id,
      locationId: promo.locationId,
      locationTitle: promo.locationTitle,
      vendorId: promo.account.vendorId,
    };
    awardObject.reward = {
      text: promo.reward.text,
      details: promo.reward.details,
    };
    awardObject.criteria = {
      text: promo.criteria.text,
      guidelines: promo.criteria.guidelines,
    };
    awardObject.redemption = {
      date: null,
      redeemerId: null,
      redeemerFullName: null,
    };
    awardObject.restrictions = {
      text: '',
    };
    this.savePromo(awardObject);
  }

  savePromo(award) {
    const db = firebase.firestore();
    const setDoc = db
      .collection('user_awards')
      .add(award)
      .then((ref) => {
        // console.log("Added document with ID: ", ref);
        this.props.getUserAwards(this.props.profile.userId);
      });

    this.setState({
      codeOne: '',
      codeTwo: '',
      codeThree: '',
      codeFour: '',
      codeFive: '',
      codeSix: '',
      validate: false,
      vendorUsers: [],
    });
    this.closePromoModal();
  }

  // Redeed Award (Pre-COVID)
  redeemAward(user) {
    let award = this.state.activeAward;
    const timestamp = new Date();
    award.active = false;
    award.redemption.date = timestamp;
    award.redemption.redeemerId = user.userId;
    award.redemption.redeemerFullName = user.fullName;
    const db = firebase.firestore();
    const update = db.collection('user_awards').doc(award.awardId);
    const setDoc = update
      .update({
        active: false,
        redemption: award.redemption,
      })
      .then((ref) => {
        this.props.getUserAwards(this.props.profile.userId);
        this.setState({
          showConfirm: false,
          validate: false,
          redeem: false,
        });
      });
  }

  getValidateAction(user, actionType) {
    if (actionType === 1) {
      return (
        <a
          onClick={() => {
            this.createAward(user);
          }}
        >
          <span>{user.fullName}</span>
        </a>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.redeemAward(user);
          }}
        >
          <span>{user.fullName}</span>
        </a>
      );
    }
  }

  showValidationScreen() {
    let actionType = 1;
    if (this.state.redeem === true) {
      actionType = 2;
    }
    return (
      <div id="loadscreen">
        <div className="section text-center pin-area">
          <Container>
            <Row className="no-margin">
              <Col
                xs={1}
                md={1}
                sm={1}
                lg={1}
                className="no-padding"
              />
              <Col
                xs={10}
                md={10}
                sm={10}
                lg={10}
                className="no-padding"
              >
                <h2 className="white">Offer Validation</h2>
              </Col>
              <Col xs={1} md={1} sm={1} lg={1} className="no-padding">
                <a
                  onClick={() => {
                    this.closeValidate();
                  }}
                >
                  <MaterialIcon
                    icon="close"
                    size={25}
                    color="white"
                  />
                </a>
              </Col>
            </Row>
          </Container>
          <input
            type="password"
            onChange={this.codeOnChangeOne.bind(this)}
            value={this.state.codeOne}
            className="pin-field  text-center"
            id="one"
            maxLength="1"
          />
          <input
            type="password"
            onChange={this.codeOnChangeTwo.bind(this)}
            value={this.state.codeTwo}
            className="pin-field text-center"
            id="two"
            maxLengthh="1"
          />
          <input
            type="password"
            onChange={this.codeOnChangeThree.bind(this)}
            value={this.state.codeThree}
            className="pin-field  text-center"
            id="three"
            maxLength="1"
          />
          <input
            type="password"
            onChange={this.codeOnChangeFour.bind(this)}
            value={this.state.codeFour}
            className="pin-field  text-center"
            id="four"
            maxLength="1"
          />
          <input
            type="password"
            onChange={this.codeOnChangeFive.bind(this)}
            value={this.state.codeFive}
            className="pin-field  text-center"
            id="five"
            maxLength="1"
          />
          <input
            type="password"
            onChange={this.codeOnChangeSix.bind(this)}
            value={this.state.codeSix}
            className="pin-field text-center"
            id="six"
            maxLength="1"
          />
          <a
            onClick={() => {
              this.getVendorUsers(
                this.state.codeOne,
                this.state.codeTwo,
                this.state.codeThree,
                this.state.codeFour,
                this.state.codeFive,
                this.state.codeSix,
              );
            }}
            className="pin-code-btn"
          >
            <MaterialIcon
              icon="check_circle"
              size={40}
              color="white"
            />
          </a>
        </div>
        <div className="section">
          <Container>
            {this.getUserList(this.state.vendorUsers, actionType)}
          </Container>
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              '.authtoolbar { display: none } #headers { top: 0px !important;} .maincontent{top: 0px !important;} #bottomNav{display:none} #loadscreen p{color:white}',
          }}
        />
      </div>
    );
  }

  getUserList(vendorUsers, actionType) {
    if (vendorUsers !== null && vendorUsers !== undefined) {
      return (
        <div>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <h5 className="text-center bottompushtwenty">
                Who is validating this user?
              </h5>
            </Col>
          </Row>

          {vendorUsers.map((user) => (
            <Row className="no-margin oddeven">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                {this.getValidateAction(user, actionType)}
              </Col>
            </Row>
          ))}
        </div>
      );
    }
  }

  closeValidate() {
    this.setState({
      codeOne: '',
      codeTwo: '',
      codeThree: '',
      codeFour: '',
      codeFive: '',
      codeSix: '',
      validate: false,
    });
  }

  closeAward() {
    this.setState({
      redeem: false,
    });
  }

  getVendorUsers = (
    codeOne,
    codeTwo,
    codeThree,
    codeFour,
    codeFive,
    codeSix,
  ) => {
    let code =
      '' +
      codeOne +
      codeTwo +
      codeThree +
      codeFour +
      codeFive +
      codeSix;
    let codeNum = Number(code);
    let usersx = [];
    const db = firebase.firestore();
    const vendorCollection = db.collection('vendors');
    const vendorDocument = vendorCollection
      .where(
        'vendorId',
        '==',
        this.state.activePromo.account.vendorId,
      )
      .where('code', '==', codeNum);
    vendorDocument
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          const { users } = doc.data();
          usersx.push(users);
        });
        this.setState({
          vendorUsers: usersx[0],
        });
        if (usersx[0] === undefined) {
          this.setState({
            codeOne: '',
            codeTwo: '',
            codeThree: '',
            codeFour: '',
            codeFive: '',
            codeSix: '',
          });
        }
      })
      .catch(function (error) {
        // console.log("Error getting documents: ", error);
      });
  };

  showAward(award) {
    let promoId = award.promo.id;
    const promo = query('id').is(promoId).on(this.props.promos);
    this.setState({
      redeem: true,
      activeAward: award,
      activePromo: promo[0],
    });
  }

  setCriteriaDetailView(
    mode,
    criteria,
    index,
    activeReward,
    activeStatus,
  ) {
    this.setState({
      criteriaDetailView: mode,
      activeCriteriaPreview: criteria,
      activeReward,
      index,
      activeStatus,
    });
  }

  listVoucher(award, number) {
    if (award.active === true && award.earned === true) {
      return (
        <Container>
          <Row>
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div
                onClick={() => {
                  this.showAward(award);
                }}
                className="awardback"
              >
                <span className="award-title">
                  {award.reward.title}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      );
    }
  }

  getTitleBar(event) {
    let backLink = '';
    if (event.status === 0) {
      backLink = '/events/upcoming';
    } else {
      backLink = '/events/upcoming';
    }
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/event/' + event.eventId,
                }}
                className="left"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">{event.title}</span>
              </center>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2}>
              {/* <center>
                <MaterialIcon
                  icon="check"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.createCustomEvent(
                      this.props.profile,
                      this.props.customEvent,
                      this.props.locations,
                      this.props.packages
                    );
                  }}
                />
              </center> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  showActivePromoScreen(location) {
    return (
      <div>
        {this.getPromos(location.locationId, this.props.promos)}
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return <div>{this.showLoadScreen()}</div>;
    }

    if (this.state.validate === true) {
      return <div>{this.showValidationScreen()}</div>;
    }
    if (this.state.redeem === true) {
      return (
        <div>{this.showRedeemScreen(this.state.activeAward)}</div>
      );
    }
    if (
      this.state.criteriaDetailView === true &&
      this.state.validate !== true
    ) {
      return (
        <div style={{ height: '100%' }}>
          <div id="validator">
            {this.getValidateScreen(this.props.awards)}
          </div>

          <div id="package" style={{ height: '100%' }}>
            <div
              className="criteriaDetails"
              style={{ height: '100%' }}
            >
              <div className="right">
                <MaterialIcon
                  icon="close"
                  size={25}
                  color="white"
                  onClick={() => {
                    this.setCriteriaDetailView(false);
                  }}
                />
              </div>
              <div
                style={{
                  clear: 'both',
                  display: 'block',
                  padding: '10px',
                }}
              >
                <span style={{ color: 'white', fontSize: '18px' }}>
                  {this.state.activeCriteriaPreview.text}
                </span>
                <hr />
                <div style={{ minHeight: '350px' }}>
                  {this.state.activeCriteriaPreview.guidelines !==
                    '' && (
                    <span style={{ color: 'white' }}>
                      {this.state.activeCriteriaPreview.guidelines}
                    </span>
                  )}
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>
                {this.state.activeStatus === true ? (
                  <span
                    style={{
                      background: 'white',
                      padding: '10px',
                      borderRadius: '5px',
                      color: '#f21d59',
                    }}
                    onClick={() => {
                      this.state.activeStatus === true &&
                        this.getValidated(
                          this.state.index,
                          this.state.activeReward,
                        );
                    }}
                  >
                    Get Validated
                  </span>
                ) : (
                  <div>
                    <span
                      style={{
                        color: 'white',
                        clear: 'both',
                        display: 'block',
                        padding: '0px 0px 20px 0px',
                      }}
                    >
                      Available Once Per Day
                    </span>
                    <span
                      style={{
                        background: 'white',
                        padding: '10px',
                        borderRadius: '5px',
                        color: '#f21d59',
                      }}
                      onClick={() => {
                        this.setCriteriaDetailView(false);
                      }}
                    >
                      Close
                    </span>
                  </div>
                )}
              </div>
            </div>
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '#mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 0px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
              }}
            />
          </div>
        </div>
      );
    }
    if (this.state.showPromo === true) {
      return (
        <div style={{ height: '100%' }}>
          <div id="validator">
            {this.getValidateScreen(this.props.awards)}
          </div>
          <div id="activeContent" style={{ height: '100%' }}>
            {/* <TitleBar title={this.props.activeEvent.title} backlink="" /> */}
            {this.getPromoTitleBar(this.state.activePromo.title)}
            <div>
              {this.getStep(
                this.state.step,
                this.props.packageDetails,
                this.props.activeEvent.packageDetails.locations,
              )}
            </div>
            {/* {this.getActionBar(
          this.state.step,
          this.state.packageDetails,
          this.props.activeEvent.packageDetails.locations
        )} */}
            {/* {this.getActionBar(
            this.state.step,
            this.props.activeEvent.packageDetails.locations,
          )} */}
            {/* <div className="footer">
            {this.getFooter(
              this.state.step,
              this.props.activeEvent.packageDetails.locations,
            )}
          </div> */}
            <style
              dangerouslySetInnerHTML={{
                __html:
                  '#mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 75px; margin-top: 0px; height: 100%}  .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
              }}
            />
          </div>
        </div>
      );
    }
    return (
      <div style={{ height: '100%' }}>
        <div id="validator">
          {this.getValidateScreen(this.props.awards)}
        </div>
        <div id="activeContent" style={{ height: '100%' }}>
          {/* <TitleBar title={this.props.activeEvent.title} backlink="" /> */}
          {this.getTitleBar(this.props.activeEvent)}

          {this.getStep(
            this.state.step,
            this.props.packageDetails,
            this.props.activeEvent.packageDetails.locations,
          )}

          {/* {this.getActionBar(
          this.state.step,
          this.state.packageDetails,
          this.props.activeEvent.packageDetails.locations
        )} */}
          {/* {this.getActionBar(
            this.state.step,
            this.props.activeEvent.packageDetails.locations,
          )} */}
          <div className="footer">
            {this.getFooter(
              this.state.step,
              this.props.activeEvent.packageDetails.locations,
            )}
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '#mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 75px; margin-top: 0px; height: 100%}  .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    finishEvent: (zone) => {
      dispatch(finishEvent(zone));
    },
    getUserAwards: (id) => {
      dispatch(getUserAwards(id));
    },
  };
};

function mapStateToProps(state) {
  return {
    activeEvent: state.activeEvent.activeEvent,
    packages: state.packages.packages,
    locations: state.locations.locations,
    vouchers: state.vouchers.vouchers,
    profile: state.profile.profile,
    promos: state.promos.promos,
    awards: state.userAwards.awards,
    demo: state.demo,
    timedEvents: state.timedEvents.events,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActiveEvent);

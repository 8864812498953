import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Container, Row } from 'reactstrap';
import UserAvatar from 'react-user-avatar';
import MaterialIcon from 'material-icons-react';
import { Link, NavLink } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import VendorMenu from '../../components/VendorMenu';
import VendorEntertainmentListItemEdit from '../../components/VendorEntertainmentListItemEdit';
import Select from 'react-select';
import firebase from '../../config/firebase';
// Reducers
import { getMode } from '../../sagas/setModeSaga/reducer';
import { clearUauth } from '../../sagas/unauthenticatedSaga/reducer';

const KEYS_TO_FILTERS = ['entertainmentTitle', 'tagsGenre'];

const query = require('array-query');

var options = {
  enableHighAccuracy: true,
  timeout: 10,
  maximumAge: 0,
};
function success(pos) {
  // console.log('suceess!');
  var crd = pos.coords;

  // console.log('Your current position is:');
  // console.log(`Latitude : ${crd.latitude}`);
  // console.log(`Longitude: ${crd.longitude}`);
  // console.log(`More or less ${crd.accuracy} meters.`);
}

function errors(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}

export class VendorAdminEntertainment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      showHelp: false,
      searchTerm: '',
      loading: false,
      promos: [],
      vendorEntertainment: [],
      stl: true,
      kc: true,
    };
    this.showPicker = this.showPicker.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.user.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    document.title = 'Entertainment';
    this.props.clearUauth();
    if (this.props.mode !== 2) {
      this.props.getMode(2);
    }

    this.getVendorEntertainers(this.props.vendor.vendorId);
  }

  componentDidMount() {}

  getVendorEntertainers(vendorId) {
    let vendorEntertainment = [];
    const db = firebase.firestore();

    if (vendorId === 'KZry0625YQCjZV99ZZh1') {
      db.collection('entertainment')
        .where('account.vendorId', '==', vendorId)
        // .where('active', '==', true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            let temp = doc.data();
            vendorEntertainment.push(doc.data());
          });

          this.setState({
            vendorEntertainment,
            loading: false,
          });
        });
    } else {
      db.collection('entertainment')
        .where('account.vendorId', '==', vendorId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            vendorEntertainment.push(doc.data());
          });

          this.setState({
            vendorEntertainment,
            loading: false,
          });
        });
    }
  }

  showPicker(mode) {
    this.setState({
      showPicker: mode,
    });
  }

  showHelp(mode) {
    this.setState({
      showHelp: mode,
    });
  }

  getDate(timestamp) {
    let myDate = timestamp.seconds * 1000; // Unix ms timestamp
    let dayText = moment(myDate, 'x').subtract(10, 'days').calendar();

    // const date = dayText + " " + monthText + " " + yearText;
    return dayText;
  }

  getLocations() {
    const docArray = [];
    this.props.vendor.locations.forEach((location) => {
      if (this.props.vendor.vendorId !== 'KZry0625YQCjZV99ZZh1') {
        const docDetails = query('locationId')
          .is(location)
          .on(this.props.alllocations);
        if (docDetails[0] !== undefined) {
          docArray.push(docDetails[0]);
        }
      } else {
        const docDetails = query('locationId')
          .is(location)
          .on(this.props.demo.demoLocations);
        if (docDetails[0] !== undefined) {
          docArray.push(docDetails[0]);
        }
      }
    });
    return docArray;
  }

  getTitleBar(title, id) {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="no-padding"
            >
              <span className="left">
                <MaterialIcon
                  icon="apps"
                  size={30}
                  color="#353535"
                  onClick={() => {
                    this.showPicker(true);
                  }}
                />
              </span>
              <span className="vendorAppTitle">{title}</span>

              <span className="helpButton">
                <Link
                  to={{
                    pathname: '/vendor/support',
                  }}
                >
                  <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                  />
                </Link>
                {/* <MaterialIcon
                    icon="help_outline"
                    size={35}
                    color="#353535"
                    onClick={() => {
                      this.showHelp(true);
                    }}
                  /> */}
              </span>
              <span className="right">
                <UserAvatar
                  size="30"
                  name={
                    this.props.profile.firstName +
                    this.props.profile.lastName
                  }
                  src={this.props.profile.profilePhotoPath}
                />
              </span>
              <div className="saveButtons">
                <Link
                  to={{
                    pathname: '/vendor/admin/entertainment/add',
                  }}
                  className="right"
                >
                  <span className="saveButton">
                    New Entertainment
                  </span>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  getSelectOptions() {
    let selectOptions = [];
    this.state.vendorEntertainment.forEach((entertainment) => {
      selectOptions.push({
        value:
          entertainment.entertainmentTitle +
          '/' +
          entertainment.entertainmentId,
        label: entertainment.entertainmentTitle,
      });
    });
    return selectOptions;
  }

  selectChange = (values) => {
    const text = values.value.split('/', 5);
    this.setState({
      entertainmentTitle: text[0],
      entertainmentId: text[1],
      selectInput: { value: text[0], label: text[0] },
    });
  };

  filteredEvents(events) {
    let eventsArray = [];
    events.forEach((event) => {
      if (
        event.entertainmentTitle === this.state.entertainmentTitle
      ) {
        eventsArray.push(event);
      }
    });
    if (eventsArray.length === 0) {
      eventsArray = events;
    }
    return eventsArray;
  }

  clearSearch = () => {
    this.setState({
      entertainmentTitle: '',
      entertainmentId: '',
      selectInput: { value: null, label: '' },
    });
  };

  onCheckBoxChange = (fieldName, value) => {
    console.log('cats', fieldName, value.target.value);
    let status = true;
    if (value.target.value === 'off') {
      status = false;
    }
    this.setState({ [fieldName]: status });
    console.log('cats', fieldName, value.target.value);
  };

  filterEntertainers(filterEntertainers) {
    let filtered = [];
    filterEntertainers.forEach((entertainer) => {
      if (this.state.stl === true && entertainer.zoneId === 11) {
        filtered.push(entertainer);
      }
      if (this.state.kc === true && entertainer.zoneId === 10) {
        filtered.push(entertainer);
      }
    });
    return filtered;
  }

  getAppContent(vendorEntertainment) {
    let filterEntertainers = this.filterEntertainers(
      vendorEntertainment,
    );

    let filteredEvents = this.filteredEvents(filterEntertainers);

    let selectOptions = this.getSelectOptions();
    return (
      <div
        style={{
          height: '100%',
        }}
        id="vendor"
      >
        <div className="appMenuNew">
          <VendorMenu popupMenu={false} />
        </div>
        <div
          style={{
            float: 'left',
            width: 'calc(100% - 120px)',
          }}
        >
          <div style={{ padding: '20px', margin: '0px auto' }}>
            <div style={{ float: 'right', padding: '10px' }}>
              <a
                onClick={() => {
                  this.clearSearch();
                }}
                style={{
                  textDecoration: 'underline',
                }}
              >
                <span>Reset</span>
              </a>
            </div>
            <div style={{ marginBottom: '10px', width: '90%' }}>
              <Select
                value={this.state.selectInput}
                closeMenuOnSelect={true}
                blurInputOnSelect={false}
                onChange={(e) => this.selectChange(e, 0, 'one')}
                options={selectOptions}
                isMulti={false}
                placeholder={'Filter By Entertainer'}
              />
            </div>
            <div
              style={{
                marginBottom: '5px',
                display: 'inline-block',
              }}
            >
              <div style={{ float: 'left', marginRight: '20px' }}>
                <input
                  type="checkbox"
                  checked={this.state.stl}
                  onChange={(value) => {
                    this.onCheckBoxChange('stl', value);
                  }}
                />
                <span>St. Louis</span>
              </div>
              <div style={{ float: 'left' }}>
                <input
                  type="checkbox"
                  checked={this.state.kc}
                  onChange={(value) => {
                    this.onCheckBoxChange('kc', value);
                  }}
                />
                <span>KC</span>
              </div>
            </div>

            <div style={{ clear: 'both' }}>
              <VendorEntertainmentListItemEdit
                entertainment={filteredEvents}
                edit={true}
                editPath="/vendor/admin/entertainment/edit/"
                backPath="/vendor/admin/entertainment"
                type={12}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <div
          style={{
            height: '100%',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'table',
              width: '100%',
              background:
                'linear-gradient(180deg, rgba(242,29,89,1) 0%, rgba(167,25,65,1) 59%)',
            }}
          >
            <div
              style={{
                height: '100%',
                position: 'relative',
                textAlign: 'center',
                display: 'table-cell',
                verticalAlign: 'middle',
                top: '-100px',
              }}
            >
              <div
                style={{ textAlign: 'center', marginTop: '50px' }}
              ></div>
              <span style={{ color: 'white', fontSize: '24px' }}>
                Loading
              </span>
            </div>
          </div>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '.maincontent{margin-top: 0px !important} body{height: 100%} .toolbar{display:none} #analytics{height: 100%} .maincontent{margin-top: 60px; top:0px !important} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
            }}
          />
        </div>
      );
    }

    return (
      <div id="vendor">
        {this.state.showPicker === true && (
          <VendorMenu showPicker={this.showPicker} popupMenu={true} />
        )}
        {this.getTitleBar('Manage Entertainment')}
        <div className="vendorContent">
          {this.getAppContent(this.state.vendorEntertainment)}
        </div>
        {this.state.showHelp === true && (
          <div className="vendorHelpDrawer">
            <span className="right">
              <MaterialIcon
                icon="close"
                size={30}
                color="#353535"
                onClick={() => {
                  this.showHelp(false);
                }}
              />
            </span>
            <span className="vendorAppTitle">Help</span>
          </div>
        )}
        <style
          dangerouslySetInnerHTML={{
            __html:
              'body{height: 100%}  #analytics{height: 100%} .maincontent{margin-top: 60px;} .maincontent-push{height:100%} #bottomNav { display: none } #authleft{display:none;} #authright{padding-left:0px} #headersTop{width:100%}',
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getMode: (mode) => {
      dispatch(getMode(mode));
    },
    clearUauth: () => {
      dispatch(clearUauth());
    },
  };
};

function mapStateToProps(state) {
  return {
    vendor: state.vendor.vendor,
    mode: state.mode.mode,
    profile: state.profile.profile,
    vendorEntertainment: state.vendorEntertainment.entertainment,
    alllocations: state.locations.locations,
    demo: state.demo,
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VendorAdminEntertainment);

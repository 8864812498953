import { Formik } from 'formik';
import MaterialIcon from 'material-icons-react';
import moment from 'moment';
import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SearchInput, { createFilter } from 'react-search-input';
import UserAvatar from 'react-user-avatar';
import { Col, Container, Row } from 'reactstrap';
import NewEventMessage from '../components/NewEventMessage';
import NewEventScheduleModal from '../components/NewEventScheduleModal';
import Select from 'react-select';
// Styles
import '../components/PackageListItem/PackageListItem.css';
import firebase from '../config/firebase';
// Reducers
import {
  getNewEvent,
  resetNewEvent,
} from '../sagas/createEventSaga/reducer';
import { getCe } from '../sagas/customEventSaga/reducer';
import { savePurchase } from '../sagas/purchaseSaga/reducer';
import { getTaRequest } from '../sagas/taRequestSaga/reducer';
import { resetCe } from '../sagas/customEventSaga/reducer';

// Images
import redLogo from '../images/heartbig.png';
import { RichUtils } from 'draft-js';
const _ = require('lodash');

const KEYS_TO_FILTERS = ['firstName', 'lastName', 'userName'];
const query = require('array-query');

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '370px',
    padding: '0px',
    height: '550px',
    width: '300px',
  },
};

export class PlanEventConnect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      loading: true,
      screen: 1,
      step: 1,
      connectFirstModalVisible: false,
      modalState: 1,
      mode: 1,
      invitedUsers: [],
      connections: {},
      connectionsArray: [],
      Current: true,
      selectedRegion:
        this.props.taRequest.startLocation !== 'current' &&
        this.props.taRequest.startLocation !== 'lastLocation'
          ? {
              label: this.props.taRequest.startLocation,
              value: this.props.taRequest.startLocation,
            }
          : '',
      participantsType: this.props.taRequest.startLocation,
      distanceFilterState: 1,
      regionInputOn: false,
      userMoodYes: [],
      userMoodNo: [],
      eventTypes: [],
    };
    this.searchUpdated = this.searchUpdated.bind(this);
    this.setSchedule = this.setSchedule.bind(this);
    this.skipSchedule = this.skipSchedule.bind(this);
    this.saveMessage = this.saveMessage.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.currentUser.authenticated === false) {
      window.location.replace('http://trevel.com/login');
    }
    this.checkStartLocation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let modeInt = 1;
    if (this.props.match.params.screen !== undefined) {
      modeInt = parseInt(this.props.match.params.screen, 10);
    }

    // Set Region Input on State
    if (
      this.props.taRequest.startLocation !== 'current' &&
      this.props.taRequest.startLocation !== 'lastLocation'
    ) {
      this.setState({
        regionInputOn: true,
      });
    }

    if (this.props.taRequest.userMoodNo !== undefined) {
      this.setState({
        userMoodNo: this.props.taRequest.userMoodNo,
      });
    }
    if (this.props.taRequest.userMoodYes !== undefined) {
      this.setState({
        userMoodYes: this.props.taRequest.userMoodYes,
      });
    }

    if (
      this.props.newEvent.fromLocation === true ||
      this.props.newEvent.fromEvent === true ||
      this.props.newEvent.fromEntertainment === true ||
      this.props.newEvent.fromPackage === true
    ) {
      this.setState({
        step: 3,
      });
    }

    let valuex = 1;
    let value = this.props.taRequest.distance;
    if (value === 100) {
      valuex = 2;
    } else if (value === 50) {
      valuex = 0;
    }
    this.setState({
      distanceFilterState: valuex,
    });

    if (
      this.props.newEvent.fromLocation === true ||
      this.props.taRequest.eventCats.eat === true
    ) {
      this.setState({
        Dine: true,
      });
    }

    if (this.props.newEvent.fromEntertainment === true) {
      this.setState({
        Music: true,
        Watch: true,
      });
    }

    if (this.props.taRequest.eventCats.watch === true) {
      this.setState({
        Watch: true,
      });
    }

    if (this.props.taRequest.eventCats.do === true) {
      this.setState({
        Do: true,
      });
    }

    this.createEvent(this.props.user);
    this.storeId();
    // this.setConnectionsArray(this.props.connections);
    this.setState({
      screen: modeInt,
    });
  }

  searchUpdated(term) {
    this.setState({ searchTerm: term });
  }

  setConnectionsArray(connections, eventConnections) {
    let readyConnections = {};
    if (connections.connections !== undefined) {
      readyConnections = connections.connections;
    }
    let connectionsArray = [];
    let connectionsCount = Object.keys(readyConnections).length;

    Object.keys(eventConnections).forEach((key) => {
      if (eventConnections[key].connected === false) {
        let newUserStatus = false;
        if (eventConnections[key].newUser !== undefined) {
          newUserStatus = eventConnections[key].newUser;
        }
        let temp = {
          connected: eventConnections[key].connected,
          created: null,
          userId: key,
          newUser: newUserStatus,
          fullName: eventConnections[key].profile.fullName,
        };
        connectionsArray.push(temp);
      }
    });
    if (connectionsCount > 0) {
      Object.keys(readyConnections).forEach((key) => {
        Object.keys(readyConnections[key]).forEach((keyinner) => {
          if (readyConnections[key][keyinner].connected === true) {
            connectionsArray.push(readyConnections[key][keyinner]);
          }
        });
      });
    }

    return connectionsArray;
    // this.setState({
    //   connectionsArray,
    //   loading: false,
    // });
  }

  createConnection() {
    const invitation = {
      // dateSent: timestamp,
      // senderName: this.props.profile.firstName,
      // senderLastName: this.props.profile.lastName,
      // receiverName: fullname,
      // userName: user.userName,
      // senderId: this.props.profile.userId,
      // locations,
      // userId: user.userId,
      // profilePhotoPath: this.props.profile.profilePhotoPath,
      // receiverPhotoPath: user.profilePhotoPath,
      // type: 1,
      // status: 0,
      // userPurchaseId: this.props.event.packageDetails.packageId,
      // eventId: this.props.event.eventId,
      // message,
      // scheduledTime: this.props.event.scheduledTime.timestamp,
      // editable:
    };
  }

  storeId() {
    // Get New Event ID to use later
    const db = firebase.firestore();
    const newEventRef = db.collection('package').doc();
    const dbref = newEventRef.id;
    this.setState({
      dbref: dbref,
      loading: false,
    });
  }

  createEvent(profile) {
    const userEvent = {};
    const userId = profile.userId;

    const newVouchersObject = {};
    const voucherObject = {};

    const timestamp = new Date();
    userEvent.creationDetails = {
      plannerId: profile.userId,
      plannerPhoto: profile.profilePhotoPath,
      plannerFirstName: profile.firstName,
      plannerLastName: profile.lastName,
      plannerUserName: profile.userName,
      creationDate: timestamp,
    };
    //Package Details
    let eventLocations = [];
    if (
      this.props.newEvent.fromFavorite !== undefined &&
      this.props.newEvent.fromFavorite === true
    ) {
      eventLocations = this.props.newEvent.favoriteLocations;
    } else if (
      this.props.newEvent.fromVEvent !== undefined &&
      this.props.newEvent.fromVEvent === true
    ) {
      eventLocations = this.props.newEvent.vEvent.locations;
    }

    userEvent.packageDetails = {
      packageId: null,
      locations: eventLocations,
    };
    userEvent.title = 'Event With ' + profile.firstName;
    userEvent.description = '';

    // SET USERS
    const fullName = profile.firstName + profile.lastName;
    var usersObject = {};
    usersObject[profile.userId] = {
      access: true,
      editor: true,
      approved: true,
      attending: true,
      profile: {
        profilePhotoPath: profile.profilePhotoPath,
        fullName: fullName,
      },
    };
    userEvent.users = usersObject;

    // CREATE EDIT HISTORY
    const localDate = new Date();
    const editHistory = [];
    editHistory[0] = {
      editDate: localDate,
      eventType: 1,
      fullName: fullName,
      userId: profile.userId,
      username: profile.userName,
    };
    userEvent.editHistory = editHistory;

    // SET PACKAGE SETTINGS
    userEvent.eventSettings = {
      customHero: false,
      customThumb: false,
      scheduled: false,
      itinerarySent: false,
    };

    //Turn Off Edit Mode
    userEvent.editing = {
      editing: false,
      editorId: null,
    };

    // SET FAKE SCHEDULE
    userEvent.scheduledTime = {
      date: '',
      timestamp: '',
      timeString: '',
    };
    // SET EVENT IMAGES
    userEvent.heroImage = 'defaultHero.jpg';
    userEvent.thumbnail = 'defaultThumb.jpg';
    const usersAttendingObject = {};
    usersAttendingObject[userId] = { attending: true };
    userEvent.usersAttending = usersAttendingObject;

    // Set Status
    // if (this.props.newEvent.planFirst === true) {
    //   userEvent.status = 0;
    // } else {
    //   userEvent.status = 2;
    // }

    // *****  Status 4 = waiting? pending?...
    userEvent.status = 4;
    this.setState({
      userEvent,
      loading: false,
      userVouchers: voucherObject,
    });
  }

  setConnection(user, connectFirst, alllocations) {
    const fullName = user.firstName + ' ' + user.lastName;
    let newEvent = {};
    let step = 3;
    let customEvent = {};

    // If from a Vendor Event or a Favorite Location we pre-populate some stuff
    if (this.props.newEvent.fromFavorite === true) {
      step = 4;
      customEvent = {
        clear: true,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        editMode: false,
        event: {
          description: null,
          title: null,
          status: 0,
          locations: this.props.newEvent.favoriteLocations,
          creationDetails: {
            plannerId: null,
            plannerPhoto: null,
            plannerFirstName: null,
            plannerLastName: null,
            plannerUserName: null,
            creationDate: null,
          },
          scheduledTime: {
            date: null,
            timestamp: null,
            timeString: null,
            checkWeek: this.state.checkWeek,
            checkMonth: this.state.checkMonth,
          },
          usersAttending: null,
          eventSettings: {
            itinerarySent: false,
            scheduled: false,
          },
          packageDetails: {
            packageId: null,
          },
        },
      };
    } else if (this.props.newEvent.fromVEvent === true) {
      step = 4;
      customEvent = {
        clear: true,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        editMode: false,
        fromVEvent: true,
        vEvent: this.props.newEvent.vEvent,
        event: {
          description: this.props.newEvent.vEvent.description,
          title: this.props.newEvent.vEvent.title,
          status: 0,
          locations: this.props.newEvent.vEvent.locations,
          creationDetails: {
            plannerId: null,
            plannerPhoto: null,
            plannerFirstName: null,
            plannerLastName: null,
            plannerUserName: null,
            creationDate: null,
          },
          scheduledTime: {
            date: null,
            timestamp: null,
            timeString: null,
            checkWeek: this.state.checkWeek,
            checkMonth: this.state.checkMonth,
          },
          usersAttending: null,
          eventSettings: {
            itinerarySent: false,
            scheduled: false,
          },
          packageDetails: {
            packageId: null,
          },
        },
      };
    } else if (
      this.props.newEvent.fromPackage !== undefined &&
      this.props.newEvent.fromPackage === true
    ) {
      step = 4;
      customEvent = {
        clear: true,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        editMode: false,
        event: {
          description: this.props.newEvent.vEvent.description,
          title: this.props.newEvent.vEvent.title,
          status: 0,
          locations: this.props.newEvent.vEvent.locations,
          creationDetails: {
            plannerId: null,
            plannerPhoto: null,
            plannerFirstName: null,
            plannerLastName: null,
            plannerUserName: null,
            creationDate: null,
          },
          scheduledTime: {
            date: null,
            timestamp: null,
            timeString: null,
            checkWeek: this.state.checkWeek,
            checkMonth: this.state.checkMonth,
          },
          usersAttending: null,
          eventSettings: {
            itinerarySent: false,
            scheduled: false,
          },
          packageDetails: {
            packageId: null,
          },
        },
      };
    } else if (
      this.props.newEvent.fromLocation !== undefined &&
      this.props.newEvent.fromLocation === true
    ) {
      let locationsArray = [];
      let locationObject = {
        locationId: this.props.newEvent.singleLocation.locationId,
        locationTitle:
          this.props.newEvent.singleLocation.locationTitle,
        locationType: this.props.newEvent.singleLocation.locationType,
        address: this.props.newEvent.singleLocation.address,
        locationThumb:
          this.props.newEvent.singleLocation.locationThumb,
        hours: this.props.newEvent.singleLocation.hours,
        settings: this.props.newEvent.singleLocation.settings,
      };
      locationsArray.push(locationObject);
      step = 4;
      customEvent = {
        clear: true,
        step: 5,
        containsCustom: false,
        newEventRef: null,
        editMode: false,
        event: {
          description: '',
          title: '',
          status: 0,
          locations: locationsArray,
          creationDetails: {
            plannerId: null,
            plannerPhoto: null,
            plannerFirstName: null,
            plannerLastName: null,
            plannerUserName: null,
            creationDate: null,
          },
          scheduledTime: {
            date: null,
            timestamp: null,
            timeString: null,
            checkWeek: this.state.checkWeek,
            checkMonth: this.state.checkMonth,
          },
          usersAttending: null,
          eventSettings: {
            itinerarySent: false,
            scheduled: false,
          },
          packageDetails: {
            packageId: null,
          },
        },
      };
    } else if (
      this.props.newEvent.fromEntertainment !== undefined &&
      this.props.newEvent.fromEntertainment === true
    ) {
      let address = {};
      if (
        this.props.newEvent.entertainmentEvent.address.tdId !== null
      ) {
        const locationDetails = query('locationId')
          .is(this.props.newEvent.entertainmentEvent.address.tdId)
          .on(this.props.alllocations);
        let location = locationDetails[0];
        address = location.address;
      } else {
        address = this.props.newEvent.entertainmentEvent.address;
      }

      console.log(
        'this.props.newEvent.entertainmentEvent',
        this.props.newEvent.entertainmentEvent,
      );
      let locationsArray = [];
      let locationObject = {
        locationId:
          this.props.newEvent.entertainmentEvent.address.tdId,
        locationTitle:
          this.props.newEvent.entertainmentEvent.address.locationName,
        address,
        locationType: 3,
        event: true,
        eventId: this.props.newEvent.entertainmentEvent.eventId,
      };
      locationsArray.push(locationObject);

      step = 4;
      customEvent = {
        clear: true,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        editMode: false,
        event: {
          description: '',
          title: this.props.newEvent.entertainmentEvent.eventTitle,
          status: 0,
          locations: locationsArray,
          creationDetails: {
            plannerId: null,
            plannerPhoto: null,
            plannerFirstName: null,
            plannerLastName: null,
            plannerUserName: null,
            creationDate: null,
          },
          scheduledTime: {
            date: null,
            timestamp: null,
            timeString: null,
            checkWeek: this.state.checkWeek,
            checkMonth: this.state.checkMonth,
          },
          usersAttending: null,
          eventSettings: {
            itinerarySent: false,
            scheduled: false,
          },
          packageDetails: {
            packageId: null,
          },
        },
      };
    }

    let invitedUsers = this.state.invitedUsers;
    if (invitedUsers.length === 0) invitedUsers.push(user.userId);
    let connections = this.state.connections;
    // Add Current User To Guests

    let newUser = false;
    if (user.newUser !== undefined && user.newUser === true) {
      newUser = true;
    }

    connections[user.userId] = {
      access: true,
      approved: false,
      editor: false,
      attending: false,
      rejected: false,
      newUser,
      inviteAction: false,
      profile: {
        fullName: fullName,
        userName: user.userName,
        profilePhotoPath: user.profilePhotoPath,
      },
    };
    let currentUser = this.props.user;
    connections[currentUser.userId] = {
      access: true,
      approved: true,
      editor: true,
      attending: true,
      rejected: false,
      newUser: false,
      // inviteAction: null,
      profile: {
        fullName: currentUser.firstName + ' ' + currentUser.lastName,
        userName: currentUser.userName,
        profilePhotoPath: currentUser.profilePhotoPath,
      },
    };

    // General Settings
    if (connectFirst === true) {
      newEvent = {
        connectFirst: true,
        clear: false,
        step: step,
        weightedLocations: [],
      };
    } else {
      newEvent = {
        connectFirst: false,
        clear: false,
        step: step,
        weightedLocations: [],
      };
    }

    // Pass user's locations

    newEvent.locations = alllocations;

    if (
      this.props.newEvent.fromFavorite === true ||
      this.props.newEvent.fromEntertainment === true ||
      this.props.newEvent.fromVEvent === true ||
      this.props.newEvent.fromPackage === true ||
      this.props.newEvent.fromLocation
    ) {
      newEvent.customEvent = customEvent;
    }

    if (this.props.newEvent.fromVEvent === true) {
      newEvent.fromVEvent = this.props.newEvent.fromVEvent;
      newEvent.vEvent = this.props.newEvent.vEvent;
    }

    // Set Invited User *** change
    newEvent.invitedUsers = invitedUsers;
    newEvent.connections = this.state.connections;
    if (connectFirst === true) {
      this.setState({
        newEvent: newEvent,
        modalState: 2,
      });
    } else {
      this.props.getNewEvent(newEvent);
    }
  }

  newConnection(user) {
    // this.setState({ invitedUser: user, screen: 3, newUser: user });

    let userx = {};

    if (user.newUser !== true) {
      const userTits = user.userId;
      const docDetails = query('userId')
        .is(userTits)
        .on(this.props.users);
      userx = docDetails[0];
    } else {
      userx = user;
    }

    this.addUser(
      userx,
      this.state.connections,
      this.state.invitedUsers,
      false,
    );

    this.setState({
      searchTerm: '',
    });

    if (user.newUser === true) {
      this.changeMode(1);
    }

    // this.setConnection(user, false, this.props.alllocations);
    // this.openConnectFirstModal(user);
  }

  planFirst() {
    let user = this.props.user;
    let customEvent = {};
    const fullName = user.firstName + user.lastName;
    let connections = {};
    connections[user.userId] = {
      access: true,
      approved: true,
      editor: true,
      attending: true,
      rejected: false,
      // inviteAction: null,
      profile: {
        fullName: fullName,
        userName: user.userName,
        profilePhotoPath: user.profilePhotoPath,
      },
    };

    if (this.props.newEvent.fromFavorite === true) {
      customEvent = {
        clear: true,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        editMode: false,
        event: {
          description: null,
          title: null,
          status: 0,
          locations: this.props.newEvent.favoriteLocations,
          creationDetails: {
            plannerId: null,
            plannerPhoto: null,
            plannerFirstName: null,
            plannerLastName: null,
            plannerUserName: null,
            creationDate: null,
          },
          scheduledTime: {
            date: null,
            timestamp: null,
            timeString: null,
            checkWeek: this.state.checkWeek,
            checkMonth: this.state.checkMonth,
          },
          usersAttending: null,
          eventSettings: {
            itinerarySent: false,
            scheduled: false,
          },
          packageDetails: {
            packageId: null,
          },
        },
      };
    } else if (this.props.newEvent.fromVEvent === true) {
      customEvent = {
        clear: true,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        editMode: false,
        fromVEvent: true,
        vEvent: this.props.newEvent.vEvent,
        event: {
          description: this.props.newEvent.vEvent.description,
          title: this.props.newEvent.vEvent.title,
          status: 0,
          locations: this.props.newEvent.vEvent.locations,
          creationDetails: {
            plannerId: null,
            plannerPhoto: null,
            plannerFirstName: null,
            plannerLastName: null,
            plannerUserName: null,
            creationDate: null,
          },
          scheduledTime: {
            date: null,
            timestamp: null,
            timeString: null,
            checkWeek: this.state.checkWeek,
            checkMonth: this.state.checkMonth,
          },
          usersAttending: null,
          eventSettings: {
            itinerarySent: false,
            scheduled: false,
          },
          packageDetails: {
            packageId: null,
          },
        },
      };
    } else if (
      this.props.newEvent.fromPackage !== undefined &&
      this.props.newEvent.fromPackage === true
    ) {
      customEvent = {
        clear: true,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        editMode: false,
        event: {
          description: this.props.newEvent.vEvent.description,
          title: this.props.newEvent.vEvent.title,
          status: 0,
          locations: this.props.newEvent.vEvent.locations,
          creationDetails: {
            plannerId: null,
            plannerPhoto: null,
            plannerFirstName: null,
            plannerLastName: null,
            plannerUserName: null,
            creationDate: null,
          },
          scheduledTime: {
            date: null,
            timestamp: null,
            timeString: null,
            checkWeek: this.state.checkWeek,
            checkMonth: this.state.checkMonth,
          },
          usersAttending: null,
          eventSettings: {
            itinerarySent: false,
            scheduled: false,
          },
          packageDetails: {
            packageId: null,
          },
        },
      };
    } else if (
      this.props.newEvent.fromLocation !== undefined &&
      this.props.newEvent.fromLocation === true
    ) {
      let locationsArray = [];
      let customImages = false;
      if (
        this.props.newEvent.singleLocation.settings.customImages !==
        undefined
      ) {
        customImages =
          this.props.newEvent.singleLocation.settings.customImages;
      }
      let locationObject = {
        locationId: this.props.newEvent.singleLocation.locationId,
        locationTitle:
          this.props.newEvent.singleLocation.locationTitle,
        type: 0,
        locationThumb:
          this.props.newEvent.singleLocation.locationThumb,
        address: this.props.newEvent.singleLocation.address,
        settings: {
          customImages,
        },
        hours: this.props.newEvent.singleLocation.hours,
      };
      locationsArray.push(locationObject);
      customEvent = {
        clear: true,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        editMode: false,
        event: {
          description: '',
          title: '',
          status: 0,
          locations: locationsArray,
          creationDetails: {
            plannerId: null,
            plannerPhoto: null,
            plannerFirstName: null,
            plannerLastName: null,
            plannerUserName: null,
            creationDate: null,
          },
          scheduledTime: {
            date: null,
            timestamp: null,
            timeString: null,
            checkWeek: this.state.checkWeek,
            checkMonth: this.state.checkMonth,
          },
          usersAttending: null,
          eventSettings: {
            itinerarySent: false,
            scheduled: false,
          },
          packageDetails: {
            packageId: null,
          },
        },
      };
    } else if (
      this.props.newEvent.fromEntertainment !== undefined &&
      this.props.newEvent.fromEntertainment === true
    ) {
      let locationsArray = [];
      let location = {};
      if (
        this.props.newEvent.entertainmentEvent.address.tdId !== null
      ) {
        const locationDetails = query('locationId')
          .is(this.props.newEvent.entertainmentEvent.address.tdId)
          .on(this.props.alllocations);
        location = locationDetails[0];
      } else {
        location = this.props.newEvent.entertainmentEvent;
      }

      let locationObject = {
        locationId:
          this.props.newEvent.entertainmentEvent.address.tdId,
        locationTitle:
          this.props.newEvent.entertainmentEvent.address.locationName,
        address: location.address,
        locationThumb:
          this.props.newEvent.entertainmentEvent.eventThumb,
        locationHero:
          this.props.newEvent.entertainmentEvent.eventHero,
        locationType: 3,
        event: true,
        eventId: this.props.newEvent.entertainmentEvent.eventId,
      };
      locationsArray.push(locationObject);

      customEvent = {
        clear: true,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        editMode: false,
        event: {
          description: '',
          title: this.props.newEvent.entertainmentEvent.eventTitle,
          status: 0,
          locations: locationsArray,
          creationDetails: {
            plannerId: null,
            plannerPhoto: null,
            plannerFirstName: null,
            plannerLastName: null,
            plannerUserName: null,
            creationDate: null,
          },
          scheduledTime: {
            date: null,
            timestamp: null,
            timeString: null,
            checkWeek: this.state.checkWeek,
            checkMonth: this.state.checkMonth,
          },
          usersAttending: null,
          eventSettings: {
            itinerarySent: false,
            scheduled: false,
          },
          packageDetails: {
            packageId: null,
          },
        },
      };
    } else {
      customEvent = {
        clear: true,
        step: 1,
        containsCustom: false,
        newEventRef: null,
        editMode: false,
        event: {
          description: '',
          title: '',
          status: 0,
          locations: [],
          creationDetails: {
            plannerId: null,
            plannerPhoto: null,
            plannerFirstName: null,
            plannerLastName: null,
            plannerUserName: null,
            creationDate: null,
          },
          scheduledTime: {
            date: null,
            timestamp: null,
            timeString: null,
            checkWeek: this.state.checkWeek,
            checkMonth: this.state.checkMonth,
          },
          usersAttending: null,
          eventSettings: {
            itinerarySent: false,
            scheduled: false,
          },
          packageDetails: {
            packageId: null,
          },
        },
      };
    }
    const newEvent = {
      connections,
      planFirst: true,
      connectFirst: false,
      clear: false,
      step: 4,
      invitedUsers: [],
      favoriteLocations: this.props.newEvent.favoriteLocations,
      fromEntertainment: this.props.newEvent.fromEntertainment,
      fromFavorite: this.props.newEvent.fromFavorite,
      fromVEvent: this.props.newEvent.fromVEvent,
      fromLocation: this.props.newEvent.fromLocation,
      fromPackage: this.props.newEvent.fromPackage,
      vEvent: this.props.newEvent.vEvent,
      location: this.props.newEvent.locations,
      weightedLocations: this.props.newEvent.weightedLocations,
      entertainmentEvent: this.props.newEvent.entertainmentEvent,
      customEvent,
    };
    this.props.getNewEvent(newEvent);
  }

  setSchedule = (index) => (selectedDay, selectedTime) => {
    let bundle = {
      date: '',
      time: '',
      timeString: '',
      timestamp: '',
    };
    // Check for Time
    if (
      selectedTime !== '' &&
      selectedTime !== undefined &&
      selectedTime !== null
    ) {
      const datestring = String(selectedTime);
      const newtime = datestring.split(' ');
      const time = newtime[4].split(':');
      const hours = Number(time[0]);
      const minutes = Number(time[1]);
      const seconds = Number(time[2]);
      let timeValue;
      if (hours > 0 && hours <= 12) {
        timeValue = `${hours}`;
      } else if (hours > 12) {
        timeValue = `${hours - 12}`;
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
      timeValue += hours >= 12 ? ' P.M.' : ' A.M.';
      bundle.time = timeValue;
      bundle.timeString = selectedTime._d;
    }
    // Check for Date
    if (
      selectedDay !== '' &&
      selectedDay !== undefined &&
      selectedDay !== null
    ) {
      const timestamp = moment(selectedDay).unix();
      const timestampReady = timestamp * 1000;
      const dateString = moment.unix(timestamp).format('YYYY-MM-DD');
      bundle.date = dateString;
      bundle.timestamp = timestampReady;
    }
    this.setState({
      scheduledTime: bundle,
      scheduleSet: true,
      modalState: 3,
    });
  };

  skipSchedule = (index) => () => {
    this.setState({
      scheduleSet: false,
      skipSchedule: true,
      modalState: 3,
    });
  };

  saveMessage = (index) => (message) => {
    const invitedUser = this.state.invitedUser;
    const userEvent = this.state.userEvent;
    const schedule = this.state.scheduledTime;
    const user = this.props.user;
    const userVouchers = this.state.userVouchers;
    const newEvent = this.state.newEvent;

    const timestamp = new Date();

    let fullName = '';
    if (
      invitedUser.newUser !== undefined &&
      invitedUser.newUser === true
    ) {
      fullName = invitedUser.fullName;
    } else {
      fullName = `${invitedUser.firstName} ${invitedUser.lastName}`;
    }

    let messageReady = message;
    if (messageReady === undefined) {
      messageReady = '';
    }

    // Set Locations
    const locations = [];
    // Set  Up Invite
    const invitation = {
      dateSent: timestamp,
      senderName: user.firstName,
      senderLastName: user.lastName,
      profilePhotoPath: user.profilePhotoPath,
      senderId: user.userId,
      userPurchaseId: null,
      eventId: this.state.dbref,
      locations,
      receiverName: fullName,
      userName: invitedUser.userName,
      userId: invitedUser.userId,
      receiverPhotoPath: invitedUser.profilePhotoPath,
      type: 4, // Type 4 = pending?
      status: 0,
      messageReady,
      editable: true,
    };
    // Set Users
    userEvent.users = newEvent.connections;
    // Set Invite Schedule
    if (this.state.scheduleSet === true) {
      invitation.scheduledTime = schedule.timestamp;
    } else {
      invitation.scheduledTime = '';
    }

    // Add Invitation to Event
    let invitationObject = {};
    invitationObject[newEvent.invitedUser.userId] = invitation;
    userEvent.invitations = invitationObject;

    // Set connectFirst
    userEvent.eventSettings.connectFirst = true;
    userEvent.eventSettings.eventApproved = false;

    this.saveEvent(userEvent, user, userVouchers, invitation);
  };

  saveEvent(event, profile, userVouchers, invitation) {
    const scheduledEvent = event;
    if (this.state.scheduleSet === true) {
      scheduledEvent.scheduledTime = this.state.scheduledTime;
    }
    const purchaseBundle = {
      event: scheduledEvent,
      profile: profile,
      userVouchers: userVouchers,
      invitation: invitation,
      eventId: this.state.dbref,
    };
    this.props.savePurchase(purchaseBundle);
  }

  getActionButton(screen, title) {
    if (this.state.screen === screen) {
      return (
        <div className="text-center borderRight actionButtonActive">
          {title}
        </div>
      );
    } else {
      return (
        <div
          className="text-center borderRight actionButton"
          onClick={() => {
            this.changeScreen(screen);
          }}
        >
          {title}
        </div>
      );
    }
  }

  addUser(user, connections, invitedUsers, status) {
    let newUserFullName = '';
    if (user.newUser !== undefined && user.newUser === true) {
      newUserFullName = user.fullName;
    } else {
      newUserFullName = user.firstName + ' ' + user.lastName;
    }
    let newConnections = connections;
    if (newConnections[user.userId] === undefined) {
      // Add Guest
      if (user.newUser !== undefined && user.newUser === true) {
        newConnections[user.userId] = {
          connected: false,
          access: true,
          approved: false,
          editor: false,
          attending: false,
          rejected: false,
          inviteAction: false,
          newUser: true,
          profile: {
            fullName: newUserFullName,
            profilePhotoPath: user.profilePhotoPath,
            userName: user.userName,
          },
        };
      } else {
        newConnections[user.userId] = {
          connected: status,
          access: true,
          approved: false,
          editor: false,
          attending: false,
          rejected: false,
          inviteAction: false,
          profile: {
            fullName: newUserFullName,
            profilePhotoPath: user.profilePhotoPath,
            userName: user.userName,
          },
        };
        // }
      }
    } else {
      let xxx = {};
      Object.keys(connections).forEach((key) => {
        if (key !== user.userId) {
          xxx[key] = connections[key];
        }
      });
      newConnections = xxx;
    }

    let invitedUsersUpdate = invitedUsers;
    invitedUsersUpdate.push(user.userId);

    this.setState({
      connections: newConnections,
      invitedUsers: invitedUsersUpdate,
    });
  }

  getUserStatus(user) {
    let connections = this.state.connections;
    if (connections[user.userId] !== undefined) {
      return 'adminlistitem selectedLocationBack';
    } else {
      return 'adminlistitem';
    }
  }

  getUser(user) {
    if (user.newUser !== true) {
      const userTits = user.userId;

      const docDetails = query('userId')
        .is(userTits)
        .on(this.props.users);
      const userInfo = docDetails[0];
      return (
        <div
          onClick={() => {
            this.addUser(
              userInfo,
              this.state.connections,
              this.state.invitedUsers,
              true,
            );
          }}
        >
          {/* <a
          onClick={() => {
            this.setConnection(
              userInfo,
              this.props.user,
              false,
              this.props.alllocations,
            );
          }}
        > */}
          {userInfo !== undefined && (
            <div>
              <div className="left">
                <UserAvatar
                  size="50"
                  name={userInfo.firstName + userInfo.lastName}
                  src={userInfo.profilePhotoPath}
                  // onClick={() => {
                  //   this.setConnection(
                  //     userInfo,
                  //     this.props.user,
                  //     false,
                  //     this.props.alllocations,
                  //   );
                  // }}
                />
              </div>
              <div className="left" style={{ marginLeft: '10px' }}>
                {/* <span
              className="invite-label"
              onClick={() => {
                this.setConnection(
                  userInfo,
                  this.props.user,
                  false,
                  this.props.alllocations,
                );
              }}
            > */}
                <span className="invite-label">
                  {userInfo.firstName} {userInfo.lastName}
                </span>
                <br />
                <span className="address">@{userInfo.userName}</span>
                {user.connected === false && (
                  <span>Not Connected</span>
                )}
              </div>
              <div className="right">
                {/* <MaterialIcon
              icon="email"
              size={25}
              color="#f21d59"
              onClick={() => {
                this.setConnection(userInfo, this.props.user, false);
              }}
            /> */}
                {/* <span
              onClick={() => {
                this.setConnection(
                  userInfo,
                  this.props.user,
                  false,
                  this.props.alllocations
                );
              }}
            >
              <img src={redLogo} className="bottomNavLogo" alt="logo" />
            </span> */}
              </div>
            </div>
          )}
        </div>
      );
    } else {
      let fullName = '';
      if (user.connected === false) {
        fullName = user.fullName;
      }
      return (
        <div
          onClick={() => {
            this.addUser(
              user,
              this.state.connections,
              this.state.invitedUsers,
              false,
            );
          }}
        >
          <div>
            <div className="left">
              <UserAvatar size="50" name={fullName} />
            </div>
            <div className="left" style={{ marginLeft: '10px' }}>
              <span className="invite-label">{fullName}</span>
              <br />

              <span>New User</span>
            </div>
            <div className="right"></div>
          </div>
        </div>
      );
    }
  }

  getCity(zoneId) {
    if (zoneId === 10) {
      return 'Kansas City';
    } else {
      return 'St. Louis';
    }
  }

  getUserListing(user, index) {
    const userx = user;
    return (
      <div
        onClick={() => {
          this.newConnection(user);
        }}
      >
        <div className="left">
          <UserAvatar
            size="50"
            name={userx.firstName + userx.lastName}
            src={userx.profilePhotoPath}
            // onClick={() => {
            //   this.setConnection(
            //     userInfo,
            //     this.props.user,
            //     false,
            //     this.props.alllocations,
            //   );
            // }}
          />
        </div>
        <div className="left" style={{ marginLeft: '10px' }}>
          {/* <span
        className="invite-label"
        onClick={() => {
          this.setConnection(
            userInfo,
            this.props.user,
            false,
            this.props.alllocations,
          );
        }}
      > */}
          <span
            className="invite-label"
            // onClick={() => {
            //   this.newConnection(userx);
            // }}
          >
            {userx.firstName} {userx.lastName}
          </span>
          <br />
          <span className="address">@{userx.userName}</span>
        </div>
        <div className="right">
          {/* <Col xs={2} md={2} sm={2} lg={2} className="no-padding">
            <div className="right">
              <MaterialIcon
                icon="email"
                size={25}
                color="#f21d59"
                onClick={() => {
                  this.newConnection(user);
                }}
              />
            </div>
          </Col> */}
        </div>
      </div>
    );
  }

  getContent(mode, connectionsArray) {
    if (mode === 1) {
      let filteredUsers = [];
      if (this.state.searchTerm === '') {
        filteredUsers = [];
      } else {
        filteredUsers = this.props.users.filter(
          createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
        );
      }

      let cleanedConnections = [];
      connectionsArray.forEach((user) => {
        const docDetails = query('userId')
          .is(user.userId)
          .on(this.props.users);

        if (docDetails[0] !== undefined) {
          cleanedConnections.push(user);
        }
      });

      let searchText = 'Search For Trevel Users';
      if (connectionsArray.length > 0) {
        searchText = 'Search For Additional Trevel Users';
      }

      return (
        <Container>
          <Row>
            <Col xs={12} md={12} sm={12} lg={12}>
              <SearchInput
                className="search-input centered-input"
                onChange={this.searchUpdated}
                placeholder={searchText}
                value={this.state.searchTerm}
              />
            </Col>
          </Row>
          {this.state.searchTerm === '' && (
            <Row className="no-margin" style={{ paddingTop: '20px' }}>
              <Col xs={12} md={12} sm={12} lg={12}>
                <div style={{ padding: '0px 0px 0px 0px' }}>
                  {this.getConnections(cleanedConnections)}
                </div>
              </Col>
            </Row>
          )}
          {this.state.searchTerm !== '' && (
            <Row className="no-margin" style={{ paddingTop: '20px' }}>
              <Col xs={12} md={12} sm={12} lg={12}>
                <ul className="no-padding">
                  {filteredUsers.map((user, index) => (
                    <li
                      className={this.getUserStatus(user)}
                      key={'key' + index}
                    >
                      {this.getUserListing(user, index)}
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          )}
        </Container>
      );
    } else {
      return (
        <Container>
          <Row>
            <Col xs={12} md={12} sm={12} lg={12}>
              <div style={{ maxWidth: '500px', margin: '0px auto' }}>
                <span>
                  You can send a Quick Invite Link to anyone, no
                  matter if they are signed up to Trevel or not.
                </span>
                <ul
                  style={{ marginTop: '20px', marginBottom: '40px' }}
                >
                  <li>
                    Fill in their name, any name will do, and click
                    "Next".
                  </li>
                  <li>Create and save your event.</li>
                  <li>
                    Copy your new Quick Invite Link and send it to
                    your guest however you want!
                  </li>
                </ul>
                <center>
                  <h2 style={{ fontWeight: '500', fontSize: '20px' }}>
                    Who do you want to invite?
                  </h2>
                </center>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} sm={12} lg={12}>
              <Formik
                initialValues={{ fullName: '' }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    setSubmitting(false);
                  }, 500);
                }}
                // validationSchema={Yup.object().shape({
                //   email: Yup.string()
                //     .email()
                //     .required('Required'),
                // })}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit} id="fullName">
                      {/* <label htmlFor="fullName" style={{ display: "block" }}>
                    Email
                  </label> */}
                      <div
                        style={{
                          maxWidth: '400px',
                          margin: '0px auto',
                        }}
                      >
                        <input
                          id="fullName"
                          placeholder="Any name will do."
                          type="text"
                          value={values.fullName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.email && touched.email
                              ? 'text-input error'
                              : 'text-input'
                          }
                        />
                      </div>
                      {/* {errors.email && touched.email && (
              <div className="input-feedback">{errors.email}</div>
            )} */}
                      {/* 
                  <button
                    type="button"
                    className="outline"
                    onClick={handleReset}
                    disabled={!dirty || isSubmitting}
                  >
                    Reset
                  </button>
                  <button type="submit" disabled={isSubmitting}>
                    Submit
                  </button> */}
                      {this.getNextButton(props)}
                    </form>
                  );
                }}
              </Formik>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} sm={12} lg={12} />
          </Row>
        </Container>
      );
    }
  }

  getNextButton(value) {
    const token = (Math.floor(Math.random() * 10000) + 10000)
      .toString()
      .substring(1);

    const user = {
      fullName: value.values.fullName,
      firstName: value.values.fullName,
      lastName: '',
      profilePhotoPath: '',
      userName: '',
      userId: token,
      newUser: true,
    };
    if (value.values.fullName === '') {
      return (
        <div
          style={{
            maxWidth: '400px',
            margin: '0px auto',
          }}
        >
          <center>
            <a className="greybutton fullwidth  display-block">
              Next
            </a>
          </center>
        </div>
      );
    } else {
      return (
        <div
          style={{
            maxWidth: '400px',
            margin: '0px auto',
          }}
        >
          <center>
            <a
              onClick={() => {
                this.newConnection(user);
              }}
              className="redbutton fullwidth white display-block"
            >
              Next
            </a>
          </center>
        </div>
      );
    }
  }

  getConnections(connections) {
    if (connections.length > 0) {
      return (
        <ul className="no-padding">
          {connections.map((user, index) => (
            <li className={this.getUserStatus(user)}>
              {this.getUser(user)}
            </li>
          ))}
        </ul>
      );
    } else {
      return (
        <div>
          {/* <div className="tipBox">
            <span className="tipText">
              You haven{"'"}t connected with any one yet.
            </span>
          </div> */}
          <div
            style={{
              padding: '15px 10px 10px 10px',
              border: '1px solid lightgrey',
              margin: '0px auto',
              width: '100%',
              marginTop: '100px',
              background: 'white',
              display: 'flex',
              textAlign: 'left',
            }}
          >
            <Col xs={2} md={1} sm={2} lg={1}>
              <div style={{ float: 'left' }}>
                <MaterialIcon
                  icon="help_outline"
                  size={35}
                  color="rgb(238 1 88)"
                />
              </div>
            </Col>
            <Col xs={10} md={11} sm={10} lg={11}>
              <h4 style={{ fontSize: '18px', marginBottom: '10px' }}>
                Create New Experience
              </h4>
              <p
                style={{
                  margin: '0px',
                  padding: '0px 0px 10px 0px',
                }}
              >
                It looks like you don{"'"}t have any connections yet.
                No worries, just search for Trevel Users using the
                search bar above, or send an invite link to anyone you
                like.
              </p>
              <p>
                If you want to start planning on your own you can just
                click{' '}
                <span style={{ fontWeight: '600', fontSize: '14px' }}>
                  Start Solo
                </span>{' '}
                below.
              </p>
            </Col>
          </div>
        </div>
      );
    }
  }

  setDistanceFilter = (value) => {
    let valuex = 100;
    if (value === 1) {
      valuex = 10;
    } else if (value === 0) {
      valuex = 50;
    }

    let bundle = {
      tagFilters: this.props.taRequest.tagFilters,
      distance: valuex,
      startLocation: this.props.taRequest.startLocation,
      eventCats: this.props.taRequest.eventCats,
      currentLatitude: this.props.taRequest.currentLatitude,
      currentLongitude: this.props.taRequest.currentLongitude,
      closestRegion: this.props.taRequest.closestRegion,
      userMoodYes: this.props.taRequest.userMoodYes,
      userMoodNo: this.props.taRequest.userMoodNo,
    };
    this.props.getTaRequest(bundle);
    this.setState({
      distanceFilterState: value,
    });
  };

  getButton(value, distance, state) {
    if (value !== state) {
      return (
        <button
          className="redbutton full-width"
          onClick={() => {
            this.setDistanceFilter(value);
          }}
        >
          {distance}
        </button>
      );
    } else {
      return (
        <button className="greybutton full-width">{distance}</button>
      );
    }
  }

  getScreen(screen, connectionsArray) {
    if (this.state.screen === 1) {
      let filteredUsers = [];
      if (this.state.searchTerm === '') {
        filteredUsers = [];
      } else {
        filteredUsers = this.props.users.filter(
          createFilter(this.state.searchTerm, KEYS_TO_FILTERS),
        );
      }

      return (
        <div className="twentyPush">
          {this.getContent(this.state.mode, connectionsArray)}
        </div>
      );
    } else if (this.state.screen === 4) {
      return (
        <div>
          <div className="header-container">
            <div>
              <Container>
                <Row>
                  <Col xs={12} md={12} sm={12} lg={12}>
                    <a
                      onClick={() => {
                        this.changeScreen(2);
                      }}
                    >
                      <span className="header-button white right">
                        + Add New Contact
                      </span>
                    </a>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <Container>
            <Row>
              <Col xs={12} md={12} sm={12} lg={12}>
                <div style={{ padding: '0px 0px 30px 0px' }}>
                  {this.getConnections(connectionsArray)}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (this.state.screen === 3) {
      return (
        <div>
          <div className="section">{this.getModalContent()}</div>
        </div>
      );
    }
  }

  getGuestCount(connections) {
    let count = Object.keys(connections).length;

    return count;
  }

  getGoButton(connections) {
    let count = Object.keys(connections).length;
    if (count === 0) {
      return (
        <a
          onClick={() => {
            this.planFirst();
          }}
          className="right"
        >
          <span className="center solidredbutton">Start Solo</span>
        </a>
      );
    } else {
      return (
        <a
          onClick={() => {
            this.setConnection(
              // userInfo,
              this.props.user,
              false,
              this.props.alllocations,
            );
          }}
          className="right"
        >
          <span className="center solidredbutton">Next</span>
        </a>
      );
    }
  }

  getFooter(mode) {
    return (
      <div className="guestListNav" style={{ zIndex: 0 }}>
        <Container>
          <Row className="no-padding">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              <div style={{ paddingBottom: '10px' }}>
                {/* <span className="tipText">
                  <h2 className="guestListTitle">
                    can{"'"}t find your guest?
                  </h2>
                  <span>
                    You can invite anyone by sending an Invite Link!
                  </span>
                  <br />
                </span> */}
                {this.state.mode !== 2 && (
                  <div style={{ paddingBottom: '10px' }}>
                    <center>
                      <a
                        onClick={() => {
                          this.changeMode(2);
                        }}
                        className="redbuttonStrong fullwidth white display-block"
                        style={{ top: '0px' }}
                      >
                        Invite a New User
                      </a>
                    </center>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row className="no-margin">
            {this.state.mode !== 2 && (
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding"
              >
                <span style={{ fontWeight: 600 }}>
                  {this.getGuestCount(this.state.connections)}
                </span>{' '}
                Guests
                {this.getGoButton(this.state.connections)}
              </Col>
            )}
          </Row>
        </Container>
      </div>
    );
  }

  setStep(step) {
    window.scrollTo(0, 0);
    this.setEventCats();
    this.setState({
      step,
    });
  }

  setStartingCat(cat) {
    // 1 = dining, 2 = entertainment
    let newEventCats = this.props.taRequest.eventCats;

    let endCat = 2;
    if (cat === 2) {
      endCat = 1;
    }

    newEventCats.startingCat = cat;
    newEventCats.endCat = endCat;

    let bundle = {
      tagFilters: this.props.taRequest.tagFilters,
      distance: this.props.taRequest.distance,
      startLocation: this.props.taRequest.startLocation,
      eventCats: newEventCats,
      currentLatitude: this.props.taRequest.currentLatitude,
      currentLongitude: this.props.taRequest.currentLongitude,
      closestRegion: this.props.taRequest.closestRegion,
      userMoodYes: this.props.taRequest.userMoodYes,
      userMoodNo: this.props.taRequest.userMoodNo,
    };
    this.props.getTaRequest(bundle);

    this.setState({
      step: 2,
    });
  }

  getStepOneFooter(step) {
    return (
      <div className="guestListNav">
        <Container>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding"
            >
              {step !== 2 && (
                <a
                  onClick={() => {
                    this.setStep(1);
                  }}
                  className="left"
                >
                  <span className="center solidredbutton">Prev</span>
                </a>
              )}

              {this.state.Dine === true ||
              this.state.Watch === true ||
              this.state.Do === true ? (
                <a
                  onClick={() => {
                    this.setStep(step);
                  }}
                  className="right"
                >
                  <span className="center solidredbutton">Next</span>
                </a>
              ) : (
                <a className="right">
                  <span className="center solidgreybutton">Next</span>
                </a>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  openConnectFirstModal(user) {
    this.setState({ connectFirstModalVisible: true, newUser: user });
  }

  closeonnectFirstModal() {
    this.setState({ connectFirstModalVisible: false });
  }

  changeScreen(screen) {
    this.setState({
      screen,
    });
  }

  getModalHeader() {
    if (this.state.modalState === 1) {
      // return <span className="modalHeader-text">Wait for Connection?</span>;
    } else if (this.state.modalState === 2) {
      return <span className="modalHeader-text">Schedule Event</span>;
    } else if (this.state.modalState === 3) {
      return (
        <span className="modalHeader-text">Write a Message</span>
      );
    }
  }

  getModalContent() {
    if (this.state.modalState === 1) {
      return (
        <Container>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding text-center"
            >
              <div>
                <span>
                  Would you like to wait for this user to connect with
                  you before planning event?
                </span>
                <a
                  onClick={() =>
                    this.setConnection(
                      this.state.newUser,
                      true,
                      this.props.alllocations,
                    )
                  }
                >
                  <span className="stackedRedButtonBlock fullwidth">
                    Yes
                  </span>
                </a>
                <a
                  onClick={() =>
                    this.setConnection(
                      this.state.newUser,
                      false,
                      this.props.alllocations,
                    )
                  }
                >
                  <span className="stackedRedButtonBlock fullwidth">
                    No
                  </span>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      );
    } else if (this.state.modalState === 2) {
      return (
        <Container>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding text-center"
            >
              <NewEventScheduleModal
                setSchedule={this.setSchedule()}
                skipSchedule={this.skipSchedule()}
              />
            </Col>
          </Row>
        </Container>
      );
    } else if (this.state.modalState === 3) {
      return (
        <Container>
          <Row className="no-margin">
            <Col
              xs={12}
              md={12}
              sm={12}
              lg={12}
              className="no-padding text-center"
            >
              {/* <p className="modalMessage">
                Include a message with your invite?
              </p> */}
              <NewEventMessage saveMessage={this.saveMessage()} />
            </Col>
          </Row>
        </Container>
      );
    }
  }

  getSecondaryTitle() {
    if (this.state.screen === 1) {
      // return <span>"Do you know who you want to invite?"</span>;
    } else if (this.state.screen === 2) {
      return (
        <div>
          <Container>
            <Row className="no-margin">
              <Col
                xs={12}
                md={12}
                sm={12}
                lg={12}
                className="no-padding text-center"
              >
                <span>Add New Contact</span>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (this.state.screen === 3) {
      if (this.state.modalState === 1) {
        return (
          <div>
            <Container>
              <Row className="no-margin">
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding text-center"
                >
                  <span>Add New Contact</span>
                </Col>
              </Row>
            </Container>
          </div>
        );
      } else if (this.state.modalState === 2) {
        return (
          <div>
            <Container>
              <Row className="no-margin">
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding text-center"
                >
                  <span>Schedule Event</span>
                </Col>
              </Row>
            </Container>
          </div>
        );
      } else if (this.state.modalState === 3) {
        return (
          <div>
            <Container>
              <Row className="no-margin">
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className="no-padding text-center"
                >
                  <span>Invitation Message</span>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }
    }
  }

  getTabBar() {
    if (this.state.screen === 1) {
      return;
    } else if (this.state.screen === 2) {
      return (
        <Row>
          <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
            {this.getTab('Trevel Users', 1, this.state.mode, false)}
          </Col>
          <Col lg={6} md={6} sm={6} xs={6} className="no-padding">
            {this.getTab('Get Invite Link', 2, this.state.mode, true)}
          </Col>
        </Row>
      );
    }
  }

  getBackButton() {
    if (this.state.screen === 1) {
      return (
        <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
          {/* <Link
            to={{
              pathname: "/events/home"
            }}
            className="left"
          >
            <MaterialIcon icon="keyboard_backspace" size={25} color="white" />
          </Link> */}
        </Col>
      );
    } else {
      return (
        <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
          <a
            onClick={() => {
              this.changeScreen(1);
            }}
            className="right"
          >
            <MaterialIcon
              icon="keyboard_backspace"
              size={25}
              color="white"
            />
          </a>
        </Col>
      );
    }
  }

  setEventCats() {
    let updatedTags = [];
    let tagFilters = [];
    if (this.props.taRequest.tagFilters.length < 0) {
      this.props.taRequest.tagFilters.forEach((tagcat, x) => {
        tagFilters.push(tagcat);
      });
    } else {
      this.props.tags.forEach((tagcat, x) => {
        tagFilters.push(tagcat);
      });

      tagFilters.forEach((tagcat, x) => {
        tagcat.tags.map((tag, y) => {
          tagFilters[x].tags[y].status = 1;
          tagFilters[x].tags[y].checked = false;
          tagFilters[x].tags[y].parentindex = x;
          tagFilters[x].tags[y].tagindex = y;
        });
      });
    }

    let eventCats = {
      eat: false,
      watch: false,
      do: false,
      startingCat: this.props.taRequest.eventCats.startingCat,
      endCat: this.props.taRequest.eventCats.endCat,
    };

    if (this.state.Do === true) {
      eventCats.do = true;
    }
    if (this.state.Watch === true) {
      eventCats.watch = true;
    }
    if (this.state.Dine === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Eat') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.eat = true;
    }

    if (this.state.Music === true) {
      eventCats.music = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Music') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Theater === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Theater') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.theater = true;
    }

    if (this.state.HappyHour === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Happy Hour') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.happyHour = true;
    }

    if (this.state.Brunch === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Brunch') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.brunch = true;
    }

    if (this.state.Dinner === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Dinner') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.dinner = true;
    }

    if (this.state.Dessert === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Dessert') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.dessert = true;
    }

    if (this.state.Breakfast === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Breakfast') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.breakfast = true;
    }

    if (this.state.Lunch === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Lunch') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.lunch = true;
    }

    if (this.state.Outdoors === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Outdoors') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.outdoors = true;
    }

    if (this.state.Museum === true) {
      eventCats.museum = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Museum') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Golf === true) {
      eventCats.golf = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Golf') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Drinks === true) {
      eventCats.drinks = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Drinks') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Comedy === true) {
      eventCats.comedy = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Comedy Show') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Zoo === true) {
      eventCats.zoo = true;
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Zoo') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Do === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Do') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Watch === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Watch') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    }

    if (this.state.Dine === true) {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === 'Eat ') {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
      eventCats.eat = true;
    }

    this.props.tags.forEach((tagCat) => {
      if (tagCat.sid == 'dateType') {
        tagCat.tags.forEach((tag) => {
          if (
            this.state['type' + tag.tag] !== undefined &&
            this.state['type' + tag.tag] === true
          ) {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      }
    });
    const tagsBeverageType = [];
    if (this.state.beverageType !== undefined) {
      this.state.beverageType.forEach((doc) => {
        tagsBeverageType.push(doc.value);
      });
    }
    tagsBeverageType.forEach((tagx) => {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === tagx) {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    });

    const tagsCuisineType = [];
    if (this.state.userMoodYes !== undefined) {
      this.state.userMoodYes.forEach((doc) => {
        tagsCuisineType.push(doc.value);
      });
    }
    tagsCuisineType.forEach((tagx) => {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === tagx) {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    });

    const tagsGenreType = [];
    if (this.state.genreType !== undefined) {
      this.state.genreType.forEach((doc) => {
        tagsGenreType.push(doc.value);
      });
    }
    tagsGenreType.forEach((tagx) => {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === tagx) {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    });

    const tagsEventType = [];
    if (this.state.eventTypes !== undefined) {
      this.state.eventTypes.forEach((doc) => {
        tagsEventType.push(doc.value);
      });
    }
    tagsEventType.forEach((tagx) => {
      tagFilters.forEach((tagCat) => {
        tagCat.tags.forEach((tag) => {
          if (tag.tag === tagx) {
            tagFilters[tag.parentindex].tags[
              tag.tagindex
            ].checked = true;
          }
        });
      });
    });

    // Manage start and end cat if only one is selected

    if (eventCats.eat === false && eventCats.watch === true) {
      eventCats.startingCat = 2;
      eventCats.endCat = 2;
    }

    let bundle = {
      tagFilters: tagFilters,
      distance: this.props.taRequest.distance,
      startLocation: this.props.taRequest.startLocation,
      eventCats,
      currentLatitude: this.props.taRequest.currentLatitude,
      currentLongitude: this.props.taRequest.currentLongitude,
      closestRegion: this.props.taRequest.closestRegion,
      userMoodYes: this.state.userMoodYes,
      userMoodNo: this.state.userMoodNo,
    };
    this.props.getTaRequest(bundle);
  }

  getTitleBar() {
    return (
      <div id="headersTop">
        <div className="backbar">
          <Row className="no-margin">
            <Col lg={2} md={2} sm={2} xs={2} className="no-padding">
              <Link
                to={{
                  pathname: '/',
                }}
                className="left"
              >
                <MaterialIcon icon="close" size={25} color="white" />
              </Link>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <center>
                <span className="page-header">Trevel Agent</span>
              </center>
            </Col>

            {this.getBackButton()}
          </Row>
        </div>
      </div>
    );
  }

  changeMode(mode) {
    this.setState({
      mode,
    });
  }

  getTab(tab, tabMode, mode, last) {
    if (tabMode !== mode) {
      if (last === true) {
        return (
          <a
            onClick={() => {
              this.changeMode(tabMode);
            }}
          >
            <div className="text-center actionButtonInactive actionButton noBorderRight">
              <div>
                <span>{tab}</span>
              </div>
            </div>
          </a>
        );
      } else {
        return (
          <a
            onClick={() => {
              this.changeMode(tabMode);
            }}
          >
            <div className="text-center actionButtonInactive actionButton">
              <div>
                <span>{tab}</span>
              </div>
            </div>
          </a>
        );
      }
    } else {
      if (last === true) {
        return (
          <div className="text-center actionButtonActive actionButton noBorderRight">
            <div>
              <span>{tab}</span>
            </div>
          </div>
        );
      } else {
        return (
          <div className="text-center actionButtonActive actionButton">
            <div>
              <span>{tab}</span>
            </div>
          </div>
        );
      }
    }
  }

  // criteria Handlers
  selectRegionChange = (values) => {
    let bundle = {
      tagFilters: this.props.taRequest.tagFilters,
      distance: this.props.taRequest.distance,
      startLocation: values.value,
      eventCats: this.props.taRequest.eventCats,
      currentLatitude: this.props.taRequest.currentLatitude,
      currentLongitude: this.props.taRequest.currentLongitude,
      closestRegion: this.props.taRequest.closestRegion,
      userMoodYes: this.props.taRequest.userMoodYes,
      userMoodNo: this.props.taRequest.userMoodNo,
    };
    this.props.getTaRequest(bundle);
    this.setState({
      selectedRegion: values,
      participantsType: values.value,
      regionInputOn: true,
    });
  };

  participantsTypeChange(event) {
    let value = event.target.value;
    if (value !== 'region') {
      let bundle = {
        tagFilters: this.props.taRequest.tagFilters,
        distance: this.props.taRequest.distance,
        startLocation: event.target.value,
        eventCats: this.props.taRequest.eventCats,
        currentLatitude: this.props.taRequest.currentLatitude,
        currentLongitude: this.props.taRequest.currentLongitude,
        closestRegion: this.props.taRequest.closestRegion,
        userMoodYes: this.props.taRequest.userMoodYes,
        userMoodNo: this.props.taRequest.userMoodNo,
      };
      this.props.getTaRequest(bundle);
      this.setState({
        participantsType: event.target.value,
      });
    } else {
      let bundle = {
        tagFilters: this.props.taRequest.tagFilters,
        distance: this.props.taRequest.distance,
        startLocation: this.state.selectedRegion.value,
        eventCats: this.props.taRequest.eventCats,
        currentLatitude: this.props.taRequest.currentLatitude,
        currentLongitude: this.props.taRequest.currentLongitude,
        closestRegion: this.props.taRequest.closestRegion,
        userMoodYes: this.props.taRequest.userMoodYes,
        userMoodNo: this.props.taRequest.userMoodNo,
      };
      this.props.getTaRequest(bundle);
      this.setState({
        participantsType: this.state.selectedRegion.value,
      });
    }
  }

  checkStartLocation() {
    if (this.props.taRequest.startLocation === 'lastLocation') {
      if (this.props.customEvent.event.locations.length === 0) {
        let bundle = {
          tagFilters: this.props.taRequest.tagFilters,
          distance: this.props.taRequest.distance,
          startLocation: 'current',
          eventCats: this.props.taRequest.eventCats,
          currentLatitude: this.props.taRequest.currentLatitude,
          currentLongitude: this.props.taRequest.currentLongitude,
          closestRegion: this.props.taRequest.closestRegion,
          userMoodYes: this.props.taRequest.userMoodYes,
          userMoodNo: this.props.taRequest.userMoodNo,
        };
        this.props.getTaRequest(bundle);
      }
    }
  }

  getRegions(regions) {
    let selectOptions = [];
    if (regions !== undefined) {
      regions.forEach((region) => {
        selectOptions.push({
          value: region.name,
          label: region.name,
        });
      });
    }
    let sorted = selectOptions.sort((a, b) =>
      a.value.localeCompare(b.value),
    );
    return sorted;
  }

  selectCat = (value) => {
    if (value.target.alt === 'typeHappy Hour') {
      this.setState({
        Drinks: true,
        Dine: true,
      });
    }
    this.setState({
      [value.target.alt]: value.target.checked,
    });
  };

  getBox = (tag) => {
    return (
      <div>
        <input
          type="checkbox"
          checked={this.state['type' + tag]}
          onChange={this.selectCat}
          className="closedCheckBox"
          style={{
            width: '20px',
            height: '20px',
            marginRight: '5px',
            top: '2px',
          }}
          alt={'type' + tag}
        />
        <span>{tag}</span>
      </div>
    );
  };

  getCategoryFieldsDateTypeTags(tagsx) {
    const optionsEven = [];
    const optionsOdd = [];
    tagsx.forEach((cattag, i) => {
      if (cattag.sid === 'dateType') {
        const tags = cattag.tags;
        let count = tags.length;
        let half = count / 2;

        let alphaTags = [];
        tags.forEach((tag, i) => {
          alphaTags.push(tag.tag);
        });
        let sorted = alphaTags.sort((a, b) => a.localeCompare(b));
        sorted.forEach((tag, i) => {
          if (tag !== 'Weekend Getaway')
            if (i < half) {
              optionsEven.push({
                value: tag,
                label: tag,
              });
            } else {
              optionsOdd.push({
                value: tag,
                label: tag,
              });
            }
        });
      }
    });
    return (
      <Row className="no-margin">
        <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
          <div>
            {optionsEven.map((tag, index) => (
              <div>{this.getBox(tag.value)}</div>
            ))}
          </div>
        </Col>
        <Col xs={6} md={6} sm={6} lg={6} className="no-padding">
          <div>
            {optionsOdd.map((tag, index) => (
              <div>{this.getBox(tag.value)}</div>
            ))}
          </div>
        </Col>
      </Row>
    );
  }

  handleGenreChange = (fieldName, value) => {
    this.setState({ genreType: value });
  };

  getMainGenreFields(cattag) {
    const options = [];
    if (cattag.sid === 'genre') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });

      let sorted = options.sort((a, b) =>
        a.value.localeCompare(b.value),
      );
      return (
        <div>
          {/* <span className="inputLabel">{cattag.parent}</span> */}
          <Select
            placeholder={'Certain Genre?'}
            value={this.state.genreType}
            onChange={(value) => {
              this.handleGenreChange(cattag.sid, value);
            }}
            options={sorted}
            isMulti={true}
          />
        </div>
      );
    }
  }

  handleEventTypeChange = (selectName, value) => {
    this.setState({
      eventTypes: value,
    });
  };

  getEventTypeFields(cattag) {
    const options = [];
    if (cattag.sid === 'eventType') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });

      let sorted = options.sort((a, b) =>
        a.value.localeCompare(b.value),
      );
      return (
        <div>
          {/* <span className="inputLabel">{cattag.parent}</span> */}
          <Select
            placeholder={'Other type of event?'}
            value={this.state.eventTypes}
            onChange={(value) => {
              this.handleEventTypeChange(cattag.sid, value);
            }}
            options={sorted}
            isMulti={true}
          />
        </div>
      );
    }
  }

  handleCuisineChange = (selectName, value) => {
    this.setState({ [selectName]: value });
  };

  getMainCuisineFields(tags, selectName) {
    const options = [];
    tags.forEach((cattag) => {
      if (
        cattag.sid === 'cuisineType' ||
        cattag.sid === 'beverages'
      ) {
        cattag.tags.forEach((tag) => {
          options.push({
            value: tag.tag,
            label: tag.tag,
          });
        });
      }
    });
    let sorted = options.sort((a, b) =>
      a.value.localeCompare(b.value),
    );
    return (
      <div>
        {/* <span className="inputLabel">{cattag.parent}</span> */}
        <Select
          placeholder={'Select...'}
          value={this.state[selectName]}
          onChange={(value) => {
            this.handleCuisineChange(selectName, value);
          }}
          options={sorted}
          isMulti={true}
        />
      </div>
    );
  }

  handleBeveragesChange = (fieldName, value) => {
    this.setState({ beverageType: value });
  };

  getMainBeverageFields(cattag) {
    const options = [];
    if (cattag.sid === 'beverages') {
      const tags = cattag.tags;
      tags.forEach((tag) => {
        options.push({
          value: tag.tag,
          label: tag.tag,
        });
      });
      return (
        <div>
          {/* <span className="inputLabel">{cattag.parent}</span> */}
          <Select
            placeholder={'Certain Type Of Beverage?'}
            value={this.state.beverageType}
            onChange={(value) => {
              this.handleBeveragesChange(cattag.sid, value);
            }}
            options={options}
            isMulti={true}
          />
        </div>
      );
    }
  }

  render() {
    let showExperienceType = false;
    if (
      this.state.Dine === true ||
      this.state.Do === true ||
      this.state.Watch === true
    ) {
      showExperienceType = true;
    }
    if (this.props.loading) {
      return null; // or render a loading icon
    }
    let connectionsArray = this.setConnectionsArray(
      this.props.connections,
      this.state.connections,
    );

    let miles = '';
    if (this.state.distanceFilterState === 0) {
      miles = '50';
    } else if (this.state.distanceFilterState === 1) {
      miles = '10';
    }

    let regions = this.getRegions(this.props.regions);
    if (this.state.step === 5) {
      return (
        <div id="newplans" style={{ height: '100%' }}>
          {this.getTitleBar()}
          {/* <div className="secondary-title-bar"></div> */}
          <div style={{ height: '100%', backgroundColor: '#ee0059' }}>
            <Container>
              <Row className="no-margin">
                <Col
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  className=" text-center"
                >
                  <div style={{ marginTop: '100px' }}>
                    <span
                      style={{
                        color: 'white',
                        fontSize: '20px',
                      }}
                    >
                      Food & Entertainment Coming Up!
                    </span>
                    <span
                      style={{
                        color: 'white',
                        fontSize: '20px',
                        display: 'block',
                        clear: 'both',
                      }}
                    >
                      Where would you like to start?
                    </span>

                    <a onClick={() => this.setStartingCat(1)}>
                      <span className="stackedWhiteButtonBlock fullwidth">
                        Dining
                      </span>
                    </a>

                    <a onClick={() => this.setStartingCat(2)}>
                      <span className="stackedWhiteButtonBlock fullwidth">
                        Entertainment
                      </span>
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* {this.getStepOneFooter(3)} */}
          <style
            dangerouslySetInnerHTML={{
              __html:
                'html{    background-color: #ee015a;} #headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
    if (this.state.step === 2) {
      return (
        <div id="newplans">
          {this.getTitleBar()}

          <div className="secondary-title-bar">
            <div>
              <Container>
                <Row className="no-margin">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding text-center"
                  >
                    <span style={{ fontStyle: 'normal' }}>
                      Where should I look?
                    </span>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>

          <div
            style={{
              padding: '15px',
              maxWidth: '600px',
              margin: '0px auto',
            }}
          >
            <div
              style={{
                clear: 'both',
                borderBottom: '1px solid grey',
              }}
            >
              <label
                style={{
                  marginRight: '10px',
                  fontWeight: '500',
                  fontSize: '18px',
                  color: '#ed0259',
                }}
              >
                <input
                  style={{
                    marginRight: '5px',
                    float: 'left',
                    minWidth: 'unset',
                    position: 'relative',
                    width: '25px',
                    height: '25px',
                  }}
                  type="radio"
                  value="current"
                  checked={this.state.participantsType === 'current'}
                  onChange={this.participantsTypeChange.bind(this)}
                />
                Near My Current Location
              </label>
            </div>
            {/* <div
              style={{
                clear: 'both',
                borderBottom: '1px solid grey',
                padding: '10px 0px 0px 0px',
              }}
            >
              <label
                style={{
                  marginRight: '10px',
                  fontWeight: '500',
                  fontSize: '18px',
                  color: '#ed0259',
                }}
              >
                <input
                  style={{
                    marginRight: '5px',
                    float: 'left',
                    minWidth: 'unset',
                    position: 'relative',
                    width: '25px',
                    height: '25px',
                  }}
                  type="radio"
                  value="home"
                  checked={this.state.participantsType === 'home'}
                  onChange={this.participantsTypeChange.bind(this)}
                />
                Near My Home
              </label>
            </div> */}

            <div
              style={{
                clear: 'both',

                padding: '10px 0px 0px 0px',
              }}
            >
              <input
                style={{
                  marginRight: '5px',
                  float: 'left',
                  minWidth: 'unset',
                  position: 'relative',
                  width: '25px',
                  height: '25px',
                }}
                type="radio"
                value={'region'}
                checked={this.state.participantsType !== 'current'}
                onChange={this.participantsTypeChange.bind(this)}
              />

              <div
                style={{
                  height: '50px',
                  margin: '0px auto',
                  float: 'left',
                  minWidth: '300px',
                }}
              >
                <Select
                  value={this.state.selectedRegion}
                  // closeMenuOnSelect={true}
                  blurInputOnSelect={false}
                  onChange={(e) =>
                    this.selectRegionChange(e, 0, 'one')
                  }
                  options={regions}
                  // onInputChange={(e) =>
                  //   this.moodTextChange(e, 0, 'one')
                  // }
                  isMulti={false}
                  placeholder={'Select area of City'}
                />
              </div>
            </div>
            {/* <div style={{ marginTop: '30px' }}>
              <div style={{ display: 'inline-block' }}>
                <span
                  style={{
                    fontWeight: '500',
                    fontSize: '18px',
                    clear: 'both',
                    display: 'block',
                  }}
                >
                  Distance
                </span>
                <div style={{ maxWidth: '400px', float: 'left' }}>
                  <Row className="no-padding">
                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      style={{ padding: '0px 5px 0px 0px' }}
                    >
                      {this.getButton(
                        1,
                        '10',
                        this.state.distanceFilterState,
                      )}
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      style={{ padding: '0px 5px 0px 0px' }}
                    >
                      {this.getButton(
                        0,
                        '50',
                        this.state.distanceFilterState,
                      )}
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      style={{ padding: '0px 5px 0px 0px' }}
                    >
                      {this.getButton(
                        2,
                        '100+',
                        this.state.distanceFilterState,
                      )}
                    </Col>
                  </Row>
                </div>
                <div
                  style={{
                    clear: 'both',
                    display: 'block',
                    marginTop: '20px',
                    float: 'left',
                  }}
                >
                  {this.state.participantsType === 'current' && (
                    <span style={{}}>
                      <span style={{ fontWeight: '600' }}>
                        {miles}
                      </span>{' '}
                      Miles around my current location.
                    </span>
                  )} */}
            {/* {this.state.participantsType === 'home' && (
                    <span><span style={{fontWeight: '600'}}>{miles}</span> Miles around my home. </span>
                  )} */}

            {/* {this.state.participantsType !== 'current' && (
                    <span
                      style={{
                        clear: 'both',
                        display: 'block',
                      }}
                    >
                      <span
                        style={{
                          fontWeight: '600',
                        }}
                      >
                        {miles}
                      </span>{' '}
                      Miles around {this.state.selectedRegion.value}
                    </span>
                  )}
                </div> */}
            {/* </div> */}
            {/* </div> */}
          </div>

          {this.getStepOneFooter(3)}
          <style
            dangerouslySetInnerHTML={{
              __html:
                '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
    if (this.state.step === 3) {
      return (
        <div id="newplans">
          {this.getTitleBar()}
          <div className="secondary-title-bar">
            <div>
              <Container>
                <Row className="no-margin">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding text-center"
                  >
                    {this.state.mode !== 2 && (
                      <span>Will you be inviting others?</span>
                    )}
                    {this.state.mode === 2 && (
                      <span style={{ fontStyle: 'normal' }}>
                        Invite a New User
                      </span>
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          {/* {this.state.mode !== 1 && (
            <div className="secondary-title-bar">
              {this.getSecondaryTitle()}
            </div>
          )} */}

          {/* <div className="modalActionBar">{this.getTabBar()}</div> */}
          {/* <div className="adminActionBar">
            <Row>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getActionButton(1, "Connections")}
              </Col>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getActionButton(2, "All Users")}
              </Col>
              <Col lg={4} md={4} sm={4} xs={4} className="no-padding">
                {this.getActionButton(3, "Invite Link")}
              </Col>
            </Row>
          </div> */}
          {/* <div className="contentbox "> */}
          <div>
            {/* <div className="section twentyPush"> */}
            <div>
              {this.getScreen(this.state.screen, connectionsArray)}
            </div>
          </div>
          {this.getFooter()}
          <Modal
            isOpen={this.state.connectFirstModalVisible}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeConnectFirstModal}
            style={modalStyles}
            // contentLabel="Wait for Connection?"
          >
            <div className="full-height">
              <div className="modalHeader">
                <a
                  onPress={() => this.props.closeConnectFirstModal()}
                >
                  {this.getModalHeader()}
                </a>
              </div>
              <div className="section">{this.getModalContent()}</div>
            </div>
          </Modal>
          <style
            dangerouslySetInnerHTML={{
              __html:
                '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    } else {
      return (
        <div id="newplans" style={{ paddingBottom: '80px' }}>
          {this.getTitleBar()}

          <div className="secondary-title-bar">
            <div>
              <Container>
                <Row className="no-margin">
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    className="no-padding text-center"
                  >
                    <span style={{ fontStyle: 'normal' }}>
                      What would you like to do?
                    </span>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div
            style={{
              // padding: '10px',
              maxWidth: '500px',
              margin: '0px auto',
            }}
          >
            <div
              style={{
                borderBottom: '1px solid grey',
                padding: '10px',
              }}
            >
              <input
                type="checkbox"
                checked={this.state.Dine}
                onChange={this.selectCat}
                className="closedCheckBox"
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '10px',
                  top: '2px',
                }}
                alt="Dine"
              />
              <span
                style={{
                  fontWeight: '500',
                  fontSize: '18px',
                  color: '#ed0259',
                }}
              >
                Get Food or Drinks
              </span>

              {this.state.Dine === true && (
                <div>
                  <div style={{ padding: '10px 0px 0px 30px' }}>
                    <span
                      style={{
                        fontWeight: '100',
                        fontSize: '16px',
                        fontStyle: 'italic',
                      }}
                    >
                      Anything Specific?
                    </span>
                  </div>
                  <div style={{ padding: '10px 0px 0px 30px' }}>
                    <Row className="no-padding">
                      <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="no-padding"
                      >
                        <div>
                          <input
                            type="checkbox"
                            checked={this.state.Breakfast}
                            onChange={this.selectCat}
                            className="closedCheckBox"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                              top: '2px',
                            }}
                            alt="Breakfast"
                          />
                          <span>Breakfast</span>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            checked={this.state.Brunch}
                            onChange={this.selectCat}
                            className="closedCheckBox"
                            alt="Brunch"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                              top: '2px',
                            }}
                          />
                          <span>Brunch</span>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            checked={this.state.Lunch}
                            onChange={this.selectCat}
                            className="closedCheckBox"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                              top: '2px',
                            }}
                          />
                          <span>Lunch</span>
                        </div>
                      </Col>
                      <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="no-padding"
                      >
                        {/* <div>
                          <input
                            type="checkbox"
                            checked={this.state.HappyHour}
                            onChange={this.selectCat}
                            className="closedCheckBox"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                              top: '2px',
                            }}
                            alt="HappyHour"
                          />
                          <span>Happy Hour</span>
                        </div> */}
                        <div>
                          <input
                            type="checkbox"
                            checked={this.state.Dinner}
                            onChange={this.selectCat}
                            className="closedCheckBox"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                              top: '2px',
                            }}
                            alt="Dinner"
                          />
                          <span>Dinner</span>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            checked={this.state.Drinks}
                            onChange={this.selectCat}
                            className="closedCheckBox"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                              top: '2px',
                            }}
                            alt="Drinks"
                          />
                          <span>Drinks</span>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            checked={this.state.Dessert}
                            onChange={this.selectCat}
                            className="closedCheckBox"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                              top: '2px',
                            }}
                            alt="Dessert"
                          />
                          <span>Dessert</span>
                        </div>
                      </Col>
                    </Row>
                    {/* <Row style={{ marginTop: '10px' }}>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <div>
                          {this.props.tags.map((cattag, index) => (
                            <div>
                              {this.getMainBeverageFields(cattag)}
                            </div>
                          ))}
                        </div>
                      </Col>
                    </Row> */}
                    <Row style={{ marginTop: '10px' }}>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <div style={{ marginTop: '5px' }}>
                          <span
                            style={{
                              fontWeight: '100',
                              fontSize: '16px',
                              fontStyle: 'italic',
                              marginBottom: '5px',
                              display: 'block',
                            }}
                          >
                            Are you in the mood for anything?
                          </span>
                          <div>
                            {this.getMainCuisineFields(
                              this.props.tags,
                              'userMoodYes',
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: '5px' }}>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <div style={{ marginTop: '5px' }}>
                          <span
                            style={{
                              fontWeight: '100',
                              fontSize: '16px',
                              fontStyle: 'italic',
                              marginBottom: '5px',
                              display: 'block',
                            }}
                          >
                            Anything you are NOT in the mood for?
                          </span>

                          <div>
                            {this.getMainCuisineFields(
                              this.props.tags,
                              'userMoodNo',
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                padding: '10px',
              }}
            >
              <input
                type="checkbox"
                checked={this.state.Watch}
                onChange={this.selectCat}
                className="closedCheckBox"
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '10px',
                  top: '2px',
                }}
                alt="Watch"
              />
              <span
                style={{
                  fontWeight: '500',
                  fontSize: '18px',
                  color: '#ed0259',
                }}
              >
                Do Something Fun
              </span>
              {this.state.Watch === true && (
                <div>
                  <div style={{ padding: '10px 0px 0px 30px' }}>
                    <span
                      style={{
                        fontWeight: '100',
                        fontSize: '16px',
                        fontStyle: 'italic',
                      }}
                    >
                      Would you like to take in a show?
                    </span>
                  </div>
                  <div style={{ padding: '10px 0px 0px 30px' }}>
                    <Row className="no-padding">
                      <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="no-padding"
                      >
                        <div>
                          <input
                            type="checkbox"
                            checked={this.state.Music}
                            onChange={this.selectCat}
                            className="closedCheckBox"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                              top: '2px',
                            }}
                            alt="Music"
                          />
                          <span>Live Music</span>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            checked={this.state.Comedy}
                            onChange={this.selectCat}
                            className="closedCheckBox"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                              top: '2px',
                            }}
                            alt="Comedy"
                          />
                          <span>Comedy</span>
                        </div>
                      </Col>
                      <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="no-padding"
                      >
                        <div>
                          <input
                            type="checkbox"
                            checked={this.state.Theater}
                            onChange={this.selectCat}
                            className="closedCheckBox"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                              top: '2px',
                            }}
                            alt="Theater"
                          />
                          <span>Theater</span>
                        </div>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: '10px' }}>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <div>
                          {this.props.tags.map((cattag, index) => (
                            <div>
                              {this.getEventTypeFields(cattag)}
                            </div>
                          ))}
                        </div>
                      </Col>
                    </Row>

                    {this.state.Music === true && (
                      <Row style={{ marginTop: '10px' }}>
                        <Col
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="no-padding"
                        >
                          <div>
                            {this.props.tags.map((cattag, index) => (
                              <div>
                                {this.getMainGenreFields(cattag)}
                              </div>
                            ))}
                          </div>
                        </Col>
                      </Row>
                    )}

                    {/* <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <div style={{ padding: '20px 0px 0px 0px' }}>
                          <span
                            style={{
                              fontWeight: '100',
                              fontSize: '16px',
                              fontStyle: 'italic',
                            }}
                          >
                            Looking to Explore The City?
                          </span>
                        </div>
                        <div style={{ padding: '10px 0px 0px 0px' }}>
                          <Row className="no-padding">
                            <Col
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              className="no-padding"
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  checked={this.state.Museum}
                                  onChange={this.selectCat}
                                  className="closedCheckBox"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                    top: '2px',
                                  }}
                                  alt="Museum"
                                />
                                <span>Visit a Museum</span>
                              </div>
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              className="no-padding"
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  checked={this.state.Zoo}
                                  onChange={this.selectCat}
                                  className="closedCheckBox"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                    top: '2px',
                                  }}
                                  alt="Zoo"
                                />
                                <span>Visit a Zoo</span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="no-padding"
                      >
                        <div style={{ padding: '20px 0px 0px 0px' }}>
                          <span
                            style={{
                              fontWeight: '100',
                              fontSize: '16px',
                              fontStyle: 'italic',
                            }}
                          >
                            Something a little more active?
                          </span>
                        </div>
                        <div style={{ padding: '10px 0px 0px 0px' }}>
                          <Row className="no-padding">
                            <Col
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              className="no-padding"
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  checked={this.state.Outdoors}
                                  onChange={this.selectCat}
                                  className="closedCheckBox"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                    top: '2px',
                                  }}
                                  alt="Outdoors"
                                />
                                <span>Get Outdoors</span>
                              </div>
                            </Col>
                            <Col
                              lg={6}
                              md={6}
                              sm={6}
                              xs={6}
                              className="no-padding"
                            >
                              <div>
                                <input
                                  type="checkbox"
                                  checked={this.state.Golf}
                                  onChange={this.selectCat}
                                  className="closedCheckBox"
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    marginRight: '5px',
                                    top: '2px',
                                  }}
                                  alt="Golf"
                                />
                                <span>Golf</span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </div>
            {/* <div
              style={{
                padding: '10px',
              }}
            >
              <input
                type="checkbox"
                checked={this.state.Do}
                onChange={this.selectCat}
                className="closedCheckBox"
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '10px',
                  top: '2px',
                }}
                alt="Do"
              />
              <span
                style={{
                  fontWeight: '500',
                  fontSize: '18px',
                  color: '#ed0259',
                }}
              >
                Do Something
              </span>
              {this.state.Do === true && (
                <div>
                  <div style={{ padding: '10px 0px 0px 30px' }}>
                    <span
                      style={{
                        fontWeight: '100',
                        fontSize: '16px',
                        fontStyle: 'italic',
                      }}
                    >
                      Anything Specific?
                    </span>
                  </div>
                  <div style={{ padding: '10px 0px 0px 30px' }}>
                    <Row className="no-padding">
                      <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="no-padding"
                      >
                        <div>
                          <input
                            type="checkbox"
                            checked={this.state.Outdoors}
                            onChange={this.selectCat}
                            className="closedCheckBox"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                              top: '2px',
                            }}
                            alt="Outdoors"
                          />
                          <span>Get Outdoors</span>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            checked={this.state.Museum}
                            onChange={this.selectCat}
                            className="closedCheckBox"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                              top: '2px',
                            }}
                            alt="Museum"
                          />
                          <span>Visit a Museum</span>
                        </div>
                      </Col>
                      <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="no-padding"
                      >
                        <div>
                          <input
                            type="checkbox"
                            checked={this.state.Zoo}
                            onChange={this.selectCat}
                            className="closedCheckBox"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                              top: '2px',
                            }}
                            alt="Zoo"
                          />
                          <span>Visit a Zoo</span>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            checked={this.state.Golf}
                            onChange={this.selectCat}
                            className="closedCheckBox"
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '5px',
                              top: '2px',
                            }}
                            alt="Golf"
                          />
                          <span>Golf</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </div> */}
          </div>

          {showExperienceType === true && (
            <div>
              <div
                className="secondary-title-bar"
                style={{
                  borderTop: '1px solid #ee0059',
                  marginTop: '10px',
                }}
              >
                <div>
                  <Container>
                    <Row className="no-margin">
                      <Col
                        xs={12}
                        md={12}
                        sm={12}
                        lg={12}
                        className="no-padding text-center"
                      >
                        <span style={{ fontStyle: 'normal' }}>
                          Looking for a certain type of experience?
                        </span>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
              <div
                style={{
                  maxWidth: '500px',
                  margin: '0px auto',
                  marginTop: '10px',
                }}
              >
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <div>
                      {this.getCategoryFieldsDateTypeTags(
                        this.props.tags,
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )}

          {this.state.Dine === true && this.state.Watch === true ? (
            <div> {this.getStepOneFooter(5)}</div>
          ) : (
            <div> {this.getStepOneFooter(2)}</div>
          )}

          <style
            dangerouslySetInnerHTML={{
              __html:
                '#headersTop {width: 100% !important;} #authright {padding-left: 0px !important;} #authleft {display:none;} #mobile-nav { display: none } #bottomNav { display: none } .maincontent { top: 39px; margin-top: 0px; height: 100%} .footer{display:none} .full-height{height:100%} .maincontent-push { position: relative; height: 100%;}',
            }}
          />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    packages: state.packages.packages,
    alllocations: state.locations.locations,
    users: state.users.users,
    connections: state.connections,
    newEvent: state.newEvent,
    user: state.profile.profile,
    currentUser: state.user,
    taRequest: state.taRequest,
    regions: state.regions.regions,
    tags: state.tags.tags,
    customEvent: state.customEvent.customEvent,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNewEvent: (newEvent) => {
      dispatch(getNewEvent(newEvent));
    },

    resetNewEvent: (newEvent) => {
      dispatch(resetNewEvent(newEvent));
    },

    savePurchase: (purchaseBundle) => {
      dispatch(savePurchase(purchaseBundle));
    },
    getCe: (customEvent) => {
      dispatch(getCe(customEvent));
    },
    resetCe: (zone) => {
      dispatch(resetCe(zone));
    },
    getTaRequest: (bundle) => {
      dispatch(getTaRequest(bundle));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanEventConnect);
